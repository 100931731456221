"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/web.dom-collections.iterator.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

_vue.default.use(_vuex.default); // // https://webpack.js.org/guuides/dependency-management/#requirecontext


var modulesFiles = require.context('./modules', true, /\.js$/); // you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file


var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new _vuex.default.Store({
  modules: modules,
  // : {
  //     app,
  //     errorLog,
  //     settings,
  //     user,
  //     permission
  //   },
  getters: {
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    token: function token(state) {
      return state.user.token;
    },
    avatar: function avatar(state) {
      return state.user.avatar;
    },
    name: function name(state) {
      return state.user.userNickName;
    },
    userNickName: function userNickName(state) {
      return state.user.userNickName;
    },
    authorityList: function authorityList(state) {
      return state.user.authorityList;
    },
    roleList: function roleList(state) {
      return state.user.roleList;
    },
    routes: function routes(state) {
      return state.permission.routes;
    },
    errorLogs: function errorLogs(state) {
      return state.errorLog.logs;
    },
    visitedViews: function visitedViews(state) {
      return state.tagsView.visitedViews;
    },
    cachedViews: function cachedViews(state) {
      return state.tagsView.cachedViews;
    },
    orgnCode: function orgnCode(state) {
      return state.user.orgnCode;
    },
    orgnName: function orgnName(state) {
      return state.user.orgnName;
    },
    orgnId: function orgnId(state) {
      return state.user.orgnId;
    },
    sysOrgan: function sysOrgan(state) {
      return state.user.sysOrgan;
    },
    uuid: function uuid(state) {
      return state.user.userUuid;
    },
    userLongId: function userLongId(state) {
      return state.user.userLongId;
    }
  }
});
var _default = store;
exports.default = _default;