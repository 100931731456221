var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.flag,
          expression: "flag"
        }
      ],
      staticClass: "app-container",
      attrs: {
        "element-loading-text": "报表下载中，请稍等",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "queryParam",
              attrs: { inline: true, model: _vm.queryParam }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择活动" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择活动" },
                      on: { change: _vm.activityChange },
                      model: {
                        value: _vm.queryParam.activityCode,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParam, "activityCode", $$v)
                        },
                        expression: "queryParam.activityCode"
                      }
                    },
                    _vm._l(_vm.activityOptions, function(item) {
                      return _c("el-option", {
                        key: item.activityCode,
                        attrs: {
                          label: item.activityName,
                          value: item.activityCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择报表" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择报表" },
                      on: { change: _vm.reportChange },
                      model: {
                        value: _vm.queryParam.reportType,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParam, "reportType", $$v)
                        },
                        expression: "queryParam.reportType"
                      }
                    },
                    _vm._l(_vm.statementTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.reportType,
                        attrs: {
                          label: item.reportName,
                          value: item.reportType
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.queryParam.reportType != "01" &&
              _vm.queryParam.reportType != "02"
                ? _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "monthrange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM",
                          clearable: true,
                          "clear-icon": "el-icon-error"
                        },
                        on: {
                          change: function($event) {
                            return _vm.dateChange($event)
                          }
                        },
                        model: {
                          value: _vm.dateValue,
                          callback: function($$v) {
                            _vm.dateValue = $$v
                          },
                          expression: "dateValue"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.btnCtrl },
                      on: { click: _vm.handleQuery }
                    },
                    [_vm._v("查询 ")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.btnCtrl, type: "primary" },
                      on: { click: _vm.download }
                    },
                    [_vm._v(" 导 出 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              attrs: {
                data: _vm.list,
                "cell-style": { "text-align": "center" },
                "header-cell-style": { "text-align": "center" },
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "客户号", prop: "managerId" }
              }),
              _c("el-table-column", {
                attrs: { label: "首次浏览时间", prop: "managerName" }
              }),
              _c("el-table-column", {
                attrs: { label: "首次动效选择红包类型", prop: "lvl1DeptNm" }
              }),
              _c("el-table-column", {
                attrs: { label: "选择时间", prop: "network" }
              }),
              _c("el-table-column", {
                attrs: { label: "活动浏览天数", prop: "customerId" }
              }),
              _c("el-table-column", {
                attrs: { label: "活动浏览次数", prop: "mobileNo" }
              }),
              _c("el-table-column", {
                attrs: { label: "最近浏览时间", prop: "firstBrowseTime" }
              }),
              _c("el-table-column", {
                attrs: { label: "渠道号", prop: "browseNum" }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.queryParam.pageNo,
                  "page-size": _vm.queryParam.pageSize,
                  total: _vm.queryParam.totalCount,
                  "page-sizes": [100, 50, 200, 500, 1000],
                  layout: "total, sizes, prev, pager, next, jumper"
                },
                on: {
                  "update:currentPage": function($event) {
                    return _vm.$set(_vm.queryParam, "pageNo", $event)
                  },
                  "update:current-page": function($event) {
                    return _vm.$set(_vm.queryParam, "pageNo", $event)
                  },
                  "update:pageSize": function($event) {
                    return _vm.$set(_vm.queryParam, "pageSize", $event)
                  },
                  "update:page-size": function($event) {
                    return _vm.$set(_vm.queryParam, "pageSize", $event)
                  },
                  "update:total": function($event) {
                    return _vm.$set(_vm.queryParam, "totalCount", $event)
                  },
                  "size-change": _vm.handleQuery,
                  "current-change": _vm.handleQuery
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.activityRow.activityType == 20 && _vm.queryParam.reportType == "01"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.list,
                    "cell-style": { "text-align": "center" },
                    "header-cell-style": { "text-align": "center" },
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "客户经理号", prop: "managerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户经理姓名", prop: "managerName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "一级机构", prop: "lvl1DeptNm" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "网点名称", prop: "network" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户号", prop: "customerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户手机号", prop: "mobileNo" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "首次浏览时间", prop: "firstBrowseTime" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "浏览次数", prop: "browseNum" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报名时间", prop: "applyTime" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "初始等级", prop: "startGrade" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "提升等级", prop: "targetGrade" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "当前资产", prop: "currAmount" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParam.pageNo,
                      "page-size": _vm.queryParam.pageSize,
                      total: _vm.queryParam.totalCount,
                      "page-sizes": [10, 20, 50, 100],
                      layout: "total, sizes, prev, pager, next, jumper"
                    },
                    on: {
                      "update:currentPage": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:current-page": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:pageSize": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:page-size": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:total": function($event) {
                        return _vm.$set(_vm.queryParam, "totalCount", $event)
                      },
                      "size-change": _vm.handleQuery,
                      "current-change": _vm.handleQuery
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.activityRow.activityType == 21 && _vm.queryParam.reportType == "01"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.list,
                    "cell-style": { "text-align": "center" },
                    "header-cell-style": { "text-align": "center" },
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "客户经理号", prop: "managerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户经理姓名", prop: "managerName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "一级机构", prop: "lvl1DeptNm" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "网点名称", prop: "network" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户号", prop: "customerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户手机号", prop: "mobileNo" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "首次浏览时间", prop: "firstBrowseTime" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "浏览次数", prop: "browseNum" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报名时间", prop: "applyTime" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "当前资产较上月提升（元）",
                      prop: "promoteAmount"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "达标次数", prop: "reachNum" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParam.pageNo,
                      "page-size": _vm.queryParam.pageSize,
                      total: _vm.queryParam.totalCount,
                      "page-sizes": [10, 20, 50, 100],
                      layout: "total, sizes, prev, pager, next, jumper"
                    },
                    on: {
                      "update:currentPage": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:current-page": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:pageSize": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:page-size": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:total": function($event) {
                        return _vm.$set(_vm.queryParam, "totalCount", $event)
                      },
                      "size-change": _vm.handleQuery,
                      "current-change": _vm.handleQuery
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.activityRow.activityType == 22 && _vm.queryParam.reportType == "01"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.list,
                    "cell-style": { "text-align": "center" },
                    "header-cell-style": { "text-align": "center" },
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "客户经理号", prop: "managerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户经理姓名", prop: "managerName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "一级机构", prop: "lvl1DeptNm" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "网点名称", prop: "network" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户号", prop: "customerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户手机号", prop: "mobileNo" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "首次浏览时间", prop: "firstBrowseTime" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "浏览次数", prop: "browseNum" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报名时间", prop: "applyTime" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "最近结算资产", prop: "lastSettleAmount" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "当前资产", prop: "currAmount" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "达标次数", prop: "reachNum" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParam.pageNo,
                      "page-size": _vm.queryParam.pageSize,
                      total: _vm.queryParam.totalCount,
                      "page-sizes": [10, 20, 50, 100],
                      layout: "total, sizes, prev, pager, next, jumper"
                    },
                    on: {
                      "update:currentPage": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:current-page": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:pageSize": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:page-size": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:total": function($event) {
                        return _vm.$set(_vm.queryParam, "totalCount", $event)
                      },
                      "size-change": _vm.handleQuery,
                      "current-change": _vm.handleQuery
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.activityRow.activityType == 23 && _vm.queryParam.reportType == "01"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.list,
                    "cell-style": { "text-align": "center" },
                    "header-cell-style": { "text-align": "center" },
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                _vm._l(_vm.titleList, function(item, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: { label: item.lable },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row[item.attr]))])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParam.pageNo,
                      "page-size": _vm.queryParam.pageSize,
                      total: _vm.queryParam.totalCount,
                      "page-sizes": [10, 20, 50, 100],
                      layout: "total, sizes, prev, pager, next, jumper"
                    },
                    on: {
                      "update:currentPage": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:current-page": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:pageSize": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:page-size": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:total": function($event) {
                        return _vm.$set(_vm.queryParam, "totalCount", $event)
                      },
                      "size-change": _vm.handleQuery,
                      "current-change": _vm.handleQuery
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.queryParam.reportType == "02"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "histogramBox" },
              [
                _vm.histogramCtrl
                  ? _c("Histogram", {
                      key: _vm.HistogramKey,
                      attrs: {
                        "x-data": _vm.xData,
                        data1: _vm.yData1,
                        data2: _vm.yData2
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "histogramTitle" }, [
                  _vm._v("机构客户经理推广统计")
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { width: "800px" } },
              [
                _vm._m(0),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading"
                      }
                    ],
                    attrs: {
                      data: _vm.list,
                      "cell-style": { "text-align": "center" },
                      "header-cell-style": { "text-align": "center" },
                      "element-loading-text": "Loading",
                      border: "",
                      fit: "",
                      "highlight-current-row": ""
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "机构", prop: "institutionName" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "客户经理参与人数", prop: "manaPartNum" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "客户报名成功人数", prop: "cusApplyNum" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.queryParam.reportType == "03"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.list,
                    "cell-style": { "text-align": "center" },
                    "header-cell-style": { "text-align": "center" },
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "排名", prop: "managerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户经理号", prop: "managerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户经理姓名", prop: "managerName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "一级机构", prop: "lvl1DeptNm" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "网点名称", prop: "network" }
                  }),
                  _vm.activityRow.manaPromoFlag
                    ? _c("el-table-column", {
                        attrs: { label: "发放积分", prop: "points" }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { label: "达标人数", prop: "finishCusNum" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报名人数", prop: "applyCusNum" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "浏览人数", prop: "browseCusNum" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParam.pageNo,
                      "page-size": _vm.queryParam.pageSize,
                      total: _vm.queryParam.totalCount,
                      "page-sizes": [10, 20, 50, 100],
                      layout: "total, sizes, prev, pager, next, jumper"
                    },
                    on: {
                      "update:currentPage": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:current-page": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:pageSize": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:page-size": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:total": function($event) {
                        return _vm.$set(_vm.queryParam, "totalCount", $event)
                      },
                      "size-change": _vm.handleQuery,
                      "current-change": _vm.handleQuery
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.queryParam.reportType == "04"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.list,
                    "cell-style": { "text-align": "center" },
                    "header-cell-style": { "text-align": "center" },
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "客户号", prop: "customerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户手机号", prop: "mobileNo" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报名时间", prop: "applyTime" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "渠道号", prop: "channelNo" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "渠道名称", prop: "channelName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "初始资产（元）", prop: "startAmount" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "达标时资产（元）", prop: "reachAmount" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "资产提升（元）", prop: "promoAmount" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParam.pageNo,
                      "page-size": _vm.queryParam.pageSize,
                      total: _vm.queryParam.totalCount,
                      "page-sizes": [10, 20, 50, 100],
                      layout: "total, sizes, prev, pager, next, jumper"
                    },
                    on: {
                      "update:currentPage": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:current-page": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:pageSize": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:page-size": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:total": function($event) {
                        return _vm.$set(_vm.queryParam, "totalCount", $event)
                      },
                      "size-change": _vm.handleQuery,
                      "current-change": _vm.handleQuery
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              attrs: {
                data: _vm.list,
                "cell-style": { "text-align": "center" },
                "header-cell-style": { "text-align": "center" },
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "日期", prop: "managerId" }
              }),
              _c("el-table-column", {
                attrs: { label: "UV", prop: "managerName" }
              }),
              _c("el-table-column", {
                attrs: { label: "首次动效触发人数", prop: "managerName" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "首次动效选择红包类型的人数",
                  prop: "lvl1DeptNm"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "首次签到人数", prop: "network" }
              }),
              _c("el-table-column", {
                attrs: { label: "首次未签到人数", prop: "points" }
              }),
              _c("el-table-column", {
                attrs: { label: "流失率", prop: "finishCusNum" }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.queryParam.pageNo,
                  "page-size": _vm.queryParam.pageSize,
                  total: _vm.queryParam.totalCount,
                  "page-sizes": [100, 50, 200, 500, 1000],
                  layout: "total, sizes, prev, pager, next, jumper"
                },
                on: {
                  "update:currentPage": function($event) {
                    return _vm.$set(_vm.queryParam, "pageNo", $event)
                  },
                  "update:current-page": function($event) {
                    return _vm.$set(_vm.queryParam, "pageNo", $event)
                  },
                  "update:pageSize": function($event) {
                    return _vm.$set(_vm.queryParam, "pageSize", $event)
                  },
                  "update:page-size": function($event) {
                    return _vm.$set(_vm.queryParam, "pageSize", $event)
                  },
                  "update:total": function($event) {
                    return _vm.$set(_vm.queryParam, "totalCount", $event)
                  },
                  "size-change": _vm.handleQuery,
                  "current-change": _vm.handleQuery
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tableTitle" }, [
      _c("span", { staticClass: "tableTitleLine" }),
      _c("span", { staticClass: "tableTitleText" }, [_vm._v("详情数据")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }