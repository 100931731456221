var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      attrs: {
        action: "",
        "on-success": _vm.handleUploadSuccess,
        "on-error": _vm.handleError,
        limit: 1,
        drag: _vm.drag,
        accept: _vm.acceptVal,
        "on-exceed": _vm.handleUploadExceed,
        "file-list": _vm.fileInfo,
        "on-remove": _vm.handleRemove,
        "on-preview": _vm.handlePreview,
        "before-upload": _vm.beforeUpload,
        "http-request": _vm.handleUpload
      }
    },
    [
      _vm.drag
        ? _vm._t("default")
        : _c(
            "el-button",
            {
              attrs: { slot: "trigger", size: _vm.size, type: _vm.type },
              slot: "trigger"
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }