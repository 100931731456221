var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "winnerBox" }, [
    _c("div", { staticClass: "turnplate_box" }, [
      _c("img", {
        staticClass: "turnplate_boxBg",
        attrs: { src: require("../../assets/images/zhuanpnull@2x.png") }
      }),
      _c(
        "canvas",
        {
          ref: "myCanvas",
          style: _vm.rotateStyle,
          attrs: { id: "myCanvas", width: "260px", height: "260px" }
        },
        [_vm._v("抱歉！浏览器不支持。")]
      ),
      _vm.runing
        ? _c(
            "button",
            { staticClass: "turnplatw_btn", on: { click: _vm.startRun } },
            [
              _c("img", {
                staticClass: "turnplatw_img",
                attrs: { src: require("../../assets/images/guding@2x.png") }
              }),
              _vm._m(0)
            ]
          )
        : _c(
            "button",
            { staticClass: "turnplatw_btn", attrs: { disabled: "disabled" } },
            [
              _c("img", {
                staticClass: "turnplatw_img",
                attrs: { src: require("../../assets/images/guding@2x.png") }
              }),
              _vm._m(1)
            ]
          )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "goIcon" }, [
      _c("img", { attrs: { src: require("../../assets/images/go.png") } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "goIcon" }, [
      _c("img", { attrs: { src: require("../../assets/images/go.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }