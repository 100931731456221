var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: "客群设置",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "900px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "rowForm",
          staticClass: "customerForm",
          attrs: {
            model: _vm.selectedRow,
            rules: _vm.rules,
            "label-width": "140px"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "margin-bottom": "22px" } },
            [
              _c(
                "div",
                { staticClass: "targetCheckLabel" },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.selectedRow.cusType,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedRow, "cusType", $$v)
                        },
                        expression: "selectedRow.cusType"
                      }
                    },
                    _vm._l(_vm.targetOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "targetCheckGroup" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.selectedRow.selType,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedRow, "selType", $$v)
                        },
                        expression: "selectedRow.selType"
                      }
                    },
                    [_vm._v("客群")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.selectedRow.selType,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedRow, "selType", $$v)
                        },
                        expression: "selectedRow.selType"
                      }
                    },
                    [_vm._v("标签")]
                  )
                ],
                1
              )
            ]
          ),
          _vm.selectedRow.selType
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "CRM标签" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.selectedRow.crm,
                            callback: function($$v) {
                              _vm.$set(_vm.selectedRow, "crm", $$v)
                            },
                            expression: "selectedRow.crm"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("任一标签")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("全部标签")
                          ])
                        ],
                        1
                      ),
                      _c("br"),
                      _vm._l(_vm.crmTargetList, function(item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticStyle: { "margin-right": "10px" }
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.openTagDialog("crm")
                            }
                          }
                        },
                        [_vm._v("标签选择")]
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "自定义标签" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.selectedRow.crm,
                            callback: function($$v) {
                              _vm.$set(_vm.selectedRow, "crm", $$v)
                            },
                            expression: "selectedRow.crm"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("任一标签")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("全部标签")
                          ])
                        ],
                        1
                      ),
                      _c("br"),
                      _vm._l(_vm.customTargetList, function(item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticStyle: { "margin-right": "10px" }
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.openTagDialog("custom")
                            }
                          }
                        },
                        [_vm._v("标签选择")]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "客群" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", multiple: "" },
                      model: {
                        value: _vm.selectedRow.cusGroupUuids,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedRow, "cusGroupUuids", $$v)
                        },
                        expression: "selectedRow.cusGroupUuids"
                      }
                    },
                    _vm._l(_vm.cusGroupOptions, function(item) {
                      return _c("el-option", {
                        key: item.secUuid,
                        attrs: { label: item.cusGroupName, value: item.secUuid }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
          _c("div", { staticClass: "gapLine" }),
          _c(
            "el-form-item",
            { attrs: { label: "白名单" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.selectedRow.whtListUuid,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "whtListUuid", $$v)
                    },
                    expression: "selectedRow.whtListUuid"
                  }
                },
                _vm._l(_vm.whtListOptions, function(item) {
                  return _c("el-option", {
                    key: item.secUuid,
                    attrs: { label: item.title, value: item.secUuid }
                  })
                }),
                1
              ),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "50px" },
                  attrs: { "false-label": 0, "true-label": 1 },
                  model: {
                    value: _vm.selectedRow.isWhtOnly,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "isWhtOnly", $$v)
                    },
                    expression: "selectedRow.isWhtOnly"
                  }
                },
                [_vm._v("仅限白名单")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "黑名单" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.selectedRow.blkListUuid,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "blkListUuid", $$v)
                    },
                    expression: "selectedRow.blkListUuid"
                  }
                },
                _vm._l(_vm.blkListOptions, function(item) {
                  return _c("el-option", {
                    key: item.secUuid,
                    attrs: { label: item.title, value: item.secUuid }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnSaveLoading },
              on: { click: _vm.handleSave }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _vm.targetDialog
        ? _c("TargetDialog", {
            attrs: {
              targetList: _vm.targetList,
              targetType: _vm.targetType,
              visible: _vm.targetDialog
            },
            on: {
              "update:visible": function($event) {
                _vm.targetDialog = $event
              },
              ok: _vm.targetSelect
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }