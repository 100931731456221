"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

/**
 * 替换图片URL前缀
 * @param url {string}
 * @return {string}
 */
function _default(uname) {
  var url = process.env.VUE_APP_JOINT_URL + uname;
  return url;
}