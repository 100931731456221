var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.queryParam }
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOpen } },
                [_vm._v("+ 新增")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称", clearable: "" },
                    model: {
                      value: _vm.queryParam.collaName,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParam, "collaName", $$v)
                      },
                      expression: "queryParam.collaName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "cell-style": { "text-align": "center" },
            "header-cell-style": { "text-align": "center" },
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", type: "index", width: "50" }
          }),
          _c("el-table-column", {
            attrs: { label: "配置项名称", prop: "collaName" }
          }),
          _c("el-table-column", {
            attrs: { label: "助力有效期", prop: "collaTermTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.collaTermTime
                      ? _c("span", [_vm._v(_vm._s(row.collaTermTime + "小时"))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "助力人数", prop: "collaQuaNumber" }
          }),
          _c("el-table-column", {
            attrs: { label: "助力资格", prop: "collaQuaCodeLabel" }
          }),
          _c("el-table-column", {
            attrs: { label: "好友助力资格次数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.collaPerUserCodeLabel && row.collaPerUserNumber
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              row.collaPerUserCodeLabel +
                                "" +
                                row.collaPerUserNumber +
                                "次"
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "创建用户", prop: "createUser" }
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "140",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "0" },
                            attrs: { size: "mini", type: "text", circle: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleDetails(row)
                              }
                            }
                          },
                          [_vm._v("详情")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "0" },
                            attrs: { size: "mini", type: "text", circle: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleUpdate(row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                "confirm-button-text": "好的",
                                "cancel-button-text": "取消",
                                icon: "el-icon-info",
                                "icon-color": "red",
                                title: "您确认要删除这条数据吗?"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleDelete(row)
                                }
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "text-red",
                                  attrs: {
                                    slot: "reference",
                                    size: "mini",
                                    type: "text",
                                    circle: ""
                                  },
                                  slot: "reference"
                                },
                                [_vm._v(" 删除 ")]
                              )
                            ],
                            1
                          )
                        ]
                      ],
                      2
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryParam.pageNo,
              "page-size": _vm.queryParam.pageSize,
              total: _vm.queryParam.totalCount,
              "page-sizes": [10, 20, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:total": function($event) {
                return _vm.$set(_vm.queryParam, "totalCount", $event)
              },
              "size-change": function($event) {
                return _vm.handleQuery("page")
              },
              "current-change": function($event) {
                return _vm.handleQuery("page")
              }
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          ref: "helpForm",
          attrs: {
            width: "800px",
            title: _vm.helpForm.id ? "编辑" : "新增",
            visible: _vm.helpFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.helpFormVisible = $event
            },
            close: _vm.closeHpleForm
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "helpForm",
              staticStyle: { width: "600px" },
              attrs: {
                model: _vm.helpForm,
                "label-width": "180px",
                rules: _vm.helpRules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "配置项名称", prop: "collaName" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "请输入配置项名称"
                    },
                    model: {
                      value: _vm.helpForm.collaName,
                      callback: function($$v) {
                        _vm.$set(_vm.helpForm, "collaName", $$v)
                      },
                      expression: "helpForm.collaName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "助力有效期", prop: "collaTermType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        input: function($event) {
                          _vm.helpForm.collaTermTime = ""
                        }
                      },
                      model: {
                        value: _vm.helpForm.collaTermType,
                        callback: function($$v) {
                          _vm.$set(_vm.helpForm, "collaTermType", $$v)
                        },
                        expression: "helpForm.collaTermType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("不限")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("限制")])
                    ],
                    1
                  ),
                  _vm._v(" 发起后 "),
                  _c("el-input", {
                    staticStyle: { width: "80px" },
                    attrs: {
                      disabled: _vm.helpForm.collaTermType === 0,
                      autocomplete: "off",
                      size: "mini"
                    },
                    model: {
                      value: _vm.helpForm.collaTermTime,
                      callback: function($$v) {
                        _vm.$set(_vm.helpForm, "collaTermTime", _vm._n($$v))
                      },
                      expression: "helpForm.collaTermTime"
                    }
                  }),
                  _vm._v(" 小时有效 ")
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "助力人数", prop: "collaQuaNumber" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      autocomplete: "off",
                      placeholder: "请输入助力人数"
                    },
                    model: {
                      value: _vm.helpForm.collaQuaNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.helpForm, "collaQuaNumber", $$v)
                      },
                      expression: "helpForm.collaQuaNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "助力资格", prop: "collaQuaType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        input: function($event) {
                          _vm.helpForm.collaQuaCode = ""
                        }
                      },
                      model: {
                        value: _vm.helpForm.collaQuaType,
                        callback: function($$v) {
                          _vm.$set(_vm.helpForm, "collaQuaType", $$v)
                        },
                        expression: "helpForm.collaQuaType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("不限")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("限制")])
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px", "margin-left": "20px" },
                      attrs: {
                        disabled: _vm.helpForm.collaQuaType === 0,
                        placeholder: "请选择",
                        size: "mini"
                      },
                      model: {
                        value: _vm.helpForm.collaQuaCode,
                        callback: function($$v) {
                          _vm.$set(_vm.helpForm, "collaQuaCode", $$v)
                        },
                        expression: "helpForm.collaQuaCode"
                      }
                    },
                    _vm._l(_vm.collaQuaOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "好友助力次数", prop: "collaPerUserType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        input: function($event) {
                          ;(_vm.helpForm.collaPerUserCode = ""),
                            (_vm.helpForm.collaPerUserNumber = "")
                        }
                      },
                      model: {
                        value: _vm.helpForm.collaPerUserType,
                        callback: function($$v) {
                          _vm.$set(_vm.helpForm, "collaPerUserType", $$v)
                        },
                        expression: "helpForm.collaPerUserType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("不限")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("限制")])
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px", "margin-left": "20px" },
                      attrs: {
                        disabled: _vm.helpForm.collaPerUserType === 0,
                        placeholder: "请选择",
                        size: "mini"
                      },
                      model: {
                        value: _vm.helpForm.collaPerUserCode,
                        callback: function($$v) {
                          _vm.$set(_vm.helpForm, "collaPerUserCode", $$v)
                        },
                        expression: "helpForm.collaPerUserCode"
                      }
                    },
                    _vm._l(_vm.collaPerUserCodeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  ),
                  _vm._v(" 最多 "),
                  _c("el-input", {
                    staticStyle: { width: "40px" },
                    attrs: {
                      disabled: _vm.helpForm.collaPerUserType === 0,
                      autocomplete: "off",
                      size: "mini"
                    },
                    model: {
                      value: _vm.helpForm.collaPerUserNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.helpForm, "collaPerUserNumber", $$v)
                      },
                      expression: "helpForm.collaPerUserNumber"
                    }
                  }),
                  _vm._v(" 次 ")
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "助力活动总次数", prop: "collaTotalUserType" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        input: function($event) {
                          _vm.helpForm.collaTotalUserNumber = ""
                        }
                      },
                      model: {
                        value: _vm.helpForm.collaTotalUserType,
                        callback: function($$v) {
                          _vm.$set(_vm.helpForm, "collaTotalUserType", $$v)
                        },
                        expression: "helpForm.collaTotalUserType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("不限")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("限制")])
                    ],
                    1
                  ),
                  _c("el-input", {
                    staticStyle: { width: "40px", "margin-left": "20px" },
                    attrs: {
                      disabled: _vm.helpForm.collaTotalUserType === 0,
                      autocomplete: "off",
                      size: "mini"
                    },
                    model: {
                      value: _vm.helpForm.collaTotalUserNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.helpForm, "collaTotalUserNumber", $$v)
                      },
                      expression: "helpForm.collaTotalUserNumber"
                    }
                  }),
                  _vm._v(" 次 ")
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "同时发起活动个数",
                    prop: "collaSimCountType"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        input: function($event) {
                          _vm.helpForm.collaSimCount = ""
                        }
                      },
                      model: {
                        value: _vm.helpForm.collaSimCountType,
                        callback: function($$v) {
                          _vm.$set(_vm.helpForm, "collaSimCountType", $$v)
                        },
                        expression: "helpForm.collaSimCountType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("不限")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("限制")])
                    ],
                    1
                  ),
                  _c("el-input", {
                    staticStyle: { width: "40px", "margin-left": "20px" },
                    attrs: {
                      disabled: _vm.helpForm.collaSimCountType === 0,
                      autocomplete: "off",
                      size: "mini"
                    },
                    model: {
                      value: _vm.helpForm.collaSimCount,
                      callback: function($$v) {
                        _vm.$set(_vm.helpForm, "collaSimCount", $$v)
                      },
                      expression: "helpForm.collaSimCount"
                    }
                  }),
                  _vm._v(" 个 ")
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "助力页面背景图", prop: "videoUploadInfoUrl" }
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        "before-remove": _vm.beforeBgcRemove,
                        "on-exceed": _vm.handleExceed,
                        action: "''",
                        "file-list": _vm.fileBgc,
                        limit: 1,
                        "http-request": _vm.handleUploadPictureBgc
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("上传")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "助力规则", prop: "collaRuleType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { input: _vm.inputChange },
                      model: {
                        value: _vm.helpForm.collaRuleType,
                        callback: function($$v) {
                          _vm.$set(_vm.helpForm, "collaRuleType", $$v)
                        },
                        expression: "helpForm.collaRuleType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("上传图片")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("文字输入")
                      ])
                    ],
                    1
                  ),
                  _vm.helpForm.collaRuleType
                    ? _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入活动规则"
                        },
                        model: {
                          value: _vm.helpForm.collaRuleDesc,
                          callback: function($$v) {
                            _vm.$set(_vm.helpForm, "collaRuleDesc", $$v)
                          },
                          expression: "helpForm.collaRuleDesc"
                        }
                      })
                    : _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: "''",
                            limit: 1,
                            "http-request": _vm.handleUploadRule,
                            "file-list": _vm.ruleFile,
                            "on-remove": _vm.handleRuleRemove
                          }
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          )
                        ],
                        1
                      )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "页面推荐" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.bannerFormVisible = true
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                _vm._l(_vm.helpForm.bannerList, function(item, index) {
                  return _c("div", { key: index, staticClass: "banner" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openBanner(index)
                          }
                        }
                      },
                      [_vm._v("页面推荐" + _vm._s(index + 1))]
                    ),
                    _c("i", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function($event) {
                          return _vm.deleteBannerList(index)
                        }
                      }
                    })
                  ])
                }),
                0
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.helpFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleAdd }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "600px",
                title: "添加页面推荐",
                visible: _vm.bannerFormVisible,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.bannerFormVisible = $event
                },
                close: _vm.closeBannerForm
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "bannerForm",
                  staticStyle: { width: "500px" },
                  attrs: { model: _vm.bannerForm, "label-width": "100px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入推荐产品或活动的名称"
                        },
                        model: {
                          value: _vm.bannerForm.bannerName,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerForm, "bannerName", $$v)
                          },
                          expression: "bannerForm.bannerName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "banner" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: "''",
                            "on-remove": _vm.handleBannerRemove,
                            limit: 1,
                            "http-request": _vm.handleBannerUpload,
                            "file-list": _vm.bannerImg
                          }
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("上传")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "跳转方式" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择跳转方式" },
                          model: {
                            value: _vm.bannerForm.linkType,
                            callback: function($$v) {
                              _vm.$set(_vm.bannerForm, "linkType", $$v)
                            },
                            expression: "bannerForm.linkType"
                          }
                        },
                        _vm._l(_vm.jumpTypeOptions, function(typeItem) {
                          return _c("el-option", {
                            key: typeItem.dictValue,
                            attrs: {
                              label: typeItem.dictLabel,
                              value: typeItem.dictValue
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.bannerForm.linkType == "applet"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "appId" } },
                        [
                          _c("el-input", {
                            attrs: {
                              autocomplete: "off",
                              placeholder: "请输入appId"
                            },
                            model: {
                              value: _vm.bannerForm.appId,
                              callback: function($$v) {
                                _vm.$set(_vm.bannerForm, "appId", $$v)
                              },
                              expression: "bannerForm.appId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "链接地址" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入链接地址"
                        },
                        model: {
                          value: _vm.bannerForm.linkUrl,
                          callback: function($$v) {
                            _vm.$set(_vm.bannerForm, "linkUrl", $$v)
                          },
                          expression: "bannerForm.linkUrl"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.bannerFormVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.recommendBtnSaveLoading
                      },
                      on: { click: _vm.addBannerForm }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.detailsVisible,
            direction: "rtl",
            "with-header": false,
            size: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.detailsVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "5px" }, attrs: { focus: true } },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "160px" } },
                [
                  _c("el-form-item", { attrs: { label: "配置项名称:" } }, [
                    _vm._v(_vm._s(_vm.detailsList.collaName))
                  ]),
                  _c("el-form-item", { attrs: { label: "助力有效期:" } }, [
                    _vm.detailsList.collaTermTime
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.detailsList.collaTermTime + "小时"))
                        ])
                      : _vm._e()
                  ]),
                  _c("el-form-item", { attrs: { label: "助力人数:" } }, [
                    _vm.detailsList.collaQuaNumber
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.detailsList.collaQuaNumber))
                        ])
                      : _vm._e()
                  ]),
                  _c("el-form-item", { attrs: { label: "助力资格:" } }, [
                    _vm._v(_vm._s(_vm.detailsList.collaQuaCode))
                  ]),
                  _c("el-form-item", { attrs: { label: "好友助力次数:" } }, [
                    _vm.detailsList.collaPerUserCode &&
                    _vm.detailsList.collaPerUserNumber
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.detailsList.collaPerUserCode +
                                "" +
                                _vm.detailsList.collaPerUserNumber +
                                "次"
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("el-form-item", { attrs: { label: "助力活动总次数:" } }, [
                    _vm.detailsList.collaTotalUserNumber
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.detailsList.collaTotalUserNumber))
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "同时发起活动个数:" } },
                    [
                      _vm.detailsList.collaSimCount
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.detailsList.collaSimCount))
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("el-form-item", { attrs: { label: "助力背景图:" } }, [
                    _vm.detailsList.videoUploadInfoUrl &&
                    _vm.detailsList.videoUploadInfoUrl.videoUrl
                      ? _c("img", {
                          staticStyle: { width: "100px" },
                          attrs: {
                            src:
                              _vm.detailsList.videoUploadInfoUrl.videoUrl.url,
                            alt: ""
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm.detailsList.collaRuleType
                    ? _c("el-form-item", { attrs: { label: "助力规则:" } }, [
                        _vm._v(_vm._s(_vm.detailsList.collaRuleDesc))
                      ])
                    : _c("el-form-item", { attrs: { label: "助力规则:" } }, [
                        _vm.detailsList.collaRuleDesc &&
                        _vm.detailsList.collaRuleDesc.videoUrl
                          ? _c("img", {
                              staticStyle: { width: "100px" },
                              attrs: {
                                src: _vm.detailsList.collaRuleDesc.videoUrl.url,
                                alt: ""
                              }
                            })
                          : _vm._e()
                      ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "页面推荐:" } },
                    [
                      _c("el-form-item"),
                      _vm._l(_vm.detailsList.bannerList, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c("el-form-item", { attrs: { label: "名称:" } }, [
                              _vm._v(_vm._s(item.bannerName))
                            ]),
                            _c(
                              "el-form-item",
                              { attrs: { label: "banner:" } },
                              [
                                item.bannerUrl && item.bannerUrl.url
                                  ? _c("img", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        src: item.bannerUrl.url,
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "跳转方式:" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.jumpTypePreview(item.linkType))
                                )
                              ]
                            ),
                            item.linkType == "applet"
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "appId:" } },
                                  [_vm._v(_vm._s(item.appId))]
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { label: "链接地址:" } },
                              [_vm._v(_vm._s(item.linkUrl))]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }