var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: "进阶任务设置",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "1000px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
        _c("i", {
          staticClass: "el-icon-circle-plus-outline addTaskIcon",
          on: { click: _vm.handleAdd }
        })
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "cell-style": { "text-align": "center" },
            "header-cell-style": { "text-align": "center" },
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "任务名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.taskTitle))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "任务类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm.taskTypePreview(scope.row.taskType)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "是否展示" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.isShow == 1 ? "是" : "否"))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "更新时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.updateTimeStr))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "创建用户" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.createName))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text", circle: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleDetail(row)
                          }
                        }
                      },
                      [_vm._v("详情 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text", circle: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v("编辑 ")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "好的",
                          "cancel-button-text": "取消",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "您确认要删除这条数据吗?"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "text-red",
                            staticStyle: { color: "red" },
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "text",
                              circle: ""
                            },
                            slot: "reference"
                          },
                          [_vm._v(" 删除 ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.SaveDialog
        ? _c("SaveDialog", {
            attrs: {
              row: _vm.selectedRow,
              visible: _vm.SaveDialog,
              isDetail: _vm.isDetail
            },
            on: {
              "update:visible": function($event) {
                _vm.SaveDialog = $event
              },
              ok: _vm.handleQuery
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }