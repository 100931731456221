"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ActivityManager = _interopRequireDefault(require("@/api/ActivityManager"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    targetList: {
      type: Array,
      required: true
    },
    targetType: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      // selectedRow: {},
      checkBoxList: ['复选框1', '复选框2', '复选框3', '复选框4', '复选框5', '复选框6', '复选框7'],
      targetCheck: []
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.targetCheck = [];
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      this.btnSaveLoading = false;
      this.$emit('ok', this.targetCheck);
      this.handleCancel();
    }
  },
  created: function created() {
    var _this = this;

    // 基础卡片布局数据字典
    _ActivityManager.default.dictListGet("COLLECT_CARD_LAYOUT").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this.cardLayoutOptions = result.data[0];
    }); // 反显


    if (this.targetList) {
      this.targetCheck = this.targetList;
    }
  }
};
exports.default = _default;