"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.string.trim.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _TipInformationManagementService = _interopRequireDefault(require("@/api/TipInformationManagementService"));

var _Page = _interopRequireDefault(require("@/utils/entity/Page"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      // queryParam: {
      //     pageNo: Page.ofDefault().number,
      //     pageSize: Page.ofDefault().size,
      // },
      listLoading: false,
      list: null,
      selectedRow: null
    };
  },
  computed: {},
  created: function created() {
    this.handleQuery();
  },
  methods: {
    handleQuery: function handleQuery() {
      var _this = this;

      this.listLoading = true;

      _TipInformationManagementService.default.pageable().then(function (result) {
        _this.listLoading = false;

        if (result.data.length && result.data[0].codePromptList) {
          _this.list = result.data[0].codePromptList.map(function (row) {
            row.isFocus = false;
            row.ininInfo = row.prompt; // if (row.strtTm) {
            //     row.strtTm = this.formatTime(row.strtTm);
            // }
            // if (row.finTm) {
            //     row.finTm = this.formatTime(row.finTm);
            // }

            return row;
          });
        }

        result.hasFailure(function () {
          return _this.$message.error(result.message);
        });
      });
    },
    editClick: function editClick(row) {
      row.isFocus = true;
    },
    sureClick: function sureClick(row) {
      var _this2 = this;

      if (!row.prompt || row.prompt.trim() == '') {
        this.$message.error('信息提示不能为空！');
        return false;
      }

      this.listLoading = true;
      var postParam = {
        id: row.id,
        prompt: row.prompt
      };

      _TipInformationManagementService.default.updateStatus(postParam).then(function (result) {
        _this2.listLoading = false;
        result.hasFailure(function (result) {
          return _this2.$message.error(result.getErrorMessage('更新失败'));
        }).hasSuccess(function () {
          _this2.$message.success('更新成功');

          row.isFocus = false;

          _this2.handleQuery();
        });
      });
    },
    cancelClick: function cancelClick(row) {
      row.isFocus = false;
      row.prompt = row.ininInfo;
    }
  },
  watch: {}
};
exports.default = _default;