"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.string.split.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ActivityManager = _interopRequireDefault(require("@/api/ActivityManager"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: '0'
    }
  },
  data: function data() {
    return {
      selectedRow: {},
      btnSaveLoading: false
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.selectedRow = {};
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this = this;

      if (this.type == '1') {
        var postParam = JSON.parse(JSON.stringify(this.selectedRow));

        _ActivityManager.default.setActivityTime(postParam).then(function (result) {
          result.hasFailure(function () {
            _this.btnSaveLoading = false;

            _this.$message.warning(result.getErrorMessage('保存失败'));
          }).hasSuccess(function () {
            _this.btnSaveLoading = false;

            _this.$message.success('保存成功');

            _this.handleCancel();

            _this.$emit('ok');
          });
        });
      } else {
        var _postParam = JSON.parse(JSON.stringify(this.selectedRow));

        _ActivityManager.default.launchActivity(_postParam).then(function (result) {
          result.hasFailure(function () {
            _this.btnSaveLoading = false;

            _this.$message.warning(result.getErrorMessage('保存失败'));
          }).hasSuccess(function () {
            _this.btnSaveLoading = false;

            _this.$message.success('保存成功');

            _this.handleCancel();

            _this.$emit('ok');
          });
        });
      }
    },
    getDetailData: function getDetailData() {
      var _this2 = this;

      // 查询主活动详情
      _ActivityManager.default.getParentDetail(this.row.id).then(function (parentRes) {
        if (parentRes.data[0].applyStartTime && parentRes.data[0].applyStartTime.includes(':')) {
          parentRes.data[0].applyStartTime = parentRes.data[0].applyStartTime.split(' ')[0];
        }

        if (parentRes.data[0].applyFinishTime && parentRes.data[0].applyFinishTime.includes(':')) {
          parentRes.data[0].applyFinishTime = parentRes.data[0].applyFinishTime.split(' ')[0];
        }

        if (parentRes.data[0].reachStartTime && parentRes.data[0].reachStartTime.includes(':')) {
          parentRes.data[0].reachStartTime = parentRes.data[0].reachStartTime.split(' ')[0];
        }

        if (parentRes.data[0].reachFinishTime && parentRes.data[0].reachFinishTime.includes(':')) {
          parentRes.data[0].reachFinishTime = parentRes.data[0].reachFinishTime.split(' ')[0];
        }

        if (parentRes.data[0].getStartTime && parentRes.data[0].getStartTime.includes(':')) {
          parentRes.data[0].getStartTime = parentRes.data[0].getStartTime.split(' ')[0];
        }

        if (parentRes.data[0].getFinishTime && parentRes.data[0].getFinishTime.includes(':')) {
          parentRes.data[0].getFinishTime = parentRes.data[0].getFinishTime.split(' ')[0];
        }

        var resObj = {
          secUUID: parentRes.data[0].secUuid,
          // secUUID: this.row.id,
          applyStartTime: parentRes.data[0].applyStartTime ? parentRes.data[0].applyStartTime : null,
          applyFinishTime: parentRes.data[0].applyFinishTime ? parentRes.data[0].applyFinishTime : null,
          reachStartTime: parentRes.data[0].reachStartTime ? parentRes.data[0].reachStartTime : null,
          reachFinishTime: parentRes.data[0].reachFinishTime ? parentRes.data[0].reachFinishTime : null,
          getStartTime: parentRes.data[0].getStartTime ? parentRes.data[0].getStartTime : null,
          getFinishTime: parentRes.data[0].getFinishTime ? parentRes.data[0].getFinishTime : null
        };
        _this2.selectedRow = resObj;
      });
    }
  },
  created: function created() {
    this.getDetailData();
  }
};
exports.default = _default;