"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = isExternal;

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}