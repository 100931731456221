var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.row.id ? "编辑" : "新增",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        },
        open: _vm.handleOpen
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "rowForm",
          attrs: { model: _vm.row, rules: _vm.rules, "label-width": "100px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "员工编号", prop: "userCode" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.row.userCode,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "userCode", $$v)
                  },
                  expression: "row.userCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "员工名称", prop: "nickName" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.row.nickName,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "nickName", $$v)
                  },
                  expression: "row.nickName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "mobile" } },
            [
              _c("el-input", {
                attrs: { type: "phone", autocomplete: "off" },
                model: {
                  value: _vm.row.mobile,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "mobile", $$v)
                  },
                  expression: "row.mobile"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邮箱", prop: "email" } },
            [
              _c("el-input", {
                attrs: { type: "email", autocomplete: "off" },
                model: {
                  value: _vm.row.email,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "email", $$v)
                  },
                  expression: "row.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "机构编号", prop: "orgnCode" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.row.orgnCode,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "orgnCode", $$v)
                  },
                  expression: "row.orgnCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "机构名称", prop: "orgnName" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.row.orgnName,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "orgnName", $$v)
                  },
                  expression: "row.orgnName"
                }
              })
            ],
            1
          ),
          _vm.row.platform !== "Member_Manager" && _vm.row.platform !== "Member"
            ? _c(
                "el-form-item",
                { attrs: { label: "角色分配", prop: "roleList" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { display: "block" },
                      attrs: { filterable: "", placeholder: "请选择角色" },
                      model: {
                        value: _vm.rtem,
                        callback: function($$v) {
                          _vm.rtem = $$v
                        },
                        expression: "rtem"
                      }
                    },
                    _vm._l(_vm.roles, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.roleName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "头像" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    name: "file",
                    action: "''",
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                    "http-request": _vm.handleUpload
                  }
                },
                [
                  _vm.row.avatarUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm.convertImageUrl(_vm.row.avatarUrl.url)
                        }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon"
                      })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["ROLE_ADMIN", "User_List_save"],
                  expression: "['ROLE_ADMIN', 'User_List_save']"
                }
              ],
              attrs: { type: "primary", loading: _vm.btnSaveLoading },
              on: { click: _vm.handleSave }
            },
            [_vm._v("保存 ")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "90%",
            title: "员工信息",
            visible: _vm.innerVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.innerVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select",
                  staticStyle: { width: "300px", margin: "0 0 10px 0" },
                  attrs: { placeholder: "员工姓名" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleQuery($event)
                    }
                  },
                  model: {
                    value: _vm.queryParam.displayName,
                    callback: function($$v) {
                      _vm.$set(_vm.queryParam, "displayName", $$v)
                    },
                    expression: "queryParam.displayName"
                  }
                },
                [
                  _c("el-button", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["ROLE_ADMIN", "User_List_load"],
                        expression: "['ROLE_ADMIN', 'User_List_load']"
                      }
                    ],
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.handleQuery },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      }
                    },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                loading: _vm.listLoading,
                data: _vm.list,
                "header-cell-style": { "text-align": "center" },
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "机构名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orgnName))])]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "机构编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.websiteId))])]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "员工姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.employeeName))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "员工编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.employeeNo))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "手机号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.mobile))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "邮箱" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.email))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  width: "170",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleDetail(row, $index)
                              }
                            }
                          },
                          [_vm._v("选择")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryParam.pageNo,
              "page-size": _vm.queryParam.pageSize,
              total: _vm.queryParam.totalCount,
              "page-sizes": [10, 20, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:total": function($event) {
                return _vm.$set(_vm.queryParam, "totalCount", $event)
              },
              "size-change": _vm.handleQuery,
              "current-change": _vm.handleQuery
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }