"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.some.js");

require("core-js/modules/es.string.includes.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("@/store"));

/**
 * 按钮权限指令使用方法
 * <pre>
 * import permission from '@/directive/permission' // 导入权限指令
 *
 * export default {
 *   directives: {permission}
 * }
 *
 * 组件上添加 v-permission="['ROLE_ADMIN', '']"
 * </pre>
 */
// export default {
//   inserted(el, binding) {
//     const {value} = binding
//     // const authorityList = store.getters && store.getters.authorityList
//     if (value && value instanceof Array && value.length > 0) {
//       // const permissionRoles = value
//       // const hasPermission = authorityList.some(role => {
//       //   return permissionRoles.includes(role)
//       // })
//       // if (!hasPermission) {
//       //   el.parentNode && el.parentNode.removeChild(el)
//       // }
//     } else {
//       throw new Error(`need roles! Like v-permission="['ROLE_ADMIN', 'editor']"`)
//     }
//   }
// }
var _default = {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var authorityList = _store.default.getters && _store.default.getters.authorityList;

    if (value && value instanceof Array && value.length > 0) {
      var permissionRoles = value;
      var hasPermission = authorityList.some(function (role) {
        return permissionRoles.includes(role);
      });

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("need roles! Like v-permission=\"['ROLE_ADMIN', 'editor']\"");
    }
  }
};
exports.default = _default;