var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "actBgUpload",
      attrs: {
        action: "''",
        "file-list": _vm.bgImgList,
        "list-type": "picture-card",
        "show-file-list": true,
        "on-success": _vm.handlePicSuccess,
        "before-upload": _vm.beforePicUpload,
        "http-request": _vm.handlePicUpload
      },
      scopedSlots: _vm._u([
        {
          key: "file",
          fn: function(ref) {
            var file = ref.file
            return _c("div", {}, [
              _c("img", {
                staticClass: "el-upload-list__item-thumbnail",
                staticStyle: { width: "146px", height: "146px" },
                attrs: { src: _vm.convertImgUrlPrefix(file.uname), alt: "" }
              }),
              _c("span", { staticClass: "el-upload-list__item-actions" }, [
                _c(
                  "span",
                  {
                    staticClass: "el-upload-list__item-edit",
                    on: {
                      click: function($event) {
                        return _vm.handlePicEdit(file)
                      }
                    }
                  },
                  [_c("i", { staticClass: "el-icon-edit" })]
                ),
                _c(
                  "span",
                  {
                    staticClass: "el-upload-list__item-delete",
                    on: {
                      click: function($event) {
                        return _vm.handlePicRemove(file)
                      }
                    }
                  },
                  [_c("i", { staticClass: "el-icon-delete" })]
                )
              ])
            ])
          }
        }
      ])
    },
    [
      _c("i", {
        staticClass: "el-icon-plus",
        attrs: { slot: "default" },
        slot: "default"
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }