var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: "图片查看",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "900px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.selectedRow } },
        [
          _vm.type == 1
            ? _c("el-form-item", { attrs: { label: "规则图片：" } }, [
                _vm.row.videoUrl && _vm.row.videoUrl.uname
                  ? _c("img", {
                      staticStyle: { width: "100px", "max-height": "100px" },
                      attrs: {
                        src: _vm.convertImgUrlPrefix(_vm.row.videoUrl.uname),
                        alt: ""
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.type == 2
            ? _c("el-form-item", { attrs: { label: "banner：" } }, [
                _vm.row.simpleBanner &&
                _vm.row.simpleBanner.bannerUrl &&
                _vm.row.simpleBanner.bannerUrl.uname
                  ? _c("img", {
                      staticStyle: { width: "100px", "max-height": "100px" },
                      attrs: {
                        src: _vm.convertImgUrlPrefix(
                          _vm.row.simpleBanner.bannerUrl.uname
                        ),
                        alt: ""
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.type == 2
            ? _c("el-form-item", { attrs: { label: "详情展示页：" } }, [
                _vm.row.detailBanner &&
                _vm.row.detailBanner.bannerUrl &&
                _vm.row.detailBanner.bannerUrl.uname
                  ? _c("img", {
                      staticStyle: { width: "100px", "max-height": "100px" },
                      attrs: {
                        src: _vm.convertImgUrlPrefix(
                          _vm.row.detailBanner.bannerUrl.uname
                        ),
                        alt: ""
                      }
                    })
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("关 闭")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }