var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.queryParam }
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.handleAdd }
                    },
                    [_vm._v("新增 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.btnDeleteLoading,
                        type: "danger",
                        icon: "el-icon-delete"
                      },
                      on: { click: _vm.handleBatchDelete }
                    },
                    [_vm._v(" 删除 ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "140px" },
                    attrs: { placeholder: "客户号" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      }
                    },
                    model: {
                      value: _vm.queryParam.customerId,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParam, "customerId", $$v)
                      },
                      expression: "queryParam.customerId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["ROLE_ADMIN", "Buy_Product_load"],
                          expression: "['ROLE_ADMIN', 'Buy_Product_load']"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [_vm._v("查询 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "cell-style": { "text-align": "center" },
            "header-cell-style": { "text-align": "center" },
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            width: "1020"
          },
          on: { "selection-change": _vm.handleSelected }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "60", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "客户号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.customerId))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "editText",
                        attrs: { size: "mini", type: "text", circle: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row, $index)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "好的",
                          "cancel-button-text": "取消",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "您确认要删除这条记录吗?"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["ROLE_ADMIN", "Redemption_delete"],
                                expression:
                                  "['ROLE_ADMIN', 'Redemption_delete']"
                              }
                            ],
                            staticClass: "text-red",
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "text",
                              circle: ""
                            },
                            slot: "reference"
                          },
                          [_vm._v(" 删除 ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryParam.pageNo,
              "page-size": _vm.queryParam.pageSize,
              total: _vm.queryParam.totalCount,
              "page-sizes": [10, 20, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:total": function($event) {
                return _vm.$set(_vm.queryParam, "totalCount", $event)
              },
              "size-change": function($event) {
                return _vm.handleQuery("page")
              },
              "current-change": function($event) {
                return _vm.handleQuery("page")
              }
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.selectedRow.id ? "编辑" : "新增",
            visible: _vm.visibleSaveDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleSaveDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "rowForm",
              attrs: {
                model: _vm.selectedRow,
                rules: _vm.rules,
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户号", prop: "customerId" } },
                [
                  _c("el-input", {
                    attrs: { type: "phone", autocomplete: "off" },
                    model: {
                      value: _vm.selectedRow.customerId,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedRow, "customerId", $$v)
                      },
                      expression: "selectedRow.customerId"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["ROLE_ADMIN", "User_List_save"],
                      expression: "['ROLE_ADMIN', 'User_List_save']"
                    }
                  ],
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("保存 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }