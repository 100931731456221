"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
        icon = _context$props.icon,
        title = _context$props.title;
    var vnodes = [];

    if (icon) {
      // 这里注意不能换行,但是格式化代码会自动换行
      // vnodes.push(<svg-icon icon-class={icon}/>)
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }

    if (title) {
      // 这里注意不能换行,但是格式化代码会自动换行
      // vnodes.push(<span slot='title'>{(title)}</span>)
      vnodes.push(h("span", {
        "slot": 'title'
      }, [title]));
    }

    return vnodes;
  }
};
exports.default = _default;