var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "800px",
            title: _vm.Form.id ? "编辑" : "新增",
            visible: _vm.visible,
            "before-close": _vm.closeForm
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "Form",
              staticStyle: { width: "700px" },
              attrs: {
                model: _vm.Form,
                "label-width": "180px",
                rules: _vm.Rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "详情页名称", prop: "pageName" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "请输入详情页名称"
                    },
                    model: {
                      value: _vm.Form.pageName,
                      callback: function($$v) {
                        _vm.$set(_vm.Form, "pageName", $$v)
                      },
                      expression: "Form.pageName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入描述信息" },
                    model: {
                      value: _vm.Form.describe,
                      callback: function($$v) {
                        _vm.$set(_vm.Form, "describe", $$v)
                      },
                      expression: "Form.describe"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详情页类型", prop: "pageType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.Form.pageType,
                        callback: function($$v) {
                          _vm.$set(_vm.Form, "pageType", $$v)
                        },
                        expression: "Form.pageType"
                      }
                    },
                    _vm._l(_vm.pageTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景图片", prop: "backImageDto" } },
                [
                  _c("CommonFileUpload", {
                    attrs: {
                      value: _vm.Form.backImageDto
                        ? [_vm.Form.backImageDto]
                        : [],
                      limit: 1,
                      isDetail: _vm.isDetail
                    },
                    on: { change: _vm.reviewFileChange, btnLoad: _vm.btnLoad }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "咨询跳转功能", prop: "isConsultationJump" }
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.Form.isConsultationJump,
                        callback: function($$v) {
                          _vm.$set(_vm.Form, "isConsultationJump", $$v)
                        },
                        expression: "Form.isConsultationJump"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm.Form.isConsultationJump
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { "margin-left": "30px" },
                          attrs: { size: "small", placeholder: "请选择类型" },
                          model: {
                            value: _vm.Form.jumpPage,
                            callback: function($$v) {
                              _vm.$set(_vm.Form, "jumpPage", $$v)
                            },
                            expression: "Form.jumpPage"
                          }
                        },
                        _vm._l(_vm.jumpPageOptions, function(item) {
                          return _c("el-option", {
                            key: item.dictValue,
                            attrs: {
                              label: item.dictLabel,
                              value: item.dictValue
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c("br"),
                  _vm.Form.isConsultationJump
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _vm._v(" 按钮显示名称： "),
                              _c("CommonFileUpload", {
                                attrs: {
                                  value: _vm.Form.consultButtonNameDto
                                    ? [_vm.Form.consultButtonNameDto]
                                    : [],
                                  limit: 1,
                                  isDetail: _vm.isDetail
                                },
                                on: {
                                  change: _vm.buttonFileChange,
                                  btnLoad: _vm.btnLoad
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm._v(" 按钮起始坐标："),
                              _c("el-input", {
                                staticStyle: { width: "120px" },
                                attrs: {
                                  size: "small",
                                  placeholder: "请输入内容"
                                },
                                model: {
                                  value: _vm.Form.consultButtonStartCoordinates,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.Form,
                                      "consultButtonStartCoordinates",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "Form.consultButtonStartCoordinates"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.productDisplayDTOList !== []
                ? _c(
                    "div",
                    _vm._l(_vm.productDisplayDTOList, function(item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("div", { staticClass: "title" }, [
                            _c("i", {
                              staticClass: "el-icon-remove",
                              on: {
                                click: function($event) {
                                  return _vm.deleteSublevel(index)
                                }
                              }
                            }),
                            _c("div", { staticClass: "liftTitle" }, [
                              _vm._v("产品展示板块 " + _vm._s(index + 1))
                            ])
                          ]),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { flex: "1" },
                                  attrs: { label: "展示板块名称" }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "请输入展示板块名称",
                                      size: "small"
                                    },
                                    model: {
                                      value: item.displayName,
                                      callback: function($$v) {
                                        _vm.$set(item, "displayName", $$v)
                                      },
                                      expression: "item.displayName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { flex: "1" },
                                  attrs: { label: "名称展示" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small"
                                      },
                                      model: {
                                        value: item.isNameDisplay,
                                        callback: function($$v) {
                                          _vm.$set(item, "isNameDisplay", $$v)
                                        },
                                        expression: "item.isNameDisplay"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "是", value: 1 }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "否", value: 0 }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { flex: "1" },
                                  attrs: { label: "板块页面布局" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small"
                                      },
                                      model: {
                                        value: item.pageLayout,
                                        callback: function($$v) {
                                          _vm.$set(item, "pageLayout", $$v)
                                        },
                                        expression: "item.pageLayout"
                                      }
                                    },
                                    _vm._l(_vm.pageLayoutOptions, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.dictValue,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.dictValue
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { flex: "1" },
                                  attrs: { label: "业务办理流程图" }
                                },
                                [
                                  _c("CommonFileUpload", {
                                    attrs: {
                                      value: item.processFlowchartAddDto
                                        ? [item.processFlowchartAddDto]
                                        : [],
                                      limit: 1,
                                      isDetail: _vm.isDetail,
                                      paramData: { index: index }
                                    },
                                    on: {
                                      change: _vm.businessFileChange,
                                      btnLoad: _vm.btnLoad
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收集意向客户功能" } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value: item.isCollectPotentialCustomers,
                                    callback: function($$v) {
                                      _vm.$set(
                                        item,
                                        "isCollectPotentialCustomers",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "item.isCollectPotentialCustomers"
                                  }
                                },
                                [_vm._v("启用")]
                              ),
                              _c("br"),
                              item.isCollectPotentialCustomers
                                ? _c(
                                    "div",
                                    [
                                      _vm._v(" 按钮起始坐标："),
                                      _c("el-input", {
                                        staticStyle: { width: "120px" },
                                        attrs: {
                                          size: "small",
                                          placeholder: "请输入内容"
                                        },
                                        model: {
                                          value:
                                            item.collectButtonStartCoordinates,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "collectButtonStartCoordinates",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.collectButtonStartCoordinates"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openProduct(index)
                                    }
                                  }
                                },
                                [_vm._v("添加")]
                              ),
                              _vm.productDisplayDTOList[index]
                                .productDetailDTOList.length
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.productDisplayDTOList[index]
                                        .productDetailDTOList,
                                      function(item2, index2) {
                                        return _c(
                                          "el-tag",
                                          {
                                            key: index2,
                                            staticClass: "equitySpan",
                                            attrs: {
                                              closable: "",
                                              effect: "dark"
                                            },
                                            on: {
                                              close: function($event) {
                                                return _vm.closeProduct(
                                                  index,
                                                  index2
                                                )
                                              },
                                              click: function($event) {
                                                return _vm.changeProduct(
                                                  item2,
                                                  index,
                                                  index2
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " 产品" + _vm._s(index2 + 1) + " "
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "addLift",
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.addSublevel("promoteTaskDTOList")
                    }
                  }
                },
                [_vm._v("+ 添加产品展示板块")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "页面布局" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.Form.pageLayout,
                        callback: function($$v) {
                          _vm.$set(_vm.Form, "pageLayout", $$v)
                        },
                        expression: "Form.pageLayout"
                      }
                    },
                    _vm._l(_vm.pageLayoutOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeForm } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleAdd }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          ),
          _c("ProductDialog", {
            attrs: { visible: _vm.ProductVisible, row: _vm.ProductList },
            on: {
              closeProductDialog: _vm.closeProductDialog,
              addProduct: _vm.addProduct
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }