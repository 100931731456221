var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.flag,
          expression: "flag"
        }
      ],
      staticClass: "app-container",
      attrs: {
        "element-loading-text": "报表下载中，请稍等",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)"
      }
    },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.queryParam }
        },
        [
          _c(
            "el-form-item",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入客户号" },
                    model: {
                      value: _vm.queryParam.customerId,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParam, "customerId", $$v)
                      },
                      expression: "queryParam.customerId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入礼品名称" },
                    model: {
                      value: _vm.queryParam.giftName,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParam, "giftName", $$v)
                      },
                      expression: "queryParam.giftName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择活动" },
                      model: {
                        value: _vm.queryParam.activityId,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParam, "activityId", $$v)
                        },
                        expression: "queryParam.activityId"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "全部", value: "ALL" }
                      }),
                      _vm._l(_vm.activityOptions, function(item) {
                        return _c("el-option", {
                          key: item.secUuid,
                          attrs: {
                            label: item.activityName,
                            value: item.secUuid
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.download } },
                    [_vm._v("导出Excel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "cell-style": { "text-align": "center" },
            "header-cell-style": { "text-align": "center" },
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "活动名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.activityName))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "客户号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.customerId))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "礼品名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.giftName))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "领取明细" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.receiveDetail))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "渠道来源" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm.channelTypePreview(scope.row.channelSource))
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "领取时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.receiveTime))]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.queryParam.pageNo,
              "page-size": _vm.queryParam.pageSize,
              total: _vm.queryParam.totalCount,
              "page-sizes": [10, 20, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.queryParam, "pageNo", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.queryParam, "pageSize", $event)
              },
              "update:total": function($event) {
                return _vm.$set(_vm.queryParam, "totalCount", $event)
              },
              "size-change": function($event) {
                return _vm.handleQuery("page")
              },
              "current-change": function($event) {
                return _vm.handleQuery("page")
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }