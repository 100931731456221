var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: _vm.targetType == "crm" ? "CRM标签" : "自定义标签",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "600px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "checkBoxContainer" },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.targetCheck,
                callback: function($$v) {
                  _vm.targetCheck = $$v
                },
                expression: "targetCheck"
              }
            },
            _vm._l(_vm.checkBoxList, function(option, checkIndex) {
              return _c(
                "el-checkbox",
                { key: "position" + checkIndex, attrs: { label: option } },
                [_vm._v(_vm._s(option))]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnSaveLoading },
              on: { click: _vm.handleSave }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }