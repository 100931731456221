"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _uniqueId = _interopRequireDefault(require("lodash/uniqueId"));

var _charts = _interopRequireDefault(require("@/components/Charts/mixin/charts"));

//
//
//
//
//
//
var _default = {
  mixins: [_charts.default],
  props: {
    data1: {
      type: Array,
      default: null
    },
    data2: {
      type: Array,
      default: null
    },
    xData: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {
      timepick: '',
      charts: {
        chart: {
          id: (0, _uniqueId.default)('chart_'),
          instance: null,
          // charts mixin 时会通过混入的方式初始化 echarts 实例到这个属性
          option: {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'

              }
            },
            grid: {
              left: '1%',
              right: '1%',
              bottom: '3%',
              top: '10%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: this.xData
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              }
            },
            series: [{
              name: '客户经理参与人数',
              type: 'bar',
              // barWidth: '30px', // 控制柱子宽度
              itemStyle: {
                color: '#5087EC'
              },
              z: 3,
              data: this.data1,
              barGap: 0
            }, {
              name: '客户报名人数',
              type: 'bar',
              // barWidth: '30px', // 控制柱子宽度
              itemStyle: {
                color: '#75F9FD'
              },
              z: 2,
              data: this.data2,
              barGap: 0
            } // {
            //     name: '累计报名人数',
            //     type: 'bar',
            //     itemStyle: {
            //         color: '#5087EC',
            //     },
            //     z: 3,
            //     data: this.data1,
            //     barGap: 0,
            // },
            // {
            //     name: '达成人数',
            //     type: 'bar',
            //     itemStyle: {
            //         color: '#75F9FD',
            //     },
            //     z: 2,
            //     data: this.data2,
            //     barGap: 0,
            // },
            // {
            //     name: '领奖人数',
            //     type: 'bar',
            //     itemStyle: {
            //         color: '#58A55C',
            //     },
            //     z: 1,
            //     data: this.data3,
            //     barGap: 0,
            // },
            ],
            axisLine: {
              lineStyle: {
                color: '#215c7e' // 这里设置X轴的颜色

              }
            }
          }
        }
      }
    };
  },
  created: function created() {}
};
exports.default = _default;