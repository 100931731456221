"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ActivityManager = _interopRequireDefault(require("@/api/ActivityManager"));

var _TargetDialog = _interopRequireDefault(require("@/views/activityProduct/activityManager/components/TargetDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    TargetDialog: _TargetDialog.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      rules: {},
      selectedRow: {
        cusType: 0,
        selType: 0
      },
      targetOptions: [{
        dictValue: 0,
        dictLabel: '目标用户'
      }, {
        dictValue: 1,
        dictLabel: '其他用户'
      }],
      checkBoxList: ['客群', '标签'],
      targetCheck: [],
      target: '0',
      cusGroupOptions: [],
      whtListOptions: [],
      blkListOptions: [],
      targetDialog: false,
      targetType: 'crm',
      crmTargetList: [],
      customTargetList: [],
      targetList: []
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this = this;

      this.selectedRow.activityUuid = this.row.secUuid;
      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          var postParam = JSON.parse(JSON.stringify(_this.selectedRow));

          if (postParam.cusGroupUuids) {
            postParam.cusGroupUuids = JSON.stringify(postParam.cusGroupUuids);
          }

          _ActivityManager.default.userSelEdit(postParam).then(function (result) {
            result.hasFailure(function () {
              _this.btnSaveLoading = false;

              _this.$message.warning(result.getErrorMessage('保存失败'));
            }).hasSuccess(function () {
              _this.btnSaveLoading = false;

              _this.$message.success('保存成功');

              _this.handleCancel();

              _this.$emit('ok');
            });
          });
        } else {
          return false;
        }
      });
    },
    // 打开tag标签弹窗
    openTagDialog: function openTagDialog(val) {
      this.targetType = val;
      this.targetList = val == 'crm' ? this.crmTargetList : this.customTargetList;
      this.targetDialog = true;
    },
    // tag标签选择成功回调
    targetSelect: function targetSelect(val) {
      if (this.targetType == 'crm') {
        // crm回调
        this.crmTargetList = val;
      } else {
        // 自定义回调
        this.customTargetList = val;
      }
    },
    getCustomerData: function getCustomerData() {
      var _this2 = this;

      // 查询主活动详情 获取客群配置
      _ActivityManager.default.getParentDetail(this.row.id).then(function (parentRes) {
        var userSelVo = parentRes.data[0].userSelVo || null;

        if (userSelVo) {
          // const setRow = {
          //   selType: userSelVo.selType,
          //   cusType
          // }
          // this.selectedRow = setRow
          if (userSelVo.cusGroupUuids) {
            userSelVo.cusGroupUuids = JSON.parse(userSelVo.cusGroupUuids);
          }

          _this2.selectedRow = userSelVo;
        }
      });
    }
  },
  created: function created() {
    var _this3 = this;

    // 基础卡片布局数据字典
    _ActivityManager.default.dictListGet("COLLECT_CARD_LAYOUT").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.cardLayoutOptions = result.data[0];
    }); // 进阶任务位置数据字典


    _ActivityManager.default.dictListGet("COLLECT_TASK_POSITION").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this3.taskPositionOptions = result.data[0];
    }); // 客群选择下拉框


    _ActivityManager.default.getGroupList().then(function (result) {
      // console.log(result);
      _this3.cusGroupOptions = result.data;
    }); // 黑名单


    _ActivityManager.default.blackListBatchGet({
      pageNo: 1,
      pageSize: 999
    }).then(function (result) {
      _this3.blkListOptions = result.data;
    }); // 白名单


    _ActivityManager.default.whiteListBatchGet({
      pageNo: 1,
      pageSize: 999
    }).then(function (result) {
      _this3.whtListOptions = result.data;
    }); // 编辑反显
    // if(this.row){
    //   this.selectedRow = this.row
    // }


    this.getCustomerData();
  }
};
exports.default = _default;