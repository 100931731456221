"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _http = require("./api/http");

var _Result = require("@/utils/entity/Result");

//
//
//
//
//
//
var _default = {
  name: 'App',
  data: function data() {
    return {
      first: true // 标记为首次加载

    };
  },
  created: function created() {
    var _this = this;

    // 设置消息弹窗
    (0, _Result.setToastSuccessMessage)(function (msg) {
      _this.$message.success(msg);
    });
    (0, _Result.setToastErrorMessage)(function (msg, code) {
      // switch (code){
      //   'A00005':
      //     this.$message.error('自定义异常消息，不弹出后端返回的 message')
      //     return
      // }
      _this.$message.error(msg);
    }); // http 响应拦截

    (0, _http.setResponseInterceptor)(function (response) {
      var code = response.data.code;

      if (code === 'A00003') {
        // if (this.first) {
        //   this.first = false
        //   this.$store.dispatch('user/resetToken').then(() => {
        //     console.error('会话超时1')
        //     location.reload()
        //   })
        // } else {
        //   this.$message.error({
        //     message: '会话超时',
        //     duration: 1500,
        //     onClose: () => {
        //       this.$store.dispatch('user/resetToken').then(() => {
        //         console.error('会话超时2')
        //         location.reload()
        //       })
        //     }
        //   })
        // }
        _this.$message.error({
          message: '会话超时',
          duration: 1500,
          onClose: function onClose() {
            _this.$store.dispatch('user/resetToken').then(function () {
              location.reload();
            });
          }
        });
      } else {
        return response;
      }
    }); // CommonService.csrfToken() // 初始化 csrf token
  }
};
exports.default = _default;