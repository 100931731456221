"use strict";

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

/**
 * 替换图片URL
 * @param url {string}
 * @return {string}
 */
function _default(url) {
  if (process.env.VUE_APP_REPLACE_URL && process.env.VUE_APP_TRUTH_URL && url) {
    // console.log(url.replace(process.env.VUE_APP_REPLACE_URL, process.env.VUE_APP_TRUTH_URL))
    return url.replace(process.env.VUE_APP_REPLACE_URL, process.env.VUE_APP_TRUTH_URL);
  }

  return url;
}