"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("E:\\\u684C\u9762\\company\\fdm-product-manager-web\\node_modules\\core-js\\modules\\es.array.iterator.js");

require("E:\\\u684C\u9762\\company\\fdm-product-manager-web\\node_modules\\core-js\\modules\\es.promise.js");

require("E:\\\u684C\u9762\\company\\fdm-product-manager-web\\node_modules\\core-js\\modules\\es.object.assign.js");

require("E:\\\u684C\u9762\\company\\fdm-product-manager-web\\node_modules\\core-js\\modules\\es.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

require("@/styles/index.scss");

require("@/utils/string-prototype");

require("@/utils/date-prototype");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _echarts = _interopRequireDefault(require("echarts"));

var _vueBaiduMap = _interopRequireDefault(require("vue-baidu-map"));

require("@/icons");

require("@/permission");

require("@/api/http");

var _vueQuillEditor = _interopRequireDefault(require("vue-quill-editor"));

require("quill/dist/quill.core.css");

require("quill/dist/quill.snow.css");

require("quill/dist/quill.bubble.css");

var _BtnShowCtrl = require("@/api/entity/BtnShowCtrl");

var _queryParamInit = require("@/api/entity/queryParamInit");

// A modern alternative to CSS resets
// global css
// String 原型扩展
// Date 原型扩展
// icon
// permission control
// permission control
_vue.default.prototype.$echarts = _echarts.default;
_vue.default.prototype.$getShowPageBtn = _BtnShowCtrl.getShowPageBtn;
_vue.default.prototype.$setParamPageNo = _queryParamInit.setParamPageNo; // import locale from 'element-ui/lib/locale/lang/en' // lang i18n

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const {mockXHR} = require('../mock');
//   mockXHR();
// }
//文本编辑器插件

_vue.default.use(_vueQuillEditor.default); // set ElementUI lang to EN
// Vue.use(ElementUI, {locale})
// 如果想要中文版 element-ui，按如下方式声明


_vue.default.use(_elementUi.default);

_vue.default.use(_vueBaiduMap.default, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'UKLfxW1A7sayMGdFGzSuRYXGQBmVWZUH'
});

_vue.default.config.productionTip = false;

if (process.env.VUE_APP_MOCK_ENABLED === 'YES') {
  var _require = require('../mock'),
      mockXHR = _require.mockXHR;

  mockXHR();
}

if (process.env.NODE_ENV === 'production') {
  // 记录异常日志
  _vue.default.config.errorHandler = function (err, vm, info, a) {
    // Don't ask me why I use Vue.nextTick, it just a hack.
    // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
    _vue.default.nextTick(function () {
      _store.default.dispatch('errorLog/addErrorLog', {
        err: err,
        vm: vm,
        info: info,
        url: window.location.href
      });

      console.error(err, info);
    });
  };
}

new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  data: function data() {
    return {
      menuData: null
    };
  },
  render: function render(h) {
    return h(_App.default);
  }
});