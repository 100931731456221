"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _CustomerGradeConfigService = _interopRequireDefault(require("@/api/CustomerGradeConfigService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    },
    isDetail: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      rules: {// taskTitle: [
        //   { required: true, message: "请输入任务名称", trigger: "blur" },
        // ],
        // taskCode: [
        //   { required: true, message: "请输入任务编码", trigger: "blur" },
        // ],
        // taskType: [
        //   { required: true, message: "请选择任务类型", trigger: "change" },
        // ],
        // executeClass: [
        //   { required: true, message: "请输入执行类全名", trigger: "blur" },
        // ],
        // status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      // 等级类型
      gradeTypeOptions: [],
      // 判断方式
      judeTypeOptions: [],
      // 结算时间
      settleTimeTypeOptions: [],
      // 计算方式
      computeTypeOptions: [],
      isEdit: false
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this = this;

      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          _this.btnSaveLoading = true;
          (_this.row.id ? _CustomerGradeConfigService.default.update(_this.row) : _CustomerGradeConfigService.default.save(_this.row)).then(function (result) {
            result.hasFailure(function () {
              _this.btnSaveLoading = false;

              _this.$message.warning(result.getErrorMessage("保存失败"));
            }).hasSuccess(function () {
              _this.btnSaveLoading = false;

              _this.$message.success("保存成功");

              _this.handleCancel();

              _this.$emit("ok");
            });
          });
        } else {
          return false;
        }
      });
    }
  },
  created: function created() {
    var _this2 = this;

    if (this.row.id) {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }

    _CustomerGradeConfigService.default.dictListGet("GRADE_JUDGMENT_METHOD").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this2.judeTypeOptions = result.data[0];
    });

    _CustomerGradeConfigService.default.dictListGet("CUSTOMER_GRADE").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this2.gradeTypeOptions = result.data[0];
    });

    _CustomerGradeConfigService.default.dictListGet("ACTIVITY_SETTLEMENT_TIME").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this2.settleTimeTypeOptions = result.data[0];
    });

    _CustomerGradeConfigService.default.dictListGet("CALCULATE_TYPE").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this2.computeTypeOptions = result.data[0];
    });
  }
};
exports.default = _default2;