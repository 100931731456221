"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _permission = _interopRequireDefault(require("./permission"));

/**
 * 按钮权限指令使用方法
 * <pre>
 * import permission from '@/directive/permission' // 导入权限指令
 *
 * export default {
 *   directives: {permission}
 * }
 *
 * 组件上添加 v-permission="['ROLE_ADMIN']"
 * </pre>
 */
var install = function install(Vue) {
  Vue.directive('permission', _permission.default);
};

if (window.Vue) {
  window['permission'] = _permission.default;
  Vue.use(install); // eslint-disable-line
}

_permission.default.install = install;
var _default = _permission.default;
exports.default = _default;