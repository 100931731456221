"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Enums = require("@/api/Enums");

var _utils = require("@/utils");

var _http = _interopRequireDefault(require("@/api/http"));

var _Result = _interopRequireDefault(require("@/utils/entity/Result"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'FileUpload',
  props: {
    url: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    fileType: {
      type: String,
      default: 'file'
    },
    size: {
      type: String,
      default: 'mini'
    },
    type: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String,
      default: '上传文件'
    },
    drag: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: false
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      // path: "",
      acceptVal: undefined,
      fileInfo: [],
      acceptTypes: _Enums.FileTypes,
      fileTypeMap: _Enums.FileTypeMap
    };
  },
  watch: {
    value: function value(val) {
      if (val[0] === undefined || val[0] === null) {
        this.fileInfo = [];
      } else {
        // 为了兼容内网外网同时访问到图片
        val.forEach(function (row) {
          if (row && row.url) {
            row.url = row.url;
          } else {
            row.url = null;
          }
        });
        this.fileInfo = Object.assign([], val);
      }
    },
    fileType: {
      handler: function handler(val) {
        var _this = this;

        if (val !== 'file' && !this.drag) {
          var acceptFileTypes = this.fileType.split(',');
          var accepts = [];
          acceptFileTypes.forEach(function (item) {
            accepts.push(_this.fileTypeMap[item]);
          });
          this.acceptVal = accepts.join(',');
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    if (this.value === undefined) {
      this.fileInfo = [];
    } else {
      if (this.value[0]) {
        this.fileInfo = Object.assign([], this.value);
      }
    }
  },
  // created() {
  //   this.path = process.env.VUE_APP_BASE_API.concat(this.action)
  // },
  methods: {
    handleUpload: function handleUpload(_ref) {
      var file = _ref.file,
          onSuccess = _ref.onSuccess,
          onError = _ref.onError,
          onProgress = _ref.onProgress;
      // if (file.size > 5 * 1024 * 1024) {
      //   this.$message.error('文件大小超过限制')
      //   return false // 必须返回false
      // }
      var form = new FormData();
      form.append('file', file);
      var headers = form.getHeaders ? form.getHeaders() : {};

      _http.default.post(this.url, form, {
        headers: headers,
        onUploadProgress: function onUploadProgress(progressEvent) {
          var num = progressEvent.loaded / progressEvent.total * 100 || 0;
          onProgress({
            percent: num
          });
        }
      }).then(function (result) {
        var res = _Result.default.of(result.data);

        res.dataFirst(function (row) {
          return onSuccess(row);
        }, function () {
          return onError('文件上传成功，但服务器未返回文件信息');
        }).hasFailure(function (res) {
          return onError(res.getErrorMessage('文件上传失败'));
        });
      });
    },

    /**
     * 上传成功
     */
    handleUploadSuccess: function handleUploadSuccess(res, file, files) {
      var fileInfo = [{}];
      fileInfo[0].name = res.name;
      fileInfo[0].uname = res.uname;
      fileInfo[0].url = res.url;
      this.syncFileInfo(fileInfo);
      this.$emit('success', null); // if (res.code === "A00000") {
      //   if (res.data) {
      //     const fileInfo = [{}]
      //     fileInfo[0].name = res.data[0].name
      //     fileInfo[0].uname = res.data[0].uname
      //     fileInfo[0].url = res.data[0].url
      //     this.syncFileInfo(fileInfo)
      //   }
      //   this.$emit("success", null)
      // } else {
      //   this.$message.error("上传失败！" + res.message)
      //   this.$refs.upload.clearFiles()
      // }
    },

    /**
     * 上传超过限制
     */
    handleUploadExceed: function handleUploadExceed(res) {
      this.$notify({
        title: '警告',
        message: '上传数量超过限制',
        type: 'warning'
      });
    },

    /**
     * 删除图片
     */
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
      this.syncFileInfo(fileList);
    },
    handleError: function handleError(err) {
      this.$message.error(err || '上传失败');
    },

    /**
     * 同步fileInfo
     */
    syncFileInfo: function syncFileInfo(fileList) {
      var result = [];

      if (fileList.length > 0) {
        result = fileList;
      } else {
        result = [];
      }

      this.$emit('input', result);
      this.$emit('change', result, this.index);
    },
    beforeUpload: function beforeUpload(file) {
      var size = file.size;

      if (size > 1024 * 1024 * 100) {
        this.$message.error('文件大小不能超过100M');
        return false;
      }

      var acceptFileTypes = [];

      if (this.fileType !== 'file') {
        acceptFileTypes = this.fileType.split(',');
      } else {
        acceptFileTypes = this.acceptTypes;
      }

      var fileType = (0, _utils.getSuffix)(file.name);

      if (!acceptFileTypes.includes(fileType)) {
        this.$message.error('不支持的文件类型');
        console.log('不支持的文件类型：' + fileType);
        return false;
      }
    },

    /**
     * 预览
     */
    handlePreview: function handlePreview(file) {
      var link = document.createElement('a');
      link.style.display = 'none';
      link.download = file.name;
      link.href = file.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
exports.default = _default2;