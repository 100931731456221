var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: "页面布局",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "650px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "rowForm",
          attrs: {
            model: _vm.selectedRow,
            rules: _vm.rules,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "基础卡布局", prop: "activityType" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.selectedRow.cardLayout,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "cardLayout", $$v)
                    },
                    expression: "selectedRow.cardLayout"
                  }
                },
                _vm._l(_vm.cardLayoutOptions, function(item) {
                  return _c("el-option", {
                    key: item.dictValue,
                    attrs: { label: item.dictLabel, value: item.dictValue }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.selectedRow.cardLayout === 0
            ? _c(
                "el-form-item",
                { attrs: { label: "第一排卡片个数", prop: "fristLineNum" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, label: "描述文字" },
                    model: {
                      value: _vm.selectedRow.fristLineNum,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedRow, "fristLineNum", $$v)
                      },
                      expression: "selectedRow.fristLineNum"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "进阶任务位置", prop: "taskPosition" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.selectedRow.taskPosition,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "taskPosition", $$v)
                    },
                    expression: "selectedRow.taskPosition"
                  }
                },
                _vm._l(_vm.taskPositionOptions, function(item) {
                  return _c("el-option", {
                    key: item.dictValue,
                    attrs: { label: item.dictLabel, value: item.dictValue }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnSaveLoading },
              on: { click: _vm.handleSave }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }