var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c("error-log", {
            staticClass: "errLog-container right-menu-item hover-effect"
          }),
          _c("screenfull", {
            staticClass: "right-menu-item hover-effect",
            attrs: { id: "screenfull" }
          }),
          _c("div", { staticClass: "name-wrapper" }, [
            _vm._v(_vm._s(_vm.name))
          ]),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c(
                "div",
                {
                  staticClass: "avatar-wrapper",
                  staticStyle: { width: "40px", height: "40px" }
                },
                [
                  _c("img", {
                    staticClass: "user-avatar",
                    attrs: { src: _vm.convertImageUrl(_vm.avatar), alt: "" }
                  }),
                  _c("i", { staticClass: "el-icon-caret-bottom" })
                ]
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function($event) {
                          _vm.visibleChangeAvatarDialog = true
                        }
                      }
                    },
                    [_vm._v("修改头像")]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function($event) {
                          _vm.visibleChangePasswordDialog = true
                        }
                      }
                    },
                    [_vm._v("修改密码")]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("user-change-avatar-dialog", {
        attrs: { visible: _vm.visibleChangeAvatarDialog },
        on: {
          "update:visible": function($event) {
            _vm.visibleChangeAvatarDialog = $event
          }
        }
      }),
      _c("user-change-password-dialog", {
        attrs: { visible: _vm.visibleChangePasswordDialog },
        on: {
          "update:visible": function($event) {
            _vm.visibleChangePasswordDialog = $event
          },
          ok: _vm.logout
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }