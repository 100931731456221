var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticStyle: { "min-width": "100%", "min-height": "300px" },
      attrs: { id: _vm.charts.chart.id }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }