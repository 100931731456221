"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.some.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.string.includes.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _router = require("@/router");

var _Enums = require("@/api/Enums");

/**
 * Use meta.role to determine if the current user has permission
 * @param authorityList {String[]} {AuthorityCode}
 * @param route {Route}
 */
function hasPermission(authorityList, route) {
  if (route.meta && route.meta.roles) {
    return authorityList.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes {Route[]} asyncRoutes
 * @param authorityList {String[]}
 */
// export function filterAsyncRoutes(routes) { 
//   return routes.map(route => {
//     const tmp = {...route}
//     // if (hasPermission(authorityList, tmp)) {
//     //   if (tmp.children) {
//     //     tmp.children = filterAsyncRoutes(tmp.children, authorityList)
//     //   }
//     //   return tmp
//     // }
//     if (tmp.children) {
//       tmp.children = filterAsyncRoutes(tmp.children)
//     }
//     return null
//   }).filter(Boolean)
// }


function filterAsyncRoutes(routes, authorityList) {
  return routes.map(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);

    if (hasPermission(authorityList, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, authorityList);
      }

      return tmp;
    }

    return null;
  }).filter(Boolean);
}

var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.routes = _router.constantRoutes.concat(routes);
    state.addRoutes = routes;
  }
};
var actions = {
  /**
   * 生成异步挂载路由
   * @param commit
   * @param authorityList {String[]}
   * @returns {Promise<unknown>}
   */
  // generateRoutes({commit}) {
  //   return new Promise(resolve => {
  //     let accessedRoutes
  //     // accessedRoutes = asyncRoutes || []
  //     // if (authorityList.includes(AuthorityCode.ROLE_ADMIN.key)) {
  //     //   accessedRoutes = asyncRoutes || []
  //     // } else {
  //     //   accessedRoutes = filterAsyncRoutes(asyncRoutes)
  //     // }
  //     // accessedRoutes = filterAsyncRoutes(asyncRoutes)
  //     accessedRoutes = asyncRoutes || []
  //     console.log('accessedRoutes',accessedRoutes);
  //     commit('SET_ROUTES', accessedRoutes)
  //     resolve(accessedRoutes)
  //   })
  // }
  generateRoutes: function generateRoutes(_ref, authorityList) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;

      if (authorityList.includes(_Enums.AuthorityCode.ROLE_ADMIN.key)) {
        accessedRoutes = _router.asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, authorityList);
      }

      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;