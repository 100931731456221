var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        visible: _vm.visibleDialog,
        direction: "rtl",
        "with-header": false,
        size: "600px",
        "before-close": _vm.handleCloseDetail
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "5px" }, attrs: { focus: true } },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "160px" } },
            [
              _c("el-form-item", { attrs: { label: "活动名称:" } }, [
                _vm._v(_vm._s(_vm.detailsList.activityName))
              ]),
              _c("el-form-item", { attrs: { label: "活动描述:" } }, [
                _vm._v(_vm._s(_vm.detailsList.activityDescribe))
              ]),
              _c("el-form-item", { attrs: { label: "活动类型:" } }, [
                _vm._v(
                  _vm._s(_vm.activityTypePreview(_vm.detailsList.activityType))
                )
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "活动规则:" } },
                [
                  _vm.detailsList.ruleTypeId
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(_vm.detailsList.activityRuleExplain) +
                            " "
                        )
                      ]
                    : [
                        _vm.detailsList.activityRuleExplain &&
                        _vm.detailsList.activityRuleExplain.videoUrl
                          ? _c("img", {
                              staticStyle: { width: "100%", height: "200px" },
                              attrs: {
                                src: _vm.convertImgUrlPrefix(
                                  _vm.detailsList.activityRuleExplain.videoUrl
                                    .uname
                                ),
                                alt: ""
                              }
                            })
                          : _vm._e()
                      ]
                ],
                2
              ),
              _c("el-form-item", { attrs: { label: "活动状态:" } }, [
                _vm._v(_vm._s(_vm.detailsList.isncFlg ? "上架" : "下架"))
              ]),
              _vm.detailsList.activityType === 9
                ? _c("el-form-item", { attrs: { label: "集卡方式:" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.collectCardTypePreview(
                          _vm.detailsList.collectCardType
                        )
                      )
                    )
                  ])
                : _vm._e(),
              _vm.detailsList.applyStartTime
                ? _c("el-form-item", { attrs: { label: "报名开始时间:" } }, [
                    _vm._v(_vm._s(_vm.detailsList.applyStartTime))
                  ])
                : _vm._e(),
              _vm.detailsList.applyFinishTime !== ""
                ? _c("el-form-item", { attrs: { label: "报名结束时间:" } }, [
                    _vm._v(_vm._s(_vm.detailsList.applyFinishTime))
                  ])
                : _vm._e(),
              _vm.detailsList.getStartTime
                ? _c("el-form-item", { attrs: { label: "领奖开始时间:" } }, [
                    _vm._v(_vm._s(_vm.detailsList.getStartTime))
                  ])
                : _vm._e(),
              _vm.detailsList.getFinishTime
                ? _c("el-form-item", { attrs: { label: "领奖结束时间:" } }, [
                    _vm._v(_vm._s(_vm.detailsList.getFinishTime))
                  ])
                : _vm._e(),
              _vm.detailsList.reachStartTime
                ? _c("el-form-item", { attrs: { label: "达成开始时间:" } }, [
                    _vm._v(_vm._s(_vm.detailsList.reachStartTime))
                  ])
                : _vm._e(),
              _vm.detailsList.reachFinishTime
                ? _c("el-form-item", { attrs: { label: "达成结束时间:" } }, [
                    _vm._v(_vm._s(_vm.detailsList.reachFinishTime))
                  ])
                : _vm._e(),
              _vm.detailsList.videoUploadInfoDTO &&
              _vm.detailsList.videoUploadInfoDTO.videoUrl
                ? _c(
                    "el-form-item",
                    { attrs: { label: "背景图URL:" } },
                    [
                      [
                        _c("img", {
                          staticStyle: { width: "100%", height: "200px" },
                          attrs: {
                            src: _vm.convertImgUrlPrefix(
                              _vm.detailsList.videoUploadInfoDTO.videoUrl.uname
                            ),
                            alt: ""
                          }
                        })
                      ]
                    ],
                    2
                  )
                : _vm._e(),
              _vm.detailsList.bannerPCDTOS &&
              _vm.detailsList.bannerPCDTOS.length
                ? _c(
                    "el-form-item",
                    { attrs: { label: "轮播图URL集合:" } },
                    [
                      _c(
                        "el-carousel",
                        { attrs: { height: "200px" } },
                        _vm._l(_vm.detailsList.bannerPCDTOS, function(
                          item,
                          index
                        ) {
                          return _c("el-carousel-item", { key: index }, [
                            _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.convertImgUrlPrefix(
                                  item.bannerUrl.uname
                                ),
                                alt: ""
                              }
                            })
                          ])
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.detailsList.promoteActivityName !== ""
                ? _c(
                    "el-form-item",
                    { attrs: { label: "资产提升活动名称:" } },
                    [_vm._v(_vm._s(_vm.detailsList.promoteActivityName))]
                  )
                : _vm._e(),
              _vm.detailsList.calculateTypeId !== null
                ? _c("el-form-item", { attrs: { label: "计算类型编号:" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.detailsList.calculateTypeId
                          ? "其他"
                          : "月日均金融资产"
                      )
                    )
                  ])
                : _vm._e(),
              _vm.detailsList.promoteTypeId !== null
                ? _c("el-form-item", { attrs: { label: "提升类型编号:" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.detailsList.promoteTypeId ? "其他" : "等级提升"
                      )
                    )
                  ])
                : _vm._e(),
              _vm.detailsList.promoteTaskVos &&
              _vm.detailsList.promoteTaskVos.length
                ? _vm._l(_vm.detailsList.promoteTaskVos, function(item, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: { label: "提升任务" + (index + 1) + ":" }
                      },
                      [
                        _c("el-form-item"),
                        _c(
                          "el-form",
                          { attrs: { "label-width": "80px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "任务名称:" } },
                              [_vm._v(" " + _vm._s(item.taskName) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "达成金额:" } },
                              [_vm._v(" " + _vm._s(item.finishMoney) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "初始等级:" } },
                              [_vm._v(" " + _vm._s(item.startGrade) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "目标等级:" } },
                              [_vm._v(" " + _vm._s(item.taskName) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "权益:" } },
                              [
                                _c("el-form-item"),
                                _vm._l(item.rightsDTOS, function(
                                  item2,
                                  index2
                                ) {
                                  return _c(
                                    "el-form",
                                    {
                                      key: index2,
                                      attrs: { "label-width": "80px" }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "权益标识:" } },
                                        [
                                          item2.rightsTag === 0
                                            ? [_vm._v("达标权益")]
                                            : _vm._e(),
                                          item2.rightsTag === 1
                                            ? [_vm._v("膨胀权益")]
                                            : _vm._e(),
                                          item2.rightsTag === 2
                                            ? [_vm._v("留存权益")]
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      item2.rightsTag === 1
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "膨胀方式:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.expandTypePreview(
                                                      item2.expandType
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "权益类型:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.rightsTypePreview(
                                                  item2.rightsType
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      item2.rightsTag === 1 && item2.expandType
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "助力设置:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.collaUuidPreview(
                                                      item2.collaUuid
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "领取方式:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.receiveMethodPreview(
                                                      item2.receiveMethod
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "权益次数:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.rightsNumberPreview(
                                                      item2.rightsNumber
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "开始时间:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item2.startTime) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "结束时间:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item2.finishTime) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsGiftDTOs &&
                                      item2.rightsGiftDTOs.length
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "礼品列表:" } },
                                            [
                                              _c(
                                                "el-table",
                                                {
                                                  staticStyle: { width: "90%" },
                                                  attrs: {
                                                    data: item2.rightsGiftDTOs,
                                                    border: "",
                                                    size: "mini",
                                                    "header-cell-style": {
                                                      background: "#BEBEBE",
                                                      color:
                                                        "rgba(255, 255, 255, 1);"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNm",
                                                      label: "名称"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftTypNm",
                                                      label: "类型"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftId",
                                                      label: "编号"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "ivtyQty",
                                                      label: "库存"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNumber",
                                                      label: "限量"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "probability",
                                                      label: "概率"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.detailsList.activityManageRetainedVos &&
              _vm.detailsList.activityManageRetainedVos.length
                ? _vm._l(_vm.detailsList.activityManageRetainedVos, function(
                    item,
                    index
                  ) {
                    return _c(
                      "el-form-item",
                      {
                        key: index + 100,
                        attrs: { label: "留存活动" + (index + 1) + ":" }
                      },
                      [
                        _c("el-form-item"),
                        _c(
                          "el-form",
                          { attrs: { "label-width": "80px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "活动名称:" } },
                              [_vm._v(" " + _vm._s(item.activityName) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "活动描述:" } },
                              [
                                _vm._v(
                                  " " + _vm._s(item.activityDescribe) + " "
                                )
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "开始时间:" } },
                              [_vm._v(" " + _vm._s(item.startTime) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "结束时间:" } },
                              [_vm._v(" " + _vm._s(item.finishTime) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "背景图:" } },
                              [
                                item.videoUploadInfoDTO &&
                                item.videoUploadInfoDTO.videoUrl
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "200px"
                                      },
                                      attrs: {
                                        src:
                                          item.videoUploadInfoDTO.videoUrl.url,
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.detailsList.allTaskVos && _vm.detailsList.allTaskVos.length
                ? _vm._l(_vm.detailsList.allTaskVos, function(item, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: { label: "任务" + (index + 1) + ":" }
                      },
                      [
                        _c("el-form-item"),
                        _c(
                          "el-form",
                          { attrs: { "label-width": "80px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "任务名称:" } },
                              [_vm._v(" " + _vm._s(item.taskName) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "任务类型:" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.taskBrowseTypePreview(item.taskType)
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            item.taskType === 3
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "浏览页面:" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.browsePageName) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "浏览时长:" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.browseTime + "秒") +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "达标金额:" } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.reachMoney) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "达标天/次:" } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.reachType) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "达标数量:" } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.reachAmount) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "是否连续:" } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.isContinuous) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "banner:" } },
                              [
                                item.videoUploadInfoDTO &&
                                item.videoUploadInfoDTO.videoUrl
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "200px"
                                      },
                                      attrs: {
                                        src: _vm.convertImgUrlPrefix(
                                          JSON.parse(
                                            item.videoUploadInfoDTO.videoUrl
                                          ).uname
                                        ),
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "权益:" } },
                              [
                                _c("el-form-item"),
                                _vm._l(item.rightsDTOS, function(
                                  item2,
                                  index2
                                ) {
                                  return _c(
                                    "el-form",
                                    {
                                      key: index2,
                                      attrs: { "label-width": "80px" }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "权益标识:" } },
                                        [
                                          item2.rightsTag === 0
                                            ? [_vm._v("达标权益")]
                                            : _vm._e(),
                                          item2.rightsTag === 1
                                            ? [_vm._v("膨胀权益")]
                                            : _vm._e(),
                                          item2.rightsTag === 2
                                            ? [_vm._v("留存权益")]
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      item2.rightsTag === 1
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "膨胀方式:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.expandTypePreview(
                                                      item2.expandType
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "权益类型:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.rightsTypePreview(
                                                  item2.rightsType
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      item2.rightsTag === 1 && item2.expandType
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "助力设置:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.collaUuidPreview(
                                                      item2.collaUuid
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "领取方式:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.receiveMethodPreview(
                                                      item2.receiveMethod
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "权益次数:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.rightsNumberPreview(
                                                      item2.rightsNumber
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "开始时间:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item2.startTime) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "结束时间:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item2.finishTime) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsGiftDTOs &&
                                      item2.rightsGiftDTOs.length
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "礼品列表:" } },
                                            [
                                              _c(
                                                "el-table",
                                                {
                                                  staticStyle: { width: "90%" },
                                                  attrs: {
                                                    data: item2.rightsGiftDTOs,
                                                    border: "",
                                                    size: "mini",
                                                    "header-cell-style": {
                                                      background: "#BEBEBE",
                                                      color:
                                                        "rgba(255, 255, 255, 1);"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNm",
                                                      label: "名称"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftTypNm",
                                                      label: "类型"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftId",
                                                      label: "编号"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "ivtyQty",
                                                      label: "库存"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNumber",
                                                      label: "限量"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "probability",
                                                      label: "概率"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.detailsList.basicCardVoList &&
              _vm.detailsList.basicCardVoList.length
                ? _vm._l(_vm.detailsList.basicCardVoList, function(
                    cardItem,
                    index
                  ) {
                    return _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: { label: "基础卡片" + (index + 1) + ":" }
                      },
                      [
                        _c(
                          "el-form",
                          { attrs: { "label-width": "120px" } },
                          [
                            _c("el-form-item"),
                            _c(
                              "el-form-item",
                              { attrs: { label: "卡片名称:" } },
                              [_vm._v(" " + _vm._s(cardItem.cardName) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "集卡要求:" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.cardRequirementsPreview(
                                        cardItem.cardRequirements
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "集卡素材:" } },
                              [
                                _c("el-form-item"),
                                cardItem.unreachCardDTO.videoUrl
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "未达标卡片:" } },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px"
                                          },
                                          attrs: {
                                            src: _vm.convertImgUrlPrefix(
                                              JSON.parse(
                                                cardItem.unreachCardDTO.videoUrl
                                              ).uname
                                            ),
                                            alt: ""
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                cardItem.reachCardDTO.videoUrl
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "已达标卡片:" } },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px"
                                          },
                                          attrs: {
                                            src: _vm.convertImgUrlPrefix(
                                              JSON.parse(
                                                cardItem.reachCardDTO.videoUrl
                                              ).uname
                                            ),
                                            alt: ""
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                cardItem.cardDetailPageDTO.videoUrl
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "集卡详情页:" } },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px"
                                          },
                                          attrs: {
                                            src: _vm.convertImgUrlPrefix(
                                              JSON.parse(
                                                cardItem.cardDetailPageDTO
                                                  .videoUrl
                                              ).uname
                                            ),
                                            alt: ""
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                cardItem.cardSuccessPosterDTOList &&
                                cardItem.cardSuccessPosterDTOList.length
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "集卡成功海报:" } },
                                      _vm._l(
                                        cardItem.cardSuccessPosterDTOList,
                                        function(posterItem, posterindex) {
                                          return _c(
                                            "div",
                                            { key: posterindex },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px"
                                                },
                                                attrs: {
                                                  src: _vm.convertImgUrlPrefix(
                                                    JSON.parse(
                                                      posterItem.videoUrl
                                                    ).uname
                                                  ),
                                                  alt: ""
                                                }
                                              })
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "权益:" } },
                              [
                                _c("el-form-item"),
                                _vm._l(cardItem.rightsDTOS, function(
                                  item2,
                                  index2
                                ) {
                                  return _c(
                                    "el-form",
                                    {
                                      key: index2,
                                      attrs: { "label-width": "80px" }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "权益标识:" } },
                                        [
                                          item2.rightsTag === 0
                                            ? [_vm._v("达标权益")]
                                            : _vm._e(),
                                          item2.rightsTag === 1
                                            ? [_vm._v("膨胀权益")]
                                            : _vm._e(),
                                          item2.rightsTag === 2
                                            ? [_vm._v("留存权益")]
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      item2.rightsTag === 1
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "膨胀方式:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.expandTypePreview(
                                                      item2.expandType
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "权益类型:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.rightsTypePreview(
                                                  item2.rightsType
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      item2.rightsTag === 1 && item2.expandType
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "助力设置:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.collaUuidPreview(
                                                      item2.collaUuid
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "领取方式:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.receiveMethodPreview(
                                                      item2.receiveMethod
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "权益次数:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.rightsNumberPreview(
                                                      item2.rightsNumber
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "开始时间:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item2.startTime) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "结束时间:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item2.finishTime) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsGiftDTOs &&
                                      item2.rightsGiftDTOs.length &&
                                      item2.rightsType === 2
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "礼品列表:" } },
                                            [
                                              _c(
                                                "el-table",
                                                {
                                                  staticStyle: { width: "90%" },
                                                  attrs: {
                                                    data: item2.rightsGiftDTOs,
                                                    border: "",
                                                    size: "mini",
                                                    "header-cell-style": {
                                                      background: "#BEBEBE",
                                                      color:
                                                        "rgba(255, 255, 255, 1);"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftType",
                                                      label: "类型"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftPoints",
                                                      label: "赠送积分数"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "probability",
                                                      label: "概率"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftMonthNumber",
                                                      label: "每月限量"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNumber",
                                                      label: "总限量"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item2.rightsGiftDTOs &&
                                      item2.rightsGiftDTOs.length &&
                                      item2.rightsType !== 2
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "礼品列表:" } },
                                            [
                                              _c(
                                                "el-table",
                                                {
                                                  staticStyle: { width: "90%" },
                                                  attrs: {
                                                    data: item2.rightsGiftDTOs,
                                                    border: "",
                                                    size: "mini",
                                                    "header-cell-style": {
                                                      background: "#BEBEBE",
                                                      color:
                                                        "rgba(255, 255, 255, 1);"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNm",
                                                      label: "名称"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftTypNm",
                                                      label: "类型"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftId",
                                                      label: "编号"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "ivtyQty",
                                                      label: "库存"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNumber",
                                                      label: "限量"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "probability",
                                                      label: "概率"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.detailsList.advancedTaskVoList &&
              _vm.detailsList.advancedTaskVoList.length
                ? _vm._l(_vm.detailsList.advancedTaskVoList, function(
                    highTaskItem,
                    highTaskIndex
                  ) {
                    return _c(
                      "el-form-item",
                      {
                        key: highTaskIndex,
                        attrs: { label: "进阶任务" + (highTaskIndex + 1) + ":" }
                      },
                      [
                        _c(
                          "el-form",
                          { attrs: { "label-width": "80px" } },
                          [
                            _c("el-form-item"),
                            _c(
                              "el-form-item",
                              { attrs: { label: "任务名称:" } },
                              [
                                _vm._v(
                                  " " + _vm._s(highTaskItem.taskName) + " "
                                )
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "任务类型:" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.taskTypePreview(highTaskItem.taskType)
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "任务图片:" } },
                              [
                                highTaskItem.taskPicDTO.videoUrl
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "图片:" } },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px"
                                          },
                                          attrs: {
                                            src: _vm.convertImgUrlPrefix(
                                              JSON.parse(
                                                highTaskItem.taskPicDTO.videoUrl
                                              ).uname
                                            ),
                                            alt: ""
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "初始状态:" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.startStatePreview(
                                        highTaskItem.startState
                                      )
                                    )
                                  )
                                ]),
                                _c("span", [
                                  _vm._v(_vm._s(highTaskItem.startNumber))
                                ])
                              ]
                            ),
                            highTaskItem.taskType === 0
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "达标数量:" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.startStatePreview(
                                            highTaskItem.reachState
                                          )
                                        )
                                      )
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(highTaskItem.reachNumber))
                                    ]),
                                    _vm._v("个 ")
                                  ]
                                )
                              : _vm._e(),
                            highTaskItem.taskType === 1
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "提升数量:" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(highTaskItem.promoteNumber) +
                                        "个 "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { label: "参与次数:" } },
                              [
                                highTaskItem.isLimit === 0
                                  ? _c("span", [_vm._v("不限制")])
                                  : _vm._e(),
                                highTaskItem.isLimit === 1
                                  ? _c("span", [
                                      _vm._v(
                                        "限制" +
                                          _vm._s(highTaskItem.limitNumber) +
                                          "个"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "权益:" } },
                              [
                                _c("el-form-item"),
                                _vm._l(highTaskItem.rightsDTOS, function(
                                  item2,
                                  index2
                                ) {
                                  return _c(
                                    "el-form",
                                    {
                                      key: index2,
                                      attrs: { "label-width": "80px" }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "权益标识:" } },
                                        [
                                          item2.rightsTag === 0
                                            ? [_vm._v("达标权益")]
                                            : _vm._e(),
                                          item2.rightsTag === 1
                                            ? [_vm._v("膨胀权益")]
                                            : _vm._e(),
                                          item2.rightsTag === 2
                                            ? [_vm._v("留存权益")]
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      item2.rightsTag === 1
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "膨胀方式:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.expandTypePreview(
                                                      item2.expandType
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "权益类型:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.rightsTypePreview(
                                                  item2.rightsType
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      item2.rightsTag === 1 && item2.expandType
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "助力设置:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.collaUuidPreview(
                                                      item2.collaUuid
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "领取方式:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.receiveMethodPreview(
                                                      item2.receiveMethod
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "权益次数:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.rightsNumberPreview(
                                                      item2.rightsNumber
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "开始时间:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item2.startTime) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsTag === 0
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "结束时间:" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item2.finishTime) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item2.rightsGiftDTOs &&
                                      item2.rightsGiftDTOs.length &&
                                      item2.rightsType === 2
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "礼品列表:" } },
                                            [
                                              _c(
                                                "el-table",
                                                {
                                                  staticStyle: { width: "90%" },
                                                  attrs: {
                                                    data: item2.rightsGiftDTOs,
                                                    border: "",
                                                    size: "mini",
                                                    "header-cell-style": {
                                                      background: "#BEBEBE",
                                                      color:
                                                        "rgba(255, 255, 255, 1);"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftType",
                                                      label: "类型"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftPoints",
                                                      label: "赠送积分数"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "probability",
                                                      label: "概率"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftMonthNumber",
                                                      label: "每月限量"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNumber",
                                                      label: "总限量"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item2.rightsGiftDTOs &&
                                      item2.rightsGiftDTOs.length &&
                                      item2.rightsType !== 2
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { label: "礼品列表:" } },
                                            [
                                              _c(
                                                "el-table",
                                                {
                                                  staticStyle: { width: "90%" },
                                                  attrs: {
                                                    data: item2.rightsGiftDTOs,
                                                    border: "",
                                                    size: "mini",
                                                    "header-cell-style": {
                                                      background: "#BEBEBE",
                                                      color:
                                                        "rgba(255, 255, 255, 1);"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNm",
                                                      label: "名称"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftTypNm",
                                                      label: "类型"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftId",
                                                      label: "编号"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "ivtyQty",
                                                      label: "库存"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "giftNumber",
                                                      label: "限量"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      prop: "probability",
                                                      label: "概率"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.detailsList.aggregateActivityDetailVos &&
              _vm.detailsList.aggregateActivityDetailVos.length
                ? _vm._l(_vm.detailsList.aggregateActivityDetailVos, function(
                    item,
                    index
                  ) {
                    return _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: { label: "链接" + (index + 1) + ":" }
                      },
                      [
                        _c("el-form-item"),
                        _c(
                          "el-form",
                          { attrs: { "label-width": "80px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "链接名称:" } },
                              [_vm._v(" " + _vm._s(item.linkName) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "链接类型:" } },
                              [_vm._v(" " + _vm._s(item.linkTypeName) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "链接地址:" } },
                              [_vm._v(" " + _vm._s(item.linkUrl) + " ")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "链接图:" } },
                              [
                                item.videoUploadInfoDTO &&
                                item.videoUploadInfoDTO.videoUrl
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "200px"
                                      },
                                      attrs: {
                                        src:
                                          item.videoUploadInfoDTO.videoUrl.url,
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }