require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

/**
 * 字符串原型扩展
 * @author 谢长春 2019-7-28
 */
(function (_) {
  /**
   * 字符串格式化,使用{}占位，{}内可以为空，替换顺序为参数顺序
   * console.log('test/{d}/{ab}/{}/{123}'.format(1, 2, 3, 0));
   * @param args {Array|*}
   * @return {string}
   */
  String.prototype.format = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return this.replace(/({(\w+)?})/gi, function (match, $1) {
      return args.length > 0 ? args.shift() : $1;
    });
  };
  /**
   * 字符串格式化,使用{}占位，{}内不能为空，在字符串中检索{}内的属性，从object中获取对应的值
   * console.log('test/{d}/{ab}/{c}/{123}'.formatObject({d: 1, ab: 2, c: 3, 123: 0}));
   * @param object {Object}
   * @return {string}
   */


  String.prototype.formatObject = function () {
    var object = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return this.replace(/({(\w+)?})/gi, function (match, $1, $2) {
      return object[$2] === undefined || object[$2] === null ? $1 : object[$2];
    });
  };
})();