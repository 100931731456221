var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        visible: _vm.visible,
        direction: "rtl",
        "with-header": false,
        size: "600px",
        "before-close": _vm.handleCloseDetail
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "5px" }, attrs: { focus: true } },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "160px" } },
            [
              _c("el-form-item", { attrs: { label: "详情页名称\t:" } }, [
                _vm._v(_vm._s(_vm.detailsList.pageName))
              ]),
              _c("el-form-item", { attrs: { label: "描述:" } }, [
                _vm._v(_vm._s(_vm.detailsList.describe))
              ]),
              _c("el-form-item", { attrs: { label: "详情页类型:" } }, [
                _vm._v(
                  _vm._s(
                    _vm.matchingType(
                      _vm.detailsList.pageType,
                      _vm.pageTypeOptions
                    )
                  )
                )
              ]),
              _vm.detailsList.backImageDto &&
              _vm.detailsList.backImageDto.videoUrl
                ? _c(
                    "el-form-item",
                    { attrs: { label: "背景图:" } },
                    [
                      [
                        _c("img", {
                          staticStyle: { width: "100%", height: "200px" },
                          attrs: {
                            src: _vm.convertImgUrlPrefix(
                              _vm.detailsList.backImageDto.videoUrl.uname
                            ),
                            alt: ""
                          }
                        })
                      ]
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "咨询跳转功能:" } },
                [
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.detailsList.isConsultationJump
                            ? "已启用"
                            : "未启用"
                        ) +
                        " "
                    ),
                    _vm.detailsList.isConsultationJump
                      ? _c(
                          "div",
                          [
                            _vm._v(
                              " 跳转类型：" +
                                _vm._s(
                                  _vm.matchingType(
                                    _vm.detailsList.jumpPage,
                                    _vm.jumpPageOptions
                                  )
                                ) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(" 按钮显示名称： "),
                            _vm.detailsList.consultButtonNameDto &&
                            _vm.detailsList.consultButtonNameDto.videoUrl
                              ? [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "200px"
                                    },
                                    attrs: {
                                      src: _vm.convertImgUrlPrefix(
                                        _vm.detailsList.consultButtonNameDto
                                          .videoUrl.uname
                                      ),
                                      alt: ""
                                    }
                                  })
                                ]
                              : _vm._e(),
                            _c("br"),
                            _vm._v(
                              " 按钮起始坐标：" +
                                _vm._s(
                                  _vm.detailsList.consultButtonStartCoordinates
                                ) +
                                " "
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.detailsList.pageType
                      ? _c("div", [
                          _vm._v(
                            " 跳转页面类型：" +
                              _vm._s(
                                _vm.matchingType(
                                  _vm.detailsList.jumpPageLinkDTO.jumpType,
                                  _vm.jumpTypeOptions
                                )
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " 跳转地址：" +
                              _vm._s(_vm.detailsList.jumpPageLinkDTO.linkUrl) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]
                ],
                2
              ),
              _vm.detailsList.pageType
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "页面保存功能:" } },
                      [
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.detailsList.isPageSave ? "已启用" : "未启用"
                              ) +
                              " "
                          ),
                          _vm.detailsList.isPageSave
                            ? _c(
                                "div",
                                [
                                  _vm._v(" 海报： "),
                                  _vm.detailsList.pageSavePicDto &&
                                  _vm.detailsList.pageSavePicDto.videoUrl
                                    ? [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100%",
                                            height: "200px"
                                          },
                                          attrs: {
                                            src: _vm.convertImgUrlPrefix(
                                              _vm.detailsList.pageSavePicDto
                                                .videoUrl.uname
                                            ),
                                            alt: ""
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _c("br"),
                                  _vm._v(
                                    " 按钮显示名称：" +
                                      _vm._s(_vm.detailsList.pageButtonName) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 按钮起始坐标：" +
                                      _vm._s(
                                        _vm.detailsList
                                          .pageButtonStartCoordinates
                                      ) +
                                      " "
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      ],
                      2
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "收集意向客户功能:" } },
                      [
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.detailsList.isCollectPotentialCustomers
                                  ? "已启用"
                                  : "未启用"
                              ) +
                              " "
                          ),
                          _vm.detailsList.isCollectPotentialCustomers
                            ? _c("div", [
                                _vm._v(
                                  " 按钮起始坐标：" +
                                    _vm._s(
                                      _vm.detailsList
                                        .collectButtonStartCoordinates
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      ],
                      2
                    )
                  ]
                : _vm._l(_vm.detailsList.productDisplayDTOList, function(
                    item,
                    index
                  ) {
                    return _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: { label: "产品展示板块" + (index + 1) + ":" }
                      },
                      [
                        _vm._v(
                          " 展示板块名称:" + _vm._s(item.displayName) + " "
                        ),
                        _c("br"),
                        _vm._v(
                          " 名称展示:" +
                            _vm._s(item.isNameDisplay ? "是" : "否") +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " 板块页面布局:" +
                            _vm._s(
                              _vm.matchingType(
                                item.pageLayout,
                                _vm.pageLayoutOptions
                              )
                            ) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(" 业务办理流程图: "),
                        item.processFlowchartAddDto &&
                        item.processFlowchartAddDto.videoUrl
                          ? [
                              _c("img", {
                                staticStyle: { width: "100%", height: "200px" },
                                attrs: {
                                  src: _vm.convertImgUrlPrefix(
                                    item.processFlowchartAddDto.videoUrl.uname
                                  ),
                                  alt: ""
                                }
                              })
                            ]
                          : _vm._e(),
                        _c("br"),
                        _vm._v(
                          " 收集意向客户功能:" +
                            _vm._s(
                              item.isCollectPotentialCustomers
                                ? "已启用"
                                : "未启用"
                            ) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " 按钮起始坐标:" +
                            _vm._s(item.collectButtonStartCoordinates) +
                            " "
                        ),
                        _c("br"),
                        _vm._l(item.productDetailDTOList, function(
                          item2,
                          index2
                        ) {
                          return _c(
                            "div",
                            { key: index2 },
                            [
                              _vm._v(" 产品" + _vm._s(index2 + 1) + ": "),
                              _c("br"),
                              _vm._v(
                                " 产品名称：" + _vm._s(item2.productName) + " "
                              ),
                              _c("br"),
                              _vm._v(
                                " 产品类型：" +
                                  _vm._s(
                                    _vm.matchingType(
                                      item2.productType,
                                      _vm.productTypeOptions
                                    )
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(" 产品banner： "),
                              _c("img", {
                                staticStyle: { width: "100%", height: "200px" },
                                attrs: {
                                  src: _vm.convertImgUrlPrefix(
                                    item2.productBannerAddDto.videoUrl.uname
                                  ),
                                  alt: ""
                                }
                              }),
                              _c("br"),
                              _vm._l(item2.pictureLinkDTOList, function(
                                item3,
                                index3
                              ) {
                                return _c("div", { key: index3 }, [
                                  _vm._v(
                                    " 图片链接" + _vm._s(index3 + 1) + ": "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 链接名称：" + _vm._s(item3.linkName) + " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 跳链区域：" +
                                      _vm._s(
                                        _vm.matchingType(
                                          item3.jumpArea,
                                          _vm.jumpAreaOptions
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 区域坐标：" +
                                      _vm._s(item3.areaCoordinates) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 跳转类型：" +
                                      _vm._s(
                                        _vm.matchingType(
                                          item3.jumpType,
                                          _vm.jumpTypeOptions
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 链接地址：" + _vm._s(item3.linkUrl) + " "
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        })
                      ],
                      2
                    )
                  })
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }