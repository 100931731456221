"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.set.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Role = _interopRequireDefault(require("@/api/entity/Role"));

var _AuthorityService = _interopRequireDefault(require("@/api/AuthorityService"));

var _RoleService = _interopRequireDefault(require("@/api/RoleService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: _Role.default,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      tree: null,
      props: {
        label: 'name',
        children: 'nodes'
      },
      selectedCodes: new Set(),
      rules: {
        name: [{
          required: true,
          message: '请输入角色名',
          trigger: 'blur'
        }, {
          min: 1,
          max: 100,
          message: '长度必须在 1-100 个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    visibleDrawer: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  created: function created() {
    var _this = this;

    _AuthorityService.default.getTree().then(function (result) {
      result.hasFailure(function () {
        return _this.$message.warning(result.getErrorMessage('权限树加载失败'));
      }).hasData(function (data) {
        _this.tree = data;
      });
    });
  },
  methods: {
    handleOpen: function handleOpen() {
      if (this.row.authorities) {
        // 编辑时填充权限树
        var refTree = this.$refs.tree; // 过滤:只选择叶子节点, 父节点会自动选中

        var keys = this.row.authorities.filter(function (code) {
          var _refTree$getNode;

          return (_refTree$getNode = refTree.getNode(code)) === null || _refTree$getNode === void 0 ? void 0 : _refTree$getNode.isLeaf;
        });
        refTree.setCheckedKeys(keys);
      }
    },
    handleChange: function handleChange(row, currentChecked, childChecked) {
      row.checked = currentChecked || childChecked;

      if (!row.nodes) {
        if (currentChecked) {
          this.selectedCodes.add(row.code);
        } else {
          this.selectedCodes.delete(row.code);
        }
      }
    },
    handleClose: function handleClose() {
      this.$refs.rowForm.resetFields();
      this.$refs.tree.setCheckedKeys([]);
      this.visibleDrawer = false;
    },
    handleSave: function handleSave() {
      var _this2 = this;

      if (this.selectedCodes.size === 0) {
        this.$message.warning('请选择权限');
        return;
      }

      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          _this2.btnSaveLoading = true;
          var role = new _Role.default(_this2.row);
          role.authorityTree = _this2.tree;
          (role.id ? _RoleService.default.update(role) : _RoleService.default.save(role)).then(function (result) {
            _this2.btnSaveLoading = false;
            result.hasFailure(function () {
              return _this2.$message.warning(result.getErrorMessage('保存失败'));
            }).hasSuccess(function () {
              _this2.$message.success('保存成功');

              _this2.handleClose();

              _this2.$emit('ok');
            });
          });
        }
      });
    }
  }
};
exports.default = _default;