var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: "活动时间",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "500px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("span", { staticClass: "leftLabel" }, [_vm._v("报名时间")]),
          _c("el-date-picker", {
            staticStyle: { width: "150px" },
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "date",
              placeholder: "报名开始日期"
            },
            model: {
              value: _vm.selectedRow.applyStartTime,
              callback: function($$v) {
                _vm.$set(_vm.selectedRow, "applyStartTime", $$v)
              },
              expression: "selectedRow.applyStartTime"
            }
          }),
          _vm._v(" -- "),
          _c("el-date-picker", {
            staticStyle: { width: "150px" },
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "date",
              placeholder: "报名结束日期"
            },
            model: {
              value: _vm.selectedRow.applyFinishTime,
              callback: function($$v) {
                _vm.$set(_vm.selectedRow, "applyFinishTime", $$v)
              },
              expression: "selectedRow.applyFinishTime"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "timeLine" },
        [
          _c("span", { staticClass: "leftLabel" }, [_vm._v("任务时间")]),
          _c("el-date-picker", {
            staticStyle: { width: "150px" },
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "date",
              placeholder: "达成开始日期"
            },
            model: {
              value: _vm.selectedRow.reachStartTime,
              callback: function($$v) {
                _vm.$set(_vm.selectedRow, "reachStartTime", $$v)
              },
              expression: "selectedRow.reachStartTime"
            }
          }),
          _vm._v(" -- "),
          _c("el-date-picker", {
            staticStyle: { width: "150px" },
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "date",
              placeholder: "达成结束日期"
            },
            model: {
              value: _vm.selectedRow.reachFinishTime,
              callback: function($$v) {
                _vm.$set(_vm.selectedRow, "reachFinishTime", $$v)
              },
              expression: "selectedRow.reachFinishTime"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "timeLine" },
        [
          _c("span", { staticClass: "leftLabel" }, [_vm._v("领奖时间")]),
          _c("el-date-picker", {
            staticStyle: { width: "150px" },
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "date",
              placeholder: "领奖开始日期"
            },
            model: {
              value: _vm.selectedRow.getStartTime,
              callback: function($$v) {
                _vm.$set(_vm.selectedRow, "getStartTime", $$v)
              },
              expression: "selectedRow.getStartTime"
            }
          }),
          _vm._v(" -- "),
          _c("el-date-picker", {
            staticStyle: { width: "150px" },
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "date",
              placeholder: "领奖结束日期"
            },
            model: {
              value: _vm.selectedRow.getFinishTime,
              callback: function($$v) {
                _vm.$set(_vm.selectedRow, "getFinishTime", $$v)
              },
              expression: "selectedRow.getFinishTime"
            }
          })
        ],
        1
      ),
      _vm.type == "1"
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        : _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("上 架")]
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }