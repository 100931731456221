var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加产品",
        visible: _vm.visible,
        width: "650px",
        "before-close": _vm.closeProductDialog,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "Form",
          attrs: { model: _vm.row, "label-width": "140px", rules: _vm.Rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品名称", prop: "productName" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", placeholder: "请输入产品名称" },
                model: {
                  value: _vm.row.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "productName", $$v)
                  },
                  expression: "row.productName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "产品类型", prop: "productType" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.row.productType,
                    callback: function($$v) {
                      _vm.$set(_vm.row, "productType", $$v)
                    },
                    expression: "row.productType"
                  }
                },
                _vm._l(_vm.productTypeOptions, function(item) {
                  return _c("el-option", {
                    key: item.dictValue,
                    attrs: { label: item.dictLabel, value: item.dictValue }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "产品banner" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action: "''",
                    multiple: "",
                    limit: 1,
                    "file-list": _vm.files,
                    "http-request": _vm.handleCarouselSuccess,
                    "on-exceed": _vm.handleCarouselExceed,
                    "on-remove": _vm.handleCarouselRemove
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "file",
                      fn: function(ref) {
                        var file = ref.file
                        return [
                          _c("div", { staticClass: "file-item" }, [
                            _c("i", { staticClass: "el-icon-document" }),
                            _c("span", { staticClass: "itemText" }, [
                              _vm._v(_vm._s(file.name))
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "el-upload-list__item-status-label"
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-upload-success el-icon-circle-check"
                                })
                              ]
                            ),
                            _c("i", {
                              staticClass: "el-icon-close",
                              on: {
                                click: function($event) {
                                  return _vm.handleCarouselRemove(file)
                                }
                              }
                            }),
                            _c("i", {
                              staticClass: "el-icon-s-tools",
                              on: {
                                click: function($event) {
                                  return _vm.openLinkPop(file)
                                }
                              }
                            })
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.closeProductDialog } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.Loading },
              on: { click: _vm.addProductDetailDTOList }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "700px",
            title: "图片设置",
            visible: _vm.pictureLinkVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.pictureLinkVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addTab(_vm.editableTabsValue)
                    }
                  }
                },
                [_vm._v(" 添加链接 ")]
              )
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              attrs: { type: "card", closable: "", "tab-position": "left" },
              on: { "tab-remove": _vm.removeTab },
              model: {
                value: _vm.editableTabsValue,
                callback: function($$v) {
                  _vm.editableTabsValue = $$v
                },
                expression: "editableTabsValue"
              }
            },
            _vm._l(_vm.editableTabs, function(item, index) {
              return _c(
                "el-tab-pane",
                {
                  key: item.name,
                  attrs: { label: item.title + (index + 1), name: item.name }
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: item,
                        "label-width": "140px",
                        rules: _vm.linkRules
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "链接名称", prop: "linkName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              autocomplete: "off",
                              placeholder: "请输入产品名称"
                            },
                            model: {
                              value: item.linkName,
                              callback: function($$v) {
                                _vm.$set(item, "linkName", $$v)
                              },
                              expression: "item.linkName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳链区域", prop: "jumpArea" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: item.jumpArea,
                                callback: function($$v) {
                                  _vm.$set(item, "jumpArea", $$v)
                                },
                                expression: "item.jumpArea"
                              }
                            },
                            _vm._l(_vm.jumpAreaOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      item.jumpArea !== 0 && item.jumpArea
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "区域坐标",
                                prop: "areaCoordinates"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  autocomplete: "off",
                                  placeholder: "请输入坐标"
                                },
                                model: {
                                  value: item.areaCoordinates,
                                  callback: function($$v) {
                                    _vm.$set(item, "areaCoordinates", $$v)
                                  },
                                  expression: "item.areaCoordinates"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳转类型", prop: "jumpType" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: item.jumpType,
                                callback: function($$v) {
                                  _vm.$set(item, "jumpType", $$v)
                                },
                                expression: "item.jumpType"
                              }
                            },
                            _vm._l(_vm.jumpTypeOptions, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "链接地址", prop: "linkUrl" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入跳转链接地址"
                            },
                            model: {
                              value: item.linkUrl,
                              callback: function($$v) {
                                _vm.$set(item, "linkUrl", $$v)
                              },
                              expression: "item.linkUrl"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeLinkDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.Loading },
                  on: { click: _vm.addLink }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }