var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改头像",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        width: "30%"
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                name: "file",
                action: "''",
                "show-file-list": false,
                "on-success": _vm.handleAvatarSuccess,
                "before-upload": _vm.beforeAvatarUpload,
                "http-request": _vm.handleUpload
              }
            },
            [
              (_vm.avatar || {}).url
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.avatar.url }
                  })
                : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnSaveLoading },
              on: { click: _vm.handleSave }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }