import { render, staticRenderFns } from "./bulletinBoard.vue?vue&type=template&id=2fefa296&scoped=true&"
import script from "./bulletinBoard.vue?vue&type=script&lang=js&"
export * from "./bulletinBoard.vue?vue&type=script&lang=js&"
import style0 from "./bulletinBoard.vue?vue&type=style&index=0&id=2fefa296&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fefa296",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\桌面\\company\\fdm-product-manager-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2fefa296')) {
      api.createRecord('2fefa296', component.options)
    } else {
      api.reload('2fefa296', component.options)
    }
    module.hot.accept("./bulletinBoard.vue?vue&type=template&id=2fefa296&scoped=true&", function () {
      api.rerender('2fefa296', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/businessOperations/bulletinBoard.vue"
export default component.exports