var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: _vm.row.id ? "编辑" : "新增",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "800px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "rowForm",
          attrs: { model: _vm.row, rules: _vm.rules, "label-width": "140px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "等级名称", prop: "gradeName" } },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  maxlength: "200",
                  disabled: _vm.isDetail
                },
                model: {
                  value: _vm.row.gradeName,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "gradeName", $$v)
                  },
                  expression: "row.gradeName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "描述", prop: "comment" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入描述",
                  disabled: _vm.isDetail
                },
                model: {
                  value: _vm.row.comment,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "comment", $$v)
                  },
                  expression: "row.comment"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "排序", prop: "sortNum", disabled: _vm.isDetail }
            },
            [
              _c("el-input-number", {
                attrs: { min: 0, label: "描述文字" },
                model: {
                  value: _vm.row.sortNum,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "sortNum", $$v)
                  },
                  expression: "row.sortNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "等级类型", prop: "gradeType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { autocomplete: "off", disabled: _vm.isDetail },
                  model: {
                    value: _vm.row.gradeType,
                    callback: function($$v) {
                      _vm.$set(_vm.row, "gradeType", $$v)
                    },
                    expression: "row.gradeType"
                  }
                },
                _vm._l(_vm.gradeTypeOptions, function(item) {
                  return _c("el-option", {
                    key: item.dictValue,
                    attrs: { label: item.dictLabel, value: item.dictValue }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "等级编码", prop: "gradeCode" } },
            [
              _c("el-input", {
                staticStyle: { width: "217px" },
                attrs: {
                  autocomplete: "off",
                  maxlength: "200",
                  disabled: _vm.isEdit || _vm.isDetail
                },
                model: {
                  value: _vm.row.gradeCode,
                  callback: function($$v) {
                    _vm.$set(_vm.row, "gradeCode", $$v)
                  },
                  expression: "row.gradeCode"
                }
              }),
              _c("div", { staticClass: "inputTip" }, [
                _c("span", { staticClass: "tipLeft" }, [
                  _c("i", { staticClass: "el-icon-warning tipIcon" }),
                  _vm._v("等级编码添加后不可修改！")
                ]),
                _c("span", { staticClass: "tipRight" }, [
                  _vm._v("活动客户等级编码以ACT_为前缀")
                ])
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "判定方式", prop: "judgeType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { autocomplete: "off", disabled: _vm.isDetail },
                  model: {
                    value: _vm.row.judgeType,
                    callback: function($$v) {
                      _vm.$set(_vm.row, "judgeType", $$v)
                    },
                    expression: "row.judgeType"
                  }
                },
                _vm._l(_vm.judeTypeOptions, function(item) {
                  return _c("el-option", {
                    key: item.dictValue,
                    attrs: { label: item.dictLabel, value: item.dictValue }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.row.judgeType == 0
            ? _c(
                "el-form-item",
                { attrs: { label: "计算方式", prop: "calculateType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { autocomplete: "off", disabled: _vm.isDetail },
                      model: {
                        value: _vm.row.calculateType,
                        callback: function($$v) {
                          _vm.$set(_vm.row, "calculateType", $$v)
                        },
                        expression: "row.calculateType"
                      }
                    },
                    _vm._l(_vm.computeTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.row.judgeType == 0
            ? _c(
                "el-form-item",
                { attrs: { label: "达标金额", prop: "qualifiedAmount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "217px" },
                    attrs: {
                      autocomplete: "off",
                      maxlength: "200",
                      disabled: _vm.isDetail
                    },
                    model: {
                      value: _vm.row.qualifiedAmount,
                      callback: function($$v) {
                        _vm.$set(_vm.row, "qualifiedAmount", _vm._n($$v))
                      },
                      expression: "row.qualifiedAmount"
                    }
                  }),
                  _vm._v(" 元 ")
                ],
                1
              )
            : _vm._e(),
          _vm.row.judgeType == 0
            ? _c(
                "el-form-item",
                { attrs: { label: "结算时间", prop: "settlementType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { autocomplete: "off", disabled: _vm.isDetail },
                      model: {
                        value: _vm.row.settlementType,
                        callback: function($$v) {
                          _vm.$set(_vm.row, "settlementType", $$v)
                        },
                        expression: "row.settlementType"
                      }
                    },
                    _vm._l(_vm.settleTimeTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isDetail
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("关闭")
              ])
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }