"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ApplicationManagentService = _interopRequireDefault(require("@/api/ApplicationManagentService"));

var _ActivityManager = _interopRequireDefault(require("@/api/ActivityManager"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      rules: {},
      selectedRow: {
        cardLayout: undefined,
        fristLineNum: undefined,
        taskPosition: undefined
      },
      cardLayoutOptions: [],
      taskPositionOptions: []
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      // this.$refs.rowForm.validate((valid) => {
      //   if (valid) {
      //     (
      //       this.row.id
      //         ? applicationManagentService.update(this.row)
      //         : applicationManagentService.save(this.row)
      //     ).then(result => {
      //       result
      //         .hasFailure(() => {
      //           this.btnSaveLoading = false
      //           this.$message.warning(result.getErrorMessage('保存失败'))
      //         })
      //         .hasSuccess(() => {
      //           this.btnSaveLoading = false
      //           this.$message.success('保存成功')
      //           this.handleCancel()
      //           this.$emit('ok')
      //         })
      //     })
      //   } else {
      //     return false
      //   }
      // })
      this.btnSaveLoading = false; // this.$message.success('保存成功')

      this.$emit('ok', this.selectedRow);
      this.handleCancel();
    },
    // 时间格式化
    formatTime: function formatTime(t) {
      var data = t;
      var dtime = new Date(data);
      var year = dtime.getFullYear();
      var month = dtime.getMonth() + 1;

      if (month < 10) {
        month = '0' + month;
      }

      var day = dtime.getDate();

      if (day < 10) {
        day = '0' + day;
      }

      return year + '-' + month + '-' + day;
    },
    reviewFileChange: function reviewFileChange(item) {
      if (item instanceof Array && item.length > 0) {
        this.selectedRow.img1 = {
          name: item[0].name,
          uname: item[0].uname,
          url: item[0].url
        };
      } else {
        this.selectedRow.img1 = undefined;
      }

      console.log(this.selectedRow.img1);
    }
  },
  created: function created() {
    var _this = this;

    // 基础卡片布局数据字典
    _ActivityManager.default.dictListGet("COLLECT_CARD_LAYOUT").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this.cardLayoutOptions = result.data[0];
    }); // 进阶任务位置数据字典


    _ActivityManager.default.dictListGet("COLLECT_TASK_POSITION").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this.taskPositionOptions = result.data[0];
    }); // 编辑反显


    if (this.row) {
      this.selectedRow = this.row;
    }
  }
};
exports.default = _default;