"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.some.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.string.trim.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _MaterialConfigService = _interopRequireDefault(require("@/api/MaterialConfigService"));

var _ActivityManager = _interopRequireDefault(require("@/api/ActivityManager"));

var _convertImgUrlPrefix2 = _interopRequireDefault(require("@/utils/convertImgUrlPrefix"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    // list: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    // },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      searchName: "",
      selectOptions: [],
      initSelectOptions: [],
      // selectTitle: null,
      selectValue: "",
      drownShow: false,
      titleList: [// {value: '0',name: '平台商品',selected: true},
        // {value: '1',name: '卡券',selected: false},
        // {value: '2',name: '积分',selected: false},
      ],
      selectTitle: {}
    };
  },
  computed: {
    convertImgUrlPrefix: function convertImgUrlPrefix() {
      return function (url) {
        return (0, _convertImgUrlPrefix2.default)(url);
      };
    },
    selectPreview: function selectPreview() {
      return function (val) {
        var result = "";
        this.selectOptions.map(function (item) {
          if (item.value == val) {
            result = item.name;
          }
        });
        return result;
      };
    }
  },
  watch: {
    value: function value(val, oldVal) {
      // if(val != oldVal) {
      //   this.selectValue = val;
      //   this.getModelData();
      // }
      this.selectValue = val;
      this.getModelData();
    }
  },
  methods: {
    // 权益礼品搜索
    searchNameChange: function searchNameChange(val) {
      var afVal = val.trim() || "";

      if (afVal && afVal != "") {
        this.selectOptions = this.selectOptions.filter(function (item) {
          return item.name.includes(afVal);
        });
      } else {
        this.getModelData();
      }
    },
    // 获取下拉列表内容
    getModelData: function getModelData() {
      var _this = this;

      _ActivityManager.default.giftList().then(function (result) {
        _this.selectOptions = [];
        _this.initSelectOptions = [];
        result.data.forEach(function (item) {
          var itemObj = {
            giftId: item.secUuid,
            value: item.id,
            // 礼品编号
            name: item.giftName,
            // 礼品名称
            type: item.giftType,
            // 礼品类型
            inventory: item.inventory,
            // 库存
            giftNumber: '',
            // 礼品限量
            probability: '',
            // 概率
            // tenantId: item.tenantId || '', // 租户Id
            channelSource: item.channelSource,
            // 渠道号
            // integral: item.integral, // 积分
            isSelected: false
          };

          if (_this.selectTitle.value == item.giftType) {
            _this.selectOptions.push(itemObj);
          }

          _this.initSelectOptions.push(itemObj);
        });

        _this.lightItem();
      });
    },
    // 展开收起触发事件
    visibleChange: function visibleChange(val) {
      this.drownShow = val;
    },
    // 下拉框项点击事件
    handleCommand: function handleCommand(command) {
      var _this2 = this;

      var selectItem = JSON.parse(JSON.stringify(command));
      delete selectItem.isSelected;
      this.selectOptions.forEach(function (item) {
        if (command.giftId == item.giftId) {
          if (command.isSelected) {
            item.isSelected = false;
            var inIndex = 0;

            _this2.selectValue.forEach(function (sf, index) {
              if (sf.giftId == command.giftId) {
                inIndex = index;
              }
            });

            _this2.selectValue.splice(inIndex, 1);
          } else {
            item.isSelected = true;

            _this2.selectValue.push(selectItem);
          }
        }
      });
      this.$emit("selected", this.selectValue);
    },
    // 高亮反显下拉项
    lightItem: function lightItem() {
      var _this3 = this;

      if (this.selectValue.length) {
        this.initSelectOptions.forEach(function (item) {
          var isIn = _this3.selectValue.some(function (ss) {
            return ss.giftId == item.giftId;
          });

          if (isIn) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        });
        this.selectOptions.forEach(function (item) {
          var isIn = _this3.selectValue.some(function (ss) {
            return ss.giftId == item.giftId;
          });

          if (isIn) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        });
      }

      console.log('selectOptions:', this.selectOptions);
    },
    selectTitleClick: function selectTitleClick(title) {
      var _this4 = this;

      this.selectTitle = title;
      this.titleList.forEach(function (item) {
        if (item.value == title.value) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      this.selectOptions = [];
      this.initSelectOptions.forEach(function (item) {
        if (item.type == title.value) {
          _this4.selectOptions.push(item);
        }
      });
    },
    closeValueItem: function closeValueItem(val, index) {
      this.selectValue.splice(index, 1);
      this.lightItem();
    },
    getTitleList: function getTitleList() {
      var _this5 = this;

      // 礼品类型下拉框数据字典
      _ActivityManager.default.dictListGet("GIFT_TYPE").then(function (result) {
        result.data[0].forEach(function (item, index) {
          item.value = item.dictValue;
          item.name = item.dictLabel;

          if (index == 0) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
        _this5.titleList = result.data[0];
        _this5.selectValue = _this5.value;
        _this5.selectTitle = _this5.titleList[0];

        _this5.getModelData();
      });
    },
    // 阻止冒泡
    checkBoxClick: function checkBoxClick() {}
  },
  created: function created() {
    this.getTitleList();
  }
};
exports.default = _default2;