"use strict";

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.string.trim.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _pathToRegexp = require("path-to-regexp");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{
          path: '/dashboard',
          meta: {
            title: '首页'
          }
        }].concat(matched); // matched = [{path: '/dashboard', meta: {title: '活动管理'}}].concat(matched)
      }

      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      }); // this.changeRoute()
    },
    isDashboard: function isDashboard(route) {
      var name = route && route.name;

      if (!name) {
        return false;
      }

      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = (0, _pathToRegexp.compile)(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          path = item.path;

      if (this.$route.path === redirect) {
        return;
      }

      if (redirect) {
        this.$router.push(redirect);
        return;
      }

      this.$router.push(this.pathCompile(path));
    },
    // 将首页换成活动管理
    changeRoute: function changeRoute() {
      var routeOutIndex;
      this.levelList.map(function (item, index) {
        if (item.path == '/activityProduct') {
          routeOutIndex = index;
          return;
        }
      });

      if (routeOutIndex || routeOutIndex == 0) {
        this.levelList.splice(routeOutIndex, 1);
      }

      var routeIndex;
      var routeItem;
      this.levelList.map(function (item, index) {
        if (item.path == '/activityProduct/activityManager') {
          routeIndex = index;
          routeItem = item;
          return;
        }
      });

      if (routeIndex || routeIndex == 0) {
        this.levelList.splice(routeIndex, 1);
        this.levelList[0] = routeItem;
      } else {
        this.levelList[0] = {
          path: '/activityProduct/activityManager',
          meta: {
            title: '活动管理'
          }
        };
      }
    }
  }
};
exports.default = _default;