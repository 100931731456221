"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.string.link.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getShowPageBtn = getShowPageBtn;

var _vue = _interopRequireDefault(require("vue"));

// 根据参数键名查询参数值
function getShowPageBtn(arr, title, btnCtrlData) {
  for (var i = 0; i < arr.length; i++) {
    var children = arr[i].children;

    if (arr[i].powerName == title) {
      if (children.length > 0) {
        for (var c = 0; c < children.length; c++) {
          var powerCodeArr = children[c].powerCode.split('_');
          var lastItem = powerCodeArr[powerCodeArr.length - 1];

          switch (lastItem) {
            case 'BATCHDELETE':
              btnCtrlData.batchDelete = children[c].checked;
              break;

            case 'DELETE':
              btnCtrlData.delete = children[c].checked;
              break;

            case 'ADD':
              btnCtrlData.add = children[c].checked;
              break;

            case 'UPDATE':
              btnCtrlData.update = children[c].checked;
              break;

            case 'QUERY':
              btnCtrlData.query = children[c].checked;
              break;

            case 'IMPORT':
              btnCtrlData.import = children[c].checked;
              break;

            case 'EXPORT':
              btnCtrlData.export = children[c].checked;
              break;

            case 'DETAIL':
              btnCtrlData.detail = children[c].checked;
              break;

            case 'LIMITCONFIG':
              btnCtrlData.limitconfig = children[c].checked;
              break;

            case 'LINK':
              btnCtrlData.link = children[c].checked;
              break;

            case 'MENUPUT':
              btnCtrlData.menuput = children[c].checked;
              break;

            case 'POWERPUT':
              btnCtrlData.powerput = children[c].checked;
              break;

            case 'ENABLE':
              btnCtrlData.enable = children[c].checked;
              break;

            case 'DISPLAY':
              btnCtrlData.display = children[c].checked;
              break;

            case 'UNDISPLAY':
              btnCtrlData.undisplay = children[c].checked;
              break;

            case 'CHANCECONFIG':
              btnCtrlData.chanceconfig = children[c].checked;
              break;

            case 'LEVELCONFIG':
              btnCtrlData.levelconfig = children[c].checked;
              break;

            case 'OKMANORDISPLAY':
              btnCtrlData.okmanordisplay = children[c].checked;
              break;

            case 'BBWCAROUSE':
              btnCtrlData.bbwcarouse = children[c].checked;
              break;

            case 'HOMEDISPLAY':
              btnCtrlData.homedisplay = children[c].checked;
              break;

            case 'CHECK':
              btnCtrlData.check = children[c].checked;
              break;

            case 'SENDTICKET':
              btnCtrlData.sendticket = children[c].checked;
              break;

            case 'PASSWORD':
              btnCtrlData.password = children[c].checked;
              break;

            case 'INNERBATCHDELETE':
              btnCtrlData.innerbatchDelete = children[c].checked;
              break;

            case 'INNERDELETE':
              btnCtrlData.innerdelete = children[c].checked;
              break;

            case 'INNERADD':
              btnCtrlData.inneradd = children[c].checked;
              break;

            case 'INNERUPDATE':
              btnCtrlData.innerupdate = children[c].checked;
              break;

            default:
              break;
          }
        }
      }
    } else if (children.length > 0) {
      _vue.default.prototype.$getShowPageBtn(children, title, btnCtrlData);
    } else {
      continue;
    }
  } // let menuData = Vue.prototype.$menuData
  // return menuData

}