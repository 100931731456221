var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDetail
    ? _c(
        "el-upload",
        {
          ref: "upload",
          attrs: {
            action: "''",
            limit: this.limit,
            "before-remove": _vm.beforeRemove,
            "on-exceed": _vm.handleExceed,
            "on-remove": _vm.handleRemove,
            "http-request": _vm.handleUpload,
            "file-list": _vm.file
          }
        },
        [
          _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
            _vm._v("上传")
          ])
        ],
        1
      )
    : _c(
        "div",
        _vm._l(_vm.value, function(item, index) {
          return _c(
            "div",
            { key: index },
            [
              item.url
                ? _c("el-image", {
                    staticStyle: { width: "80px", height: "100%" },
                    attrs: { src: item.url, fit: "scale-down" }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }