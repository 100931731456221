var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "角色菜单分配",
        visible: _vm.visibleDrawer,
        "close-on-click-modal": false,
        width: "650px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDrawer = $event
        },
        closed: _vm.handleClose
      }
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "5px" } },
        [
          _c("el-tree", {
            ref: "refTree",
            attrs: {
              data: _vm.treeData,
              props: _vm.defaultProps,
              "expand-on-click-node": false,
              accordion: "",
              "show-checkbox": "",
              "node-key": "id"
            },
            on: { "check-change": _vm.handleCheckChange }
          }),
          _c(
            "div",
            {
              staticClass: "text-center",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.visibleDrawer = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }