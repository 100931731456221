var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          ref: "Form",
          attrs: {
            width: "800px",
            title: _vm.Form.id ? "编辑" : "新增",
            visible: _vm.visible,
            "before-close": _vm.closeForm
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "Form",
              staticStyle: { width: "700px" },
              attrs: {
                model: _vm.Form,
                "label-width": "180px",
                rules: _vm.Rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "详情页名称", prop: "pageName" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "请输入详情页名称"
                    },
                    model: {
                      value: _vm.Form.pageName,
                      callback: function($$v) {
                        _vm.$set(_vm.Form, "pageName", $$v)
                      },
                      expression: "Form.pageName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入描述信息" },
                    model: {
                      value: _vm.Form.describe,
                      callback: function($$v) {
                        _vm.$set(_vm.Form, "describe", $$v)
                      },
                      expression: "Form.describe"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详情页类型", prop: "pageType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.Form.pageType,
                        callback: function($$v) {
                          _vm.$set(_vm.Form, "pageType", $$v)
                        },
                        expression: "Form.pageType"
                      }
                    },
                    _vm._l(_vm.pageTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景图片", prop: "backImageDto" } },
                [
                  _c("CommonFileUpload", {
                    attrs: {
                      value: _vm.Form.backImageDto
                        ? [_vm.Form.backImageDto]
                        : [],
                      limit: 1,
                      isDetail: _vm.isDetail
                    },
                    on: { change: _vm.reviewFileChange, btnLoad: _vm.btnLoad }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "咨询跳转功能" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.Form.isConsultationJump,
                        callback: function($$v) {
                          _vm.$set(_vm.Form, "isConsultationJump", $$v)
                        },
                        expression: "Form.isConsultationJump"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _vm.Form.isConsultationJump
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { "margin-left": "30px" },
                          attrs: { size: "small", placeholder: "请选择类型" },
                          model: {
                            value: _vm.Form.jumpPage,
                            callback: function($$v) {
                              _vm.$set(_vm.Form, "jumpPage", $$v)
                            },
                            expression: "Form.jumpPage"
                          }
                        },
                        _vm._l(_vm.jumpPageOptions, function(item) {
                          return _c("el-option", {
                            key: item.dictValue,
                            attrs: {
                              label: item.dictLabel,
                              value: item.dictValue
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.Form.isConsultationJump && _vm.Form.jumpPage === 1
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "30px" },
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.openLink }
                        },
                        [_vm._v("链接设置")]
                      )
                    : _vm._e(),
                  _c("br"),
                  _vm.Form.isConsultationJump
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _vm._v(" 按钮显示名称： "),
                              _c("CommonFileUpload", {
                                attrs: {
                                  value: _vm.Form.consultButtonNameDto
                                    ? [_vm.Form.consultButtonNameDto]
                                    : [],
                                  limit: 1,
                                  isDetail: _vm.isDetail
                                },
                                on: {
                                  change: _vm.buttonFileChange,
                                  btnLoad: _vm.btnLoad
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm._v(" 按钮起始坐标："),
                              _c("el-input", {
                                staticStyle: { width: "120px" },
                                attrs: {
                                  size: "small",
                                  placeholder: "请输入内容"
                                },
                                model: {
                                  value: _vm.Form.consultButtonStartCoordinates,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.Form,
                                      "consultButtonStartCoordinates",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "Form.consultButtonStartCoordinates"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "页面保存功能" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 1, "false-label": 0 },
                        model: {
                          value: _vm.Form.isPageSave,
                          callback: function($$v) {
                            _vm.$set(_vm.Form, "isPageSave", $$v)
                          },
                          expression: "Form.isPageSave"
                        }
                      },
                      [_vm._v("启用")]
                    ),
                    _vm.Form.isPageSave
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-left": "30px"
                            }
                          },
                          [
                            _vm._v(" 上传海报： "),
                            _c("CommonFileUpload", {
                              attrs: {
                                value: _vm.Form.pageSavePicDto
                                  ? [_vm.Form.pageSavePicDto]
                                  : [],
                                limit: 1,
                                isDetail: _vm.isDetail
                              },
                              on: {
                                change: _vm.posterFileChange,
                                btnLoad: _vm.btnLoad
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm.Form.isPageSave
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _vm._v(" 按钮显示名称："),
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: {
                                size: "small",
                                placeholder: "请输入内容"
                              },
                              model: {
                                value: _vm.Form.pageButtonName,
                                callback: function($$v) {
                                  _vm.$set(_vm.Form, "pageButtonName", $$v)
                                },
                                expression: "Form.pageButtonName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _vm._v(" 按钮起始坐标："),
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: {
                                size: "small",
                                placeholder: "请输入内容"
                              },
                              model: {
                                value: _vm.Form.pageButtonStartCoordinates,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.Form,
                                    "pageButtonStartCoordinates",
                                    $$v
                                  )
                                },
                                expression: "Form.pageButtonStartCoordinates"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "收集意向客户功能" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.Form.isCollectPotentialCustomers,
                        callback: function($$v) {
                          _vm.$set(_vm.Form, "isCollectPotentialCustomers", $$v)
                        },
                        expression: "Form.isCollectPotentialCustomers"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _c("br"),
                  _vm.Form.isCollectPotentialCustomers
                    ? _c(
                        "div",
                        [
                          _vm._v(" 按钮起始坐标："),
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: { size: "small", placeholder: "请输入内容" },
                            model: {
                              value: _vm.Form.collectButtonStartCoordinates,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.Form,
                                  "collectButtonStartCoordinates",
                                  $$v
                                )
                              },
                              expression: "Form.collectButtonStartCoordinates"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeForm } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleAdd }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          ),
          _c("LinkDialog", {
            attrs: { visible: _vm.linkVisible, row: _vm.jumpPageLinkDTO },
            on: { addLink: _vm.addLink, closeLinkDialog: _vm.closeLinkDialog }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }