"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.object.values.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _throttle = _interopRequireDefault(require("lodash/throttle"));

var _echarts = _interopRequireDefault(require("echarts/lib/echarts"));

var _line = _interopRequireDefault(require("echarts/lib/chart/line"));

var _bar = _interopRequireDefault(require("echarts/lib/chart/bar"));

var _pie = _interopRequireDefault(require("echarts/lib/chart/pie"));

var _title = _interopRequireDefault(require("echarts/lib/component/title"));

var _tooltip = _interopRequireDefault(require("echarts/lib/component/tooltip"));

var _legend = _interopRequireDefault(require("echarts/lib/component/legend"));

var _dataset = _interopRequireDefault(require("echarts/lib/component/dataset"));

var _dataZoom = _interopRequireDefault(require("echarts/lib/component/dataZoom"));

var _dataZoomInside = _interopRequireDefault(require("echarts/lib/component/dataZoomInside"));

var _dataZoomSlider = _interopRequireDefault(require("echarts/lib/component/dataZoomSlider"));

/**
 * 这个文件解开 echarts 所有需要的依赖
 * @author Conor 2020-04-12
 */
// ECharts 主模块
// 折线图
// 柱状图
// 饼图
// import scatter from 'echarts/lib/chart/scatter' // 散点图
// import radar from 'echarts/lib/chart/radar' // 雷达图
// import map from 'echarts/lib/chart/map' // 地图
// import tree from 'echarts/lib/chart/tree' // 树图
// import treemap from 'echarts/lib/chart/treemap' // 层级数据
// import graph from 'echarts/lib/chart/graph' // 关系图
// import gauge from 'echarts/lib/chart/gauge' // 仪表盘
// import funnel from 'echarts/lib/chart/funnel' // 漏斗图
// import parallel from 'echarts/lib/chart/parallel' // 平行坐标系的系列
// import sankey from 'echarts/lib/chart/sankey' // 桑基图
// import boxplot from 'echarts/lib/chart/boxplot' // 『箱形图』、『盒须图』、『盒式图』、『盒状图』、『箱线图』
// import candlestick from 'echarts/lib/chart/candlestick' // K线图
// import effectScatter from 'echarts/lib/chart/effectScatter' // 带有涟漪特效动画的散点（气泡）图。利用动画特效可以将某些想要突出的数据进行视觉突出。
// import lines from 'echarts/lib/chart/lines' // 路径图，用于带有起点和终点信息的线数据的绘制，主要用于地图上的航线，路线的可视化。
// import heatmap from 'echarts/lib/chart/heatmap' // 热力图
// import pictorialBar from 'echarts/lib/chart/pictorialBar' // 象形柱图
// import themeRiver from 'echarts/lib/chart/themeRiver' // 主题河流
// import sunburst from 'echarts/lib/chart/sunburst' // 旭日图（Sunburst）由多层的环形图组成，在数据结构上，内圈是外圈的父节点。因此，它既能像饼图一样表现局部和整体的占比，又能像矩形树图一样表现层级关系。
// import custom from 'echarts/lib/chart/custom'
// 标题
// 提示框组件
// import toolbox from 'echarts/lib/component/toolbox' // 工具栏组件
// import grid from 'echarts/lib/component/grid' // 直角坐标系底板
// import polar from 'echarts/lib/component/polar' // 极坐标系底板
// import geo from 'echarts/lib/component/geo' // 地理坐标系
// import singleAxis from 'echarts/lib/component/singleAxis' // 单轴坐标系
// import calendar from 'echarts/lib/component/calendar' // 日历坐标系
// import graphic from 'echarts/lib/component/graphic' // 原生图形元素组件
// import axisPointer from 'echarts/lib/component/axisPointer' // 坐标轴指示器
// import brush from 'echarts/lib/component/brush' // 区域选择组件
// import timeline from 'echarts/lib/component/timeline' // 切换、播放等操作
// import markPoint from 'echarts/lib/component/markPoint'
// import markLine from 'echarts/lib/component/markLine'
// import markArea from 'echarts/lib/component/markArea'
// import legendScroll from 'echarts/lib/component/legendScroll'
// 数据区缩放组件
// import visualMap from 'echarts/lib/component/visualMap' // 视觉映射组件
// import visualMapContinuous from 'echarts/lib/component/visualMapContinuous'
// import visualMapPiecewise from 'echarts/lib/component/visualMapPiecewise'
// import {Group, Text, Line, Circle, Sector, Ring, LinearGradient, RadialGradient, BoundingRect, CompoundPath} from 'echarts/lib/util/graphic'
var _default = {
  data: function data() {
    return {
      // 使用 $_ 前缀作为混入私有属性
      // https://cn.vuejs.org/v2/style-guuide/index.html#私有属性名必要
      $_sidebarElement: null,
      $_chartResize: null
    };
  },
  created: function created() {
    console.log([_line.default, _bar.default, _pie.default, _title.default, _tooltip.default, _legend.default, _dataset.default, _dataZoom.default, _dataZoomInside.default, _dataZoomSlider.default]);
  },
  mounted: function mounted() {
    this.$_initChart();
  },
  activated: function activated() {
    this.$_initChart(); // when keep-alive chart activated, auto resize

    this.$_chartResize();
  },
  deactivated: function deactivated() {
    this.$_destroyChart();
  },
  beforeDestroy: function beforeDestroy() {
    this.$_destroyChart();
  },
  methods: {
    // 使用 $_ 前缀作为混入私有属性
    // https://cn.vuejs.org/v2/style-guuide/index.html#私有属性名必要
    $_sidebarResizeHandler: function $_sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        // 左侧侧边栏收缩监听
        this.$_chartResize();
      }
    },

    /**
     * 初始化图表，echarts 实例初始化成功之后会触发 bind 方法，用于绑定图表事件
     */
    $_initChart: function $_initChart() {
      var _this = this;

      Object.values(this.charts).forEach(function (chart) {
        if (!chart.instance) {
          chart.instance = _echarts.default.init(document.getElementById(chart.id));
          chart.instance.setOption(chart.option);

          if (chart.bind) {
            // instance 初始化之后绑定图表事件
            chart.bind();
          }
        }
      });
      this.$_chartResize = (0, _throttle.default)(function () {
        Object.values(_this.charts).forEach(function (chart) {
          var _chart$instance;

          (_chart$instance = chart.instance) === null || _chart$instance === void 0 ? void 0 : _chart$instance.resize();
        });
      }, 400);
      window.addEventListener('resize', this.$_chartResize);
      this.$_sidebarElement = document.getElementsByClassName('sidebar-container')[0];
      this.$_sidebarElement && this.$_sidebarElement.addEventListener('transitionend', this.$_sidebarResizeHandler);
    },

    /**
     * 销毁图表实例，销毁之前调用 unbind 方法，用于清除 bind 中的事件
     */
    $_destroyChart: function $_destroyChart() {
      window.removeEventListener('resize', this.$_chartResize);
      this.$_sidebarElement && this.$_sidebarElement.removeEventListener('transitionend', this.$_sidebarResizeHandler);
      Object.values(this.charts).forEach(function (chart) {
        if (chart.instance) {
          if (chart.unbind) {
            // instance 销毁之前解绑图表事件
            chart.unbind();
          }

          chart.instance.dispose();
          chart.instance = null;
        }
      });
    }
  }
};
exports.default = _default;