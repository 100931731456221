"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.object.get-own-property-names.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _CommonService = _interopRequireDefault(require("@/api/CommonService"));

var _GiftManageService = _interopRequireDefault(require("@/api/GiftManageService"));

var _axios = _interopRequireDefault(require("axios"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    },
    addType: {
      type: Number,
      required: false
    },
    isDetail: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      rules: {
        // 基本信息必填校验
        giftName: [{
          required: true,
          message: "请输入礼品名称",
          trigger: "blur"
        }],
        giftType: [{
          required: true,
          message: "请选择礼品类型",
          trigger: "blur"
        }],
        integralType: [{
          required: true,
          message: '请选择积分类型',
          trigger: 'change'
        }],
        integral: [{
          required: true,
          message: "请输入积分",
          trigger: "blur"
        }],
        inventory: [{
          required: true,
          message: "请输入库存数量",
          trigger: "blur"
        }],
        channelSource: [{
          required: true,
          message: "请选择渠道来源",
          trigger: "change"
        }],
        cpncdFileUuid: [{
          required: true,
          message: "请导入券码",
          trigger: "change"
        }],
        exchangeInstructions: [{
          required: true,
          message: "请输入兑换说明",
          trigger: "blur"
        }],
        giftUnitPrice: [{
          required: true,
          message: "请输入礼品单价",
          trigger: "blur"
        }]
      },
      isEdit: false,
      giftTypeOptions: [],
      channelTypeOptions: [],
      pointTypeOptions: [],
      uploadFile: [],
      fileList: [],
      selectRow: {},
      file: null
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  watch: {},
  methods: {
    // 删除无关联券码
    deleteNoAssCoupon: function deleteNoAssCoupon() {
      var _this = this;

      this.btnSaveLoading = true;

      _GiftManageService.default.deleteNoAssCoupon().then(function (result) {
        result.hasFailure(function () {
          _this.btnSaveLoading = false; // this.$message.warning(result.getErrorMessage('保存失败'))
        }).hasSuccess(function () {
          _this.btnSaveLoading = false; // this.$message.success('保存成功')
        });
      });
    },
    // ==========
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this2 = this;

      // 规则类型处理
      // if(this.row.exchangeInstructions && this.row.exchangeInstructions.length > 4000){
      //   this.$message.error("输入规则文本不能超过4000字,请重新输入！");
      //   return false
      // }
      this.$refs.rowForm.validate(function (valid, tipObj) {
        if (valid) {
          _this2.btnSaveLoading = true; // const postObj = {
          //   giftTypNm: 'COUPON_CODE'
          // };
          // const postParam = {...this.row,...postObj};

          var postParam = (0, _objectSpread2.default)({}, _this2.selectRow);

          if (postParam.picture) {
            delete postParam.picture;
          }

          (_this2.row.id ? _GiftManageService.default.update(postParam) : _GiftManageService.default.save(postParam)).then(function (result) {
            result.hasFailure(function () {
              _this2.btnSaveLoading = false;

              _this2.$message.warning(result.getErrorMessage("保存失败"));
            }).hasSuccess(function () {
              _this2.btnSaveLoading = false;

              _this2.$message.success("保存成功");

              _this2.handleCancel();

              _this2.$emit("ok");
            });
          });
        } else {
          var ObjNameArr = Object.getOwnPropertyNames(tipObj);

          _this2.$message.error(tipObj[ObjNameArr[0]][0].message);

          return false;
        }
      });
    },
    beforeUpload: function beforeUpload(file) {
      // console.log('文件上传前')
      this.file = file;
    },
    beforeFileRemove: function beforeFileRemove(file, fileList) {
      // const uid = file.uid;
      // let curIndex = 0;
      // this.fileList.forEach((item, index) => {
      //   if (item.uid == uid) {
      //     curIndex = index;
      //   }
      // });
      // this.uploadFile.splice(curIndex, 1);
      this.fileList = [];
      this.selectRow.cpncdFileUuid = null;
      this.selectRow.cpncdFileName = null;
    },
    importFun: function importFun() {
      var _this3 = this;

      var formData = new FormData();
      formData.append('file', this.file);

      _axios.default.post(process.env.VUE_APP_BASE_API + '/web/upload/excel/coupon/code/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (res) {
        _this3.$message({
          message: '文件已上传，导入任务执行中',
          type: 'success'
        });

        console.log(res.data.data[0]);

        _this3.$set(_this3.selectRow, 'cpncdFileUuid', res.data.data[0].batchUUID);

        _this3.$set(_this3.selectRow, 'inventory', res.data.data[0].inventory);

        _this3.$set(_this3.selectRow, 'cpncdFileName', _this3.file.name);
      }).catch(function (err) {
        _this3.$message({
          message: '导入失败',
          type: 'error'
        });

        _this3.fileList = [];
      });
    },
    // 图片上传文件超出个数限制时
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u6700\u591A\u53EA\u80FD\u4E0A\u4F201\u5F20\u56FE\u7247\u54E6\uFF01");
    },
    handleUploadPicture: function handleUploadPicture(_ref) {
      var _this4 = this;

      var file = _ref.file;
      // this.uploadFile.push(file);
      var fileArr = [file];
      this.uploadFile = fileArr;
      this.btnSaveLoading = true;

      _CommonService.default.uploadSingleTemp(file).then(function (result) {
        console.log(result);
        _this4.btnSaveLoading = false;
        _this4.selectRow.pictureUrl = result.data[0].secUuid;
        console.log('pictureUrl:', _this4.selectRow.pictureUrl);
      });
    },
    beforeRemove: function beforeRemove(file, fileList) {
      // const uid = file.uid;
      // let curIndex = 0;
      // this.uploadFile.forEach((item, index) => {
      //   if (item.uid == uid) {
      //     curIndex = index;
      //   }
      // });
      // this.uploadFile.splice(curIndex, 1);
      this.uploadFile = [];
      this.selectRow.pictureUrl = null;
    },
    getGiftDetail: function getGiftDetail() {
      var _this5 = this;

      var postParam = {
        id: this.row.id
      };

      _GiftManageService.default.getDetail(postParam).then(function (result) {
        _this5.selectRow = result.data[0];

        if (result.data[0].picture && result.data[0].picture.videoUrl) {
          var picObj = {
            name: result.data[0].picture.fileName,
            secUuid: result.data[0].picture.secUuid
          };
          _this5.uploadFile = [picObj];
          _this5.selectRow.pictureUrl = result.data[0].secUuid;
        }

        if (result.data[0].cpncdFileUuid && result.data[0].cpncdFileName) {
          var fileObj = {
            name: result.data[0].cpncdFileName,
            secUuid: result.data[0].cpncdFileUuid
          };
          _this5.fileList = [fileObj];
        }
      });
    }
  },
  created: function created() {
    var _this6 = this;

    // if(!this.row.slicrPkid ){
    //   this.deleteNoAssCoupon()
    // }
    if (this.addType) {
      this.row.giftType = this.addType;
    }

    this.selectRow = JSON.parse(JSON.stringify(this.row));

    if (this.row.id) {
      this.getGiftDetail();
    } // 礼品类型下拉框数据字典


    _GiftManageService.default.dictListGet("GIFT_TYPE").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this6.giftTypeOptions = result.data[0];
    }); // 渠道来源下拉框数据字典


    _GiftManageService.default.dictListGet("CHANNEL_SOURCE").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this6.channelTypeOptions = result.data[0];
    }); // 积分类型下拉框数据字典


    _GiftManageService.default.dictListGet("INTEGRAL_TYPE").then(function (result) {
      result.data[0].forEach(function (item) {
        item.dictValue = Number(item.dictValue);
      });
      _this6.pointTypeOptions = result.data[0];
    });
  }
};
exports.default = _default2;