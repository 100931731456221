"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Enums = require("@/api/Enums");

var _utils = require("@/utils");

var _http = _interopRequireDefault(require("@/api/http"));

var _Result = _interopRequireDefault(require("@/utils/entity/Result"));

var _CommonService = _interopRequireDefault(require("@/api/CommonService"));

var _convertImgUrlPrefix2 = _interopRequireDefault(require("@/utils/convertImgUrlPrefix"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'FileUpload',
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    fileType: {
      type: String,
      default: 'file'
    },
    size: {
      type: String,
      default: 'mini'
    },
    type: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String,
      default: '上传文件'
    },
    drag: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: false
    } // btnSaveLoading: {
    //   type: Boolean,
    //   default: false
    // },
    // saveBtnSuccess: {
    //   type: Function,
    //   default: false
    // },

  },
  data: function data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      // path: "",
      acceptVal: undefined,
      acceptTypes: _Enums.FileTypes,
      fileTypeMap: _Enums.FileTypeMap,
      bgImgListIndex: undefined,
      bgImgList: []
    };
  },
  watch: {
    value: function value(val) {
      if (val[0] === undefined || val[0] === null) {
        this.bgImgList = [];
      } else {
        // 为了兼容内网外网同时访问到图片
        val.forEach(function (row) {
          if (row && row.url) {
            row.url = row.url;
          } else {
            row.url = null;
          }
        });
        this.bgImgList = Object.assign([], val);
      }
    },
    fileType: {
      handler: function handler(val) {
        var _this = this;

        if (val !== 'file' && !this.drag) {
          var acceptFileTypes = this.fileType.split(',');
          var accepts = [];
          acceptFileTypes.forEach(function (item) {
            accepts.push(_this.fileTypeMap[item]);
          });
          this.acceptVal = accepts.join(',');
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    if (this.value === undefined) {
      this.bgImgList = [];
    } else {
      if (this.value[0]) {
        this.bgImgList = Object.assign([], this.value);
      }
    }
  },
  computed: {
    convertImgUrlPrefix: function convertImgUrlPrefix() {
      return function (url) {
        return (0, _convertImgUrlPrefix2.default)(url);
      };
    }
  },
  methods: {
    handlePicRemove: function handlePicRemove(file) {
      var index;
      this.bgImgList.forEach(function (item, itemIndex) {
        if (item.uid == file.uid) {
          index = itemIndex;
        }
      });

      if (index != undefined) {
        this.bgImgList.splice(index, 1);
      }

      this.syncFileInfo(this.bgImgList);
    },
    handlePicEdit: function handlePicEdit(file) {
      var index;
      this.bgImgList.forEach(function (item, itemIndex) {
        if (item.uid == file.uid) {
          index = itemIndex;
        }
      });

      if (index != undefined) {
        this.bgImgListIndex = index;
      }

      this.$refs.actBgUpload.$refs["upload-inner"].handleClick(index);
    },
    beforePicUpload: function beforePicUpload(file, index) {
      // if (!["image/jpeg", "image/png", "image/x-icon"].includes(file.type)) {
      //   this.$message.error("上传图片只能是 jpg,png,ico 格式!");
      //   if (this.bgImgListIndex != undefined) {
      //     this.bgImgListIndex = undefined;
      //   }
      //   return false;
      // }
      // if (file.size / 1024 > 500) {
      //   this.$message.error("上传图片大小不能超过 500 KB !");
      //   if (this.bgImgListIndex != undefined) {
      //     this.bgImgListIndex = undefined;
      //   }
      //   return false;
      // }
      return true;
    },
    handlePicUpload: function handlePicUpload(_ref) {
      var _this2 = this;

      var file = _ref.file,
          onSuccess = _ref.onSuccess,
          onError = _ref.onError;

      // this.saveBtnSuccess(false)
      _CommonService.default.uploadTemp(file).then(function (result) {
        if (result.code != "A00000") {
          if (_this2.bgImgListIndex != undefined) {
            _this2.bgImgListIndex = undefined;
          }
        }

        result.dataFirst(function (row) {
          return onSuccess(row);
        }, function () {
          return onError("文件上传成功，但服务器未返回文件信息");
        }).hasFailure(onError(result.getErrorMessage("文件上传失败")));
      });
    },
    handlePicSuccess: function handlePicSuccess(res) {
      // 开发环境替换前缀
      var resObj = {
        name: res.name,
        uname: res.uname,
        url: res.url
      };
      var bgImgList = this.bgImgList;

      if (this.bgImgListIndex != undefined) {
        bgImgList.splice(this.bgImgListIndex, 1, resObj);
        this.bgImgListIndex = undefined;
      } else {
        bgImgList.push(resObj);
      }

      this.bgImgList = bgImgList;
      this.syncFileInfo(this.bgImgList);
    },

    /**
     * 同步fileInfo
     */
    syncFileInfo: function syncFileInfo(fileList) {
      this.$emit('change', fileList, this.index);
    },

    /**
     * 预览
     */
    handlePreview: function handlePreview(file) {
      var link = document.createElement('a');
      link.style.display = 'none';
      link.download = file.name;
      link.href = file.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
exports.default = _default2;