"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Page404',
  computed: {
    message: function message() {
      return 'The webmaster said that you can not enter this page...';
    }
  },
  methods: {
    backHome: function backHome() {
      if (process.env.VUE_APP_ENV === 'sit') {
        location.href = '/szyx/sit/';
      } else if (process.env.VUE_APP_ENV === 'uat') {
        location.href = '/szyx/uat/';
      } else {
        location.href = '/';
      }
    }
  }
};
exports.default = _default;