"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _uniqueId = _interopRequireDefault(require("lodash/uniqueId"));

var _charts = _interopRequireDefault(require("@/components/Charts/mixin/charts"));

//
//
//
//
//
//
var _default = {
  mixins: [_charts.default],
  props: {
    data1: {
      type: Array,
      default: null
    },
    data2: {
      type: Array,
      default: null
    },
    data3: {
      type: Array,
      default: null
    },
    timeData: {
      type: Array,
      default: null
    },
    lineTitle: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {
      timepick: '',
      charts: {
        chart: {
          id: (0, _uniqueId.default)('chart_'),
          instance: null,
          // charts mixin 时会通过混入的方式初始化 echarts 实例到这个属性
          option: {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'

              }
            },
            grid: {
              left: '1%',
              right: '1%',
              bottom: '3%',
              top: '10%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: this.timeData
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              }
            },
            series: [{
              name: this.lineTitle && this.lineTitle[0] ? this.lineTitle[0] : '浏览次数',
              type: 'line',
              itemStyle: {
                color: '#5087EC'
              },
              z: 3,
              data: this.data1
            }, {
              name: this.lineTitle && this.lineTitle[1] ? this.lineTitle[1] : '浏览人数',
              type: 'line',
              barGap: '-100%',
              itemStyle: {
                color: '#FF7B1E'
              },
              z: 2,
              data: this.data2
            }, {
              name: this.lineTitle && this.lineTitle[1] ? this.lineTitle[1] : '首次浏览人数',
              type: 'line',
              barGap: '-100%',
              itemStyle: {
                color: '#67C23A'
              },
              z: 4,
              data: this.data3
            }]
          }
        }
      }
    };
  }
};
exports.default = _default;