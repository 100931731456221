var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: "图片设置",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "700px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.addTab(_vm.editableTabsValue)
            }
          }
        },
        [_vm._v("添加链接")]
      ),
      _c(
        "el-tabs",
        {
          staticClass: "tabControl",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            type: "card",
            closable: "",
            "tab-position": "left",
            stretch: ""
          },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.editableTabsValue,
            callback: function($$v) {
              _vm.editableTabsValue = $$v
            },
            expression: "editableTabsValue"
          }
        },
        _vm._l(_vm.editableTabs, function(item, index) {
          return _c(
            "el-tab-pane",
            { key: item.name, attrs: { label: item.title, name: item.name } },
            [
              _c(
                "el-form",
                {
                  ref: "rowForm" + index,
                  refInFor: true,
                  attrs: {
                    model: _vm.selectedRow,
                    rules: _vm.rules,
                    "label-width": "90px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "链接名称", prop: "linkName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          maxlength: "200",
                          placeholder: "请输入链接名称"
                        },
                        model: {
                          value: item.linkName,
                          callback: function($$v) {
                            _vm.$set(item, "linkName", $$v)
                          },
                          expression: "item.linkName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "跳转区域", prop: "linkArea" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择跳转区域" },
                          model: {
                            value: item.linkArea,
                            callback: function($$v) {
                              _vm.$set(item, "linkArea", $$v)
                            },
                            expression: "item.linkArea"
                          }
                        },
                        _vm._l(_vm.linkZoneOptions, function(zoneItem) {
                          return _c("el-option", {
                            key: zoneItem.dictValue,
                            attrs: {
                              label: zoneItem.dictLabel,
                              value: zoneItem.dictValue
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  item.linkArea == "1"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "区域坐标", prop: "linkAreaPosition" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              autocomplete: "off",
                              maxlength: "200",
                              placeholder: "请输入区域坐标"
                            },
                            model: {
                              value: item.linkAreaPosition,
                              callback: function($$v) {
                                _vm.$set(item, "linkAreaPosition", $$v)
                              },
                              expression: "item.linkAreaPosition"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "跳转类型", prop: "linkType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择跳转方式" },
                          model: {
                            value: item.linkType,
                            callback: function($$v) {
                              _vm.$set(item, "linkType", $$v)
                            },
                            expression: "item.linkType"
                          }
                        },
                        _vm._l(_vm.linkTypeOptions, function(typeItem) {
                          return _c("el-option", {
                            key: typeItem.dictValue,
                            attrs: {
                              label: typeItem.dictLabel,
                              value: typeItem.dictValue
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  item.linkType === "applet"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "appid", prop: "appId" } },
                        [
                          _c("el-input", {
                            attrs: {
                              autocomplete: "off",
                              maxlength: "200",
                              placeholder: "请输入appId"
                            },
                            model: {
                              value: item.appId,
                              callback: function($$v) {
                                _vm.$set(item, "appId", $$v)
                              },
                              expression: "item.appId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "链接地址", prop: "linkUrl" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入链接地址"
                        },
                        model: {
                          value: item.linkUrl,
                          callback: function($$v) {
                            _vm.$set(item, "linkUrl", $$v)
                          },
                          expression: "item.linkUrl"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnSaveLoading },
              on: { click: _vm.handleSave }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }