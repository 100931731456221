var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.btnLoading,
          expression: "btnLoading"
        }
      ],
      staticClass: "app-container",
      attrs: {
        "element-loading-text": "看板下载中，请稍等",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)"
      }
    },
    [
      _vm.totalCtrl
        ? _c(
            "el-dialog",
            {
              staticClass: "daigSelfContainer",
              attrs: { width: "500px", visible: _vm.visibleDialog },
              on: {
                "update:visible": function($event) {
                  _vm.visibleDialog = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  staticStyle: { width: "400px", margin: "auto" },
                  attrs: { "label-width": "100px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请选择活动" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择活动" },
                          model: {
                            value: _vm.Form.activityCode,
                            callback: function($$v) {
                              _vm.$set(_vm.Form, "activityCode", $$v)
                            },
                            expression: "Form.activityCode"
                          }
                        },
                        _vm._l(_vm.activityTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.activityCode,
                            attrs: {
                              label: item.activityName,
                              value: item.activityCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.visibleDialog = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.changeActivitytype }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "el-form",
                { staticClass: "demo-form-inline", attrs: { inline: "" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择活动" },
                          on: {
                            change: function($event) {
                              return _vm.changeActivitytype()
                            }
                          },
                          model: {
                            value: _vm.Form.activityCode,
                            callback: function($$v) {
                              _vm.$set(_vm.Form, "activityCode", $$v)
                            },
                            expression: "Form.activityCode"
                          }
                        },
                        _vm._l(_vm.activityTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.activityCode,
                            attrs: {
                              label: item.activityName,
                              value: item.activityCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click" },
                          on: { command: _vm.download }
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v(" 导出Excel"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            _vm._l(_vm.downLoadArr, function(item) {
                              return _c(
                                "el-dropdown-item",
                                {
                                  key: item.dictValue,
                                  attrs: { command: _vm.beforeCommand(item) }
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.overallTitleList
                ? _c(
                    "div",
                    { staticClass: "titleDiv marginBottom" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-card",
                                [
                                  _c("h4", [_vm._v("今日|主页浏览及参与情况")]),
                                  _c("p", { staticClass: "grey" }, [
                                    _vm._v(
                                      _vm._s(_vm.time1) +
                                        " 更新于" +
                                        _vm._s(_vm.time2)
                                    )
                                  ]),
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c("p", [_vm._v("PV")]),
                                        _c("p", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.overallTitleList.todayPV
                                              )
                                            )
                                          ]),
                                          _vm._v(" 次 ")
                                        ])
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c("p", [_vm._v("UV")]),
                                        _c("p", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.overallTitleList.todayUV
                                              )
                                            )
                                          ]),
                                          _vm._v(" 人 ")
                                        ])
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c("p", [_vm._v("首次浏览")]),
                                        _c("p", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.overallTitleList
                                                  .todayFirstBrowseCusNum
                                              )
                                            )
                                          ]),
                                          _vm._v(" 人 ")
                                        ])
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c("p", [_vm._v("签到人数")]),
                                        _c("p", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.overallTitleList
                                                  .todaySignCusNum
                                              )
                                            )
                                          ]),
                                          _vm._v(" 人 ")
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-card",
                                [
                                  _c("h4", [_vm._v("总|主页浏览及参与情况")]),
                                  _c("p", { staticClass: "grey" }, [
                                    _vm._v(
                                      _vm._s(_vm.time1) +
                                        " 更新于" +
                                        _vm._s(_vm.time2)
                                    )
                                  ]),
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c("p", [_vm._v("PV")]),
                                        _c("p", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.overallTitleList.totalPV
                                              )
                                            )
                                          ]),
                                          _vm._v(" 次 ")
                                        ])
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c("p", [_vm._v("UV")]),
                                        _c("p", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.overallTitleList.totalUV
                                              )
                                            )
                                          ]),
                                          _vm._v(" 人 ")
                                        ])
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c("p", [_vm._v("签到次数")]),
                                        _c("p", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.overallTitleList
                                                  .totalSignNum
                                              )
                                            )
                                          ]),
                                          _vm._v(" 次 ")
                                        ])
                                      ]),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c("p", [_vm._v("签到人数")]),
                                        _c("p", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.overallTitleList
                                                  .totalSignCusNum
                                              )
                                            )
                                          ]),
                                          _vm._v(" 人 ")
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.LineChartLoading,
                      expression: "LineChartLoading"
                    }
                  ],
                  staticClass: "marginBottom"
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("h4", { staticStyle: { margin: "0" } }, [
                        _vm._v("活动主页面浏览趋势图")
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择时间" },
                          on: {
                            change: function($event) {
                              return _vm.getLineCharList()
                            }
                          },
                          model: {
                            value: _vm.timeType,
                            callback: function($$v) {
                              _vm.timeType = $$v
                            },
                            expression: "timeType"
                          }
                        },
                        _vm._l(_vm.timeOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("LineChart", {
                    key: _vm.LineChartKey,
                    attrs: {
                      "time-data": _vm.lineChartList.dateList,
                      data1: _vm.lineChartList.pvList,
                      data2: _vm.lineChartList.uvList,
                      data3: _vm.lineChartList.firstBrowseUvList
                    }
                  })
                ],
                1
              ),
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.HistogramLoading,
                      expression: "HistogramLoading"
                    }
                  ],
                  staticClass: "marginBottom"
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("h4", { staticStyle: { margin: "0" } }, [
                        _vm._v("签到任务参与情况")
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function($event) {
                              return _vm.taskChange()
                            }
                          },
                          model: {
                            value: _vm.curTaskUuid,
                            callback: function($$v) {
                              _vm.curTaskUuid = $$v
                            },
                            expression: "curTaskUuid"
                          }
                        },
                        [
                          _vm._l(_vm.appendOptions, function(item) {
                            return _c("el-option", {
                              key: item.taskUuid,
                              attrs: {
                                label: item.taskName,
                                value: item.taskUuid
                              }
                            })
                          }),
                          _c("el-option", {
                            attrs: { label: "所有任务", value: "" }
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c("Histogram", {
                    key: _vm.HistogramKey,
                    attrs: {
                      "time-data": _vm.HistogramList.date,
                      "activity-data": _vm.selectRow,
                      data2: _vm.HistogramList.reachNumber,
                      data3: _vm.HistogramList.receiveNumber
                    }
                  })
                ],
                1
              ),
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.AdvanceHistogramLoading,
                      expression: "AdvanceHistogramLoading"
                    }
                  ],
                  staticClass: "marginBottom"
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("h4", { staticStyle: { margin: "0" } }, [
                        _vm._v("进阶任务参与情况")
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function($event) {
                              return _vm.advanceTaskChange()
                            }
                          },
                          model: {
                            value: _vm.curAdvanceTaskUuid,
                            callback: function($$v) {
                              _vm.curAdvanceTaskUuid = $$v
                            },
                            expression: "curAdvanceTaskUuid"
                          }
                        },
                        _vm._l(_vm.appendOptions, function(item) {
                          return _c("el-option", {
                            key: item.taskUuid,
                            attrs: {
                              label: item.taskName,
                              value: item.taskUuid
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("Histogram", {
                    key: _vm.AdvanceHistogramKey,
                    attrs: {
                      "time-data": _vm.AdvanceHistogramList.date,
                      "activity-data": _vm.selectRow,
                      data1: _vm.AdvanceHistogramList.totalApplyNumber,
                      data2: _vm.AdvanceHistogramList.reachNumber,
                      data3: _vm.AdvanceHistogramList.receiveNumber,
                      type: "advance"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.PieChartLoading,
                      expression: "PieChartLoading"
                    }
                  ],
                  staticClass: "marginBottom"
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("h4", { staticStyle: { margin: "0" } }, [
                        _vm._v("用户分析")
                      ])
                    ]
                  ),
                  _c(
                    "el-row",
                    {
                      staticClass: "pieChartBox",
                      staticStyle: { "margin-top": "30px" }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("PieChart", {
                            key: _vm.PieChartKey,
                            attrs: { data: _vm.PieChartList }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: { placeholder: "请选择用户" },
                                  on: {
                                    change: function($event) {
                                      return _vm.getPieChartList()
                                    }
                                  },
                                  model: {
                                    value: _vm.Form.isApply,
                                    callback: function($$v) {
                                      _vm.$set(_vm.Form, "isApply", $$v)
                                    },
                                    expression: "Form.isApply"
                                  }
                                },
                                _vm._l(_vm.userTypeOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              ),
                              _c("div", { staticStyle: { margin: "8px" } }, [
                                _vm._v("渠道分布")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("PieChart", {
                            key: _vm.agePieChartKey,
                            attrs: { data: _vm.agePieChartList }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: { placeholder: "请选择用户" },
                                  on: {
                                    change: function($event) {
                                      return _vm.getAgePieChartList()
                                    }
                                  },
                                  model: {
                                    value: _vm.Form.ageUserType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.Form, "ageUserType", $$v)
                                    },
                                    expression: "Form.ageUserType"
                                  }
                                },
                                _vm._l(_vm.userTypeOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              ),
                              _c("div", { staticStyle: { margin: "8px" } }, [
                                _vm._v("年龄分布")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }