var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropDownBox" },
    [
      _c(
        "el-dropdown",
        {
          attrs: {
            trigger: "click",
            placement: "bottom-start",
            "hide-on-click": false
          },
          on: { "visible-change": _vm.visibleChange }
        },
        [
          _c("span", { staticClass: "el-dropdown-link dropDownTitle" }, [
            _vm.selectValue && _vm.selectValue.length
              ? _c(
                  "span",
                  { staticClass: "valueItem" },
                  _vm._l(_vm.selectValue, function(val, index) {
                    return _c("span", { key: val.value }, [
                      _c("span", [_vm._v(_vm._s(val.name))]),
                      _c("i", {
                        staticClass: "el-icon-error closeIcon",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.closeValueItem(val, index)
                          }
                        }
                      })
                    ])
                  }),
                  0
                )
              : _c("span", { staticClass: "selectPlaceholder" }, [
                  _vm._v("请选择")
                ]),
            _vm.drownShow
              ? _c("i", { staticClass: "el-icon-arrow-up arrowIcon" })
              : _c("i", { staticClass: "el-icon-arrow-down arrowIcon" })
          ]),
          _c(
            "el-dropdown-menu",
            {
              staticClass: "dropDownContent",
              attrs: { slot: "dropdown" },
              slot: "dropdown"
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "searchInput",
                      attrs: { placeholder: "搜索" },
                      on: { input: _vm.searchNameChange },
                      model: {
                        value: _vm.searchName,
                        callback: function($$v) {
                          _vm.searchName = $$v
                        },
                        expression: "searchName"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "prefix" },
                        slot: "prefix"
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "titleListBox" },
                _vm._l(_vm.titleList, function(title) {
                  return _c(
                    "div",
                    {
                      key: title.value,
                      class: title.selected ? "selectOptionActive" : "",
                      on: {
                        click: function($event) {
                          return _vm.selectTitleClick(title)
                        }
                      }
                    },
                    [_vm._v(_vm._s(title.name))]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "titleLineBox" }, [
                _c("span", { staticClass: "titleLine" }),
                _vm._v(_vm._s(_vm.selectTitle.name)),
                _c("span", { staticClass: "titleAfterLine" })
              ]),
              _c(
                "div",
                { staticClass: "itemBox" },
                _vm._l(_vm.selectOptions, function(item) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: item.giftId,
                      class: { itemActive: item.isSelected },
                      attrs: { command: item }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dropItemLine",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleCommand(item)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "dropItemLeft" },
                            [
                              _c("el-checkbox", {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.checkBoxClick($event)
                                  }
                                },
                                model: {
                                  value: item.isSelected,
                                  callback: function($$v) {
                                    _vm.$set(item, "isSelected", $$v)
                                  },
                                  expression: "item.isSelected"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "dropItemRight" }, [
                            _vm._v(" " + _vm._s(item.name) + " ")
                          ])
                        ]
                      )
                    ]
                  )
                }),
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }