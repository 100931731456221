var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: _vm.row.id ? "编辑" : "新增",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "800px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "rowForm",
          staticClass: "customerForm",
          attrs: {
            model: _vm.selectedRow,
            rules: _vm.rules,
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { flex: "1" },
              attrs: { label: "任务标题", prop: "taskTitle" }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入任务标题",
                  disabled: _vm.isDetail
                },
                model: {
                  value: _vm.selectedRow.taskTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.selectedRow, "taskTitle", $$v)
                  },
                  expression: "selectedRow.taskTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { flex: "1" },
              attrs: { label: "任务副标题", prop: "taskSubtitle" }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入任务副标题",
                  disabled: _vm.isDetail
                },
                model: {
                  value: _vm.selectedRow.taskSubtitle,
                  callback: function($$v) {
                    _vm.$set(_vm.selectedRow, "taskSubtitle", $$v)
                  },
                  expression: "selectedRow.taskSubtitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "描述", prop: "taskDescribe" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入活动描述",
                  disabled: _vm.isDetail
                },
                model: {
                  value: _vm.selectedRow.taskDescribe,
                  callback: function($$v) {
                    _vm.$set(_vm.selectedRow, "taskDescribe", $$v)
                  },
                  expression: "selectedRow.taskDescribe"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { flex: "1" },
              attrs: { label: "任务类型", prop: "taskType" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.isDetail,
                    placeholder: "请选择任务类型"
                  },
                  model: {
                    value: _vm.selectedRow.taskType,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "taskType", $$v)
                    },
                    expression: "selectedRow.taskType"
                  }
                },
                _vm._l(_vm.taskTypeOptions, function(item) {
                  return _c("el-option", {
                    key: item.dictValue,
                    attrs: { label: item.dictLabel, value: item.dictValue }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { flex: "1" },
                  attrs: { label: "计算方式", prop: "calculateType" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择计算方式",
                        disabled: _vm.isDetail
                      },
                      model: {
                        value: _vm.selectedRow.calculateType,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedRow, "calculateType", $$v)
                        },
                        expression: "selectedRow.calculateType"
                      }
                    },
                    _vm._l(_vm.calculateTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.selectedRow.taskType == 0
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "提升金额", prop: "promoteAmount" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          size: "small",
                          placeholder: "请输入提升金额",
                          disabled: _vm.isDetail
                        },
                        model: {
                          value: _vm.selectedRow.promoteAmount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.selectedRow,
                              "promoteAmount",
                              _vm._n($$v)
                            )
                          },
                          expression: "selectedRow.promoteAmount"
                        }
                      }),
                      _vm._v(" 元 ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "基准时间", prop: "baseTime" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择基准时间",
                    disabled: _vm.isDetail
                  },
                  model: {
                    value: _vm.selectedRow.baseTime,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "baseTime", $$v)
                    },
                    expression: "selectedRow.baseTime"
                  }
                },
                _vm._l(_vm.baseTimeOptions, function(item) {
                  return _c("el-option", {
                    key: item.dictValue,
                    attrs: { label: item.dictLabel, value: item.dictValue }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { flex: "1" },
                  attrs: { label: "是否展示", prop: "isShow" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.isDetail,
                        placeholder: "请选择是否展示"
                      },
                      model: {
                        value: _vm.selectedRow.isShow,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedRow, "isShow", $$v)
                        },
                        expression: "selectedRow.isShow"
                      }
                    },
                    _vm._l(_vm.isShowOptions, function(item) {
                      return _c("el-option", {
                        key: item.dictValue,
                        attrs: { label: item.dictLabel, value: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { flex: "1" },
                  attrs: { label: "展示顺序", prop: "taskIndex" }
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 0,
                      label: "描述文字",
                      disabled: _vm.isDetail
                    },
                    model: {
                      value: _vm.selectedRow.taskIndex,
                      callback: function($$v) {
                        _vm.$set(_vm.selectedRow, "taskIndex", $$v)
                      },
                      expression: "selectedRow.taskIndex"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "任务次数", prop: "taskTimeRange" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px" },
                  attrs: { disabled: _vm.isDetail, placeholder: "请选择" },
                  model: {
                    value: _vm.selectedRow.taskTimeRange,
                    callback: function($$v) {
                      _vm.$set(_vm.selectedRow, "taskTimeRange", $$v)
                    },
                    expression: "selectedRow.taskTimeRange"
                  }
                },
                _vm._l(_vm.taskTimesOptions, function(item) {
                  return _c("el-option", {
                    key: item.dictValue,
                    attrs: { label: item.dictLabel, value: item.dictValue }
                  })
                }),
                1
              ),
              _c("el-input", {
                staticStyle: { width: "80px", margin: "0 5px 0 20px" },
                attrs: { placeholder: "请输入", disabled: _vm.isDetail },
                model: {
                  value: _vm.selectedRow.taskReachTimes,
                  callback: function($$v) {
                    _vm.$set(_vm.selectedRow, "taskReachTimes", _vm._n($$v))
                  },
                  expression: "selectedRow.taskReachTimes"
                }
              }),
              _vm._v("次 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图标" } },
            [
              _vm.isDetail
                ? _c(
                    "div",
                    [
                      _vm.selectedRow.icon && _vm.selectedRow.icon.videoUrl
                        ? _c("el-image", {
                            staticStyle: {
                              width: "100px",
                              height: "100%",
                              "min-height": "36px"
                            },
                            attrs: {
                              src: JSON.parse(_vm.selectedRow.icon.videoUrl)
                                .url,
                              fit: "scale-down"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: "''",
                        limit: 1,
                        "before-remove": _vm.beforeRemove,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.uploadFile,
                        "http-request": _vm.handleUploadPicture
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("上传")]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm.isDetail
            ? _c(
                "el-form-item",
                { attrs: { label: "权益设置" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.openEquity }
                    },
                    [_vm._v("点击查看")]
                  )
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "权益设置" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.openEquity }
                    },
                    [_vm._v("设置")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm.isDetail
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("关 闭")
              ])
            ],
            1
          )
        : _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleSave }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          ),
      _c(
        "el-dialog",
        {
          staticClass: "equity-dialog",
          attrs: {
            width: "800px",
            title: "权益设置",
            visible: _vm.equityVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.equityVisible = $event
            },
            close: _vm.handleEquityBeforeClose
          }
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "tabControl tabControl2",
              staticStyle: { height: "100%" },
              attrs: { "tab-position": "left", stretch: "" },
              model: {
                value: _vm.equityTabsActiveName,
                callback: function($$v) {
                  _vm.equityTabsActiveName = $$v
                },
                expression: "equityTabsActiveName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "达标权益", name: "1" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        "label-width": "120px",
                        model: _vm.promoteEquityForm
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "权益类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择权益类型"
                              },
                              model: {
                                value: _vm.promoteEquityForm.rightsType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "rightsType",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.rightsType"
                              }
                            },
                            _vm._l(_vm.rightsTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsType === 1,
                              expression: "promoteEquityForm.rightsType === 1"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "抽奖配置" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.promoteEquityForm.extractUuid,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.promoteEquityForm,
                                        "extractUuid",
                                        $$v
                                      )
                                    },
                                    expression: "promoteEquityForm.extractUuid"
                                  }
                                },
                                _vm._l(_vm.promoteDrawOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.promoteEquityForm.rightsType === 0 ||
                                _vm.promoteEquityForm.rightsType === 3,
                              expression:
                                "promoteEquityForm.rightsType===0 || promoteEquityForm.rightsType===3"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "领取方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择领取方式"
                                  },
                                  model: {
                                    value: _vm.promoteEquityForm.receiveMethod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.promoteEquityForm,
                                        "receiveMethod",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "promoteEquityForm.receiveMethod"
                                  }
                                },
                                _vm._l(_vm.receiveMethodOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择礼品" } },
                            [
                              _vm.isDetail
                                ? _c(
                                    "el-select",
                                    {
                                      ref: "giftSelect",
                                      class: [
                                        "giftSel",
                                        _vm.isSelecting ? "giftTranslate" : ""
                                      ],
                                      attrs: {
                                        disabled: _vm.isDetail,
                                        multiple: "",
                                        placeholder: "请选择礼品"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.giftChang(
                                            "promoteEquityForm"
                                          )
                                        },
                                        focus: _vm.giftFocus
                                      },
                                      model: {
                                        value:
                                          _vm.promoteEquityForm.rightsGiftDTOs,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.promoteEquityForm,
                                            "rightsGiftDTOs",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "promoteEquityForm.rightsGiftDTOs"
                                      }
                                    },
                                    _vm._l(_vm.promoteOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: { label: item.name, value: item }
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("selectComponent", {
                                        attrs: {
                                          value:
                                            _vm.promoteEquityForm.rightsGiftDTOs
                                        },
                                        on: { selected: _vm.setSelectValue }
                                      })
                                    ],
                                    1
                                  ),
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "90%",
                                    "margin-top": "20px"
                                  },
                                  attrs: {
                                    data: _vm.promoteEquityForm.rightsGiftDTOs,
                                    border: "",
                                    "max-height": "240px",
                                    size: "mini",
                                    "header-cell-style": {
                                      background: "#BEBEBE",
                                      color: "rgba(255, 255, 255, 1);"
                                    }
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { prop: "name", label: "礼品名称" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "type", label: "礼品类型" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.type === "COUPON_CODE"
                                                  ? "优惠券"
                                                  : "实物"
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "type", label: "渠道来源" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.type === "COUPON_CODE"
                                                  ? "微信"
                                                  : "支付宝"
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "inventory", label: "库存" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "每日限量",
                                      prop: "giftDayNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value: scope.row.giftDayNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "giftDayNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "scope.row.giftDayNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "每月限量",
                                      prop: "giftMonthNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value:
                                                  scope.row.giftMonthNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "giftMonthNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "scope.row.giftMonthNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "总限量",
                                      prop: "giftNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value: row.giftNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "giftNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "row.giftNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _vm.promoteEquityForm.receiveMethod === 2
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "概率",
                                          prop: "probability",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: _vm.isDetail,
                                                      placeholder: "请输入",
                                                      size: "mini"
                                                    },
                                                    model: {
                                                      value: row.probability,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "probability",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.probability"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3938810035
                                        )
                                      })
                                    : _vm._e(),
                                  !_vm.isDetail
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "",
                                          width: "40",
                                          align: "center"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var $index = ref.$index
                                                return [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete delRightsIcon",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.delRightsGift(
                                                          $index
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          854563689
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsType === 2,
                              expression: "promoteEquityForm.rightsType===2"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "领取方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择领取方式"
                                  },
                                  model: {
                                    value: _vm.promoteEquityForm.receiveMethod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.promoteEquityForm,
                                        "receiveMethod",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "promoteEquityForm.receiveMethod"
                                  }
                                },
                                _vm._l(_vm.receiveMethodOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "权益次数" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择权益次数"
                              },
                              model: {
                                value: _vm.promoteEquityForm.rightsNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "rightsNumber",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.rightsNumber"
                              }
                            },
                            _vm._l(_vm.rightsNumberOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsNumber === 2,
                              expression: "promoteEquityForm.rightsNumber === 2"
                            }
                          ],
                          attrs: { label: "每月领取时间" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择时间",
                                clearable: ""
                              },
                              model: {
                                value: _vm.promoteEquityForm.receiveMonthDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "receiveMonthDate",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.receiveMonthDate"
                              }
                            },
                            _vm._l(31, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item + "号", value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsNumber === 1,
                              expression: "promoteEquityForm.rightsNumber === 1"
                            }
                          ],
                          attrs: { label: "每周领取时间" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择时间",
                                clearable: ""
                              },
                              model: {
                                value: _vm.promoteEquityForm.receiveWeekDate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "receiveWeekDate",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.receiveWeekDate"
                              }
                            },
                            _vm._l(_vm.dayOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsNumber,
                              expression: "promoteEquityForm.rightsNumber"
                            }
                          ],
                          attrs: { label: "当日领取时间" }
                        },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              disabled: _vm.isDetail,
                              placeholder: "选择开始时间",
                              "value-format": "HH:mm:ss"
                            },
                            model: {
                              value: _vm.promoteEquityForm.startTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.promoteEquityForm,
                                  "startTime",
                                  $$v
                                )
                              },
                              expression: "promoteEquityForm.startTime"
                            }
                          }),
                          _vm._v(" -- "),
                          _c("el-time-picker", {
                            attrs: {
                              disabled: _vm.isDetail,
                              placeholder: "选择结束时间",
                              "value-format": "HH:mm:ss"
                            },
                            model: {
                              value: _vm.promoteEquityForm.finishTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.promoteEquityForm,
                                  "finishTime",
                                  $$v
                                )
                              },
                              expression: "promoteEquityForm.finishTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.promoteEquityForm.rightsNumber === 0,
                              expression: "promoteEquityForm.rightsNumber === 0"
                            }
                          ],
                          attrs: { label: "领取时间" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择",
                                clearable: ""
                              },
                              model: {
                                value: _vm.promoteEquityForm.startDateOption,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "startDateOption",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.startDateOption"
                              }
                            },
                            _vm._l(_vm.startDateOption, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          ),
                          _c("el-time-picker", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              disabled: _vm.isDetail,
                              placeholder: "开始时间",
                              "value-format": "HH:mm:ss"
                            },
                            model: {
                              value: _vm.promoteEquityForm.startTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.promoteEquityForm,
                                  "startTime",
                                  $$v
                                )
                              },
                              expression: "promoteEquityForm.startTime"
                            }
                          }),
                          _vm._v(" -- "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择",
                                clearable: ""
                              },
                              model: {
                                value: _vm.promoteEquityForm.endDateOption,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.promoteEquityForm,
                                    "endDateOption",
                                    $$v
                                  )
                                },
                                expression: "promoteEquityForm.endDateOption"
                              }
                            },
                            _vm._l(_vm.endDateOption, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          ),
                          _c("el-time-picker", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              disabled: _vm.isDetail,
                              placeholder: "结束时间",
                              "value-format": "HH:mm:ss"
                            },
                            model: {
                              value: _vm.promoteEquityForm.finishTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.promoteEquityForm,
                                  "finishTime",
                                  $$v
                                )
                              },
                              expression: "promoteEquityForm.finishTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "膨胀权益", name: "2" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        "label-width": "80px",
                        model: _vm.swellEquityForm
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "膨胀方式" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择膨胀方式"
                              },
                              model: {
                                value: _vm.swellEquityForm.expandType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.swellEquityForm,
                                    "expandType",
                                    $$v
                                  )
                                },
                                expression: "swellEquityForm.expandType"
                              }
                            },
                            _vm._l(_vm.expandTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "权益类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择权益类型"
                              },
                              model: {
                                value: _vm.swellEquityForm.rightsType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.swellEquityForm,
                                    "rightsType",
                                    $$v
                                  )
                                },
                                expression: "swellEquityForm.rightsType"
                              }
                            },
                            _vm._l(_vm.rightsTypeOptions, function(item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.swellEquityForm.rightsType === 1,
                              expression: "swellEquityForm.rightsType === 1"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "抽奖配置" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.swellEquityForm.extractUuid,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.swellEquityForm,
                                        "extractUuid",
                                        $$v
                                      )
                                    },
                                    expression: "swellEquityForm.extractUuid"
                                  }
                                },
                                _vm._l(_vm.swellDrawOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.swellEquityForm.rightsType === 0,
                              expression: "swellEquityForm.rightsType === 0"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "领取方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.isDetail,
                                    placeholder: "请选择领取方式"
                                  },
                                  model: {
                                    value: _vm.swellEquityForm.receiveMethod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.swellEquityForm,
                                        "receiveMethod",
                                        $$v
                                      )
                                    },
                                    expression: "swellEquityForm.receiveMethod"
                                  }
                                },
                                _vm._l(_vm.receiveMethodOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.dictValue,
                                    attrs: {
                                      label: item.dictLabel,
                                      value: item.dictValue
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择礼品" } },
                            [
                              _vm.isDetail
                                ? _c(
                                    "el-select",
                                    {
                                      ref: "giftSelect",
                                      class: [
                                        "giftSel",
                                        _vm.isSelecting ? "giftTranslate" : ""
                                      ],
                                      attrs: {
                                        disabled: _vm.isDetail,
                                        multiple: "",
                                        placeholder: "请选择礼品"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.giftChang(
                                            "swellEquityForm"
                                          )
                                        },
                                        focus: _vm.giftFocus
                                      },
                                      model: {
                                        value:
                                          _vm.swellEquityForm.rightsGiftDTOs,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.swellEquityForm,
                                            "rightsGiftDTOs",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "swellEquityForm.rightsGiftDTOs"
                                      }
                                    },
                                    _vm._l(_vm.promoteOptions, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: { label: item.name, value: item }
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("selectComponent", {
                                        attrs: {
                                          value:
                                            _vm.swellEquityForm.rightsGiftDTOs
                                        },
                                        on: { selected: _vm.setSelectValue }
                                      })
                                    ],
                                    1
                                  ),
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "90%",
                                    "margin-top": "20px"
                                  },
                                  attrs: {
                                    data: _vm.swellEquityForm.rightsGiftDTOs,
                                    border: "",
                                    "max-height": "240px",
                                    size: "mini",
                                    "header-cell-style": {
                                      background: "#BEBEBE",
                                      color: "rgba(255, 255, 255, 1);"
                                    }
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { prop: "name", label: "礼品名称" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "type", label: "礼品类型" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.type === "COUPON_CODE"
                                                  ? "优惠券"
                                                  : "实物"
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "type", label: "渠道来源" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.type === "COUPON_CODE"
                                                  ? "微信"
                                                  : "支付宝"
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: { prop: "inventory", label: "库存" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "每日限量",
                                      prop: "giftDayNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value: scope.row.giftDayNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "giftDayNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "scope.row.giftDayNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "每月限量",
                                      prop: "giftMonthNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value:
                                                  scope.row.giftMonthNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "giftMonthNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "scope.row.giftMonthNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "总限量",
                                      prop: "giftNumber",
                                      width: "100"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isDetail,
                                                placeholder: "请输入",
                                                size: "mini"
                                              },
                                              model: {
                                                value: row.giftNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "giftNumber",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "row.giftNumber"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  }),
                                  _vm.swellEquityForm.receiveMethod === 2
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "概率",
                                          prop: "probability",
                                          width: "100"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: _vm.isDetail,
                                                      placeholder: "请输入",
                                                      size: "mini"
                                                    },
                                                    model: {
                                                      value: row.probability,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "probability",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.probability"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3938810035
                                        )
                                      })
                                    : _vm._e(),
                                  !_vm.isDetail
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "",
                                          width: "40",
                                          align: "center"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var $index = ref.$index
                                                return [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete delRightsIcon",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.delRightsGift(
                                                          $index
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          854563689
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.swellEquityForm.expandType === 1,
                              expression: "swellEquityForm.expandType===1"
                            }
                          ],
                          attrs: { label: "助力设置" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isDetail,
                                placeholder: "请选择助力配置"
                              },
                              model: {
                                value: _vm.swellEquityForm.collaUuid,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.swellEquityForm,
                                    "collaUuid",
                                    $$v
                                  )
                                },
                                expression: "swellEquityForm.collaUuid"
                              }
                            },
                            _vm._l(_vm.collaUUIDOptions, function(item) {
                              return _c("el-option", {
                                key: item.secUuid,
                                attrs: {
                                  label: item.collaName,
                                  value: item.secUuid
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isDetail
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.equityVisible = false
                        }
                      }
                    },
                    [_vm._v("关 闭")]
                  )
                ],
                1
              )
            : _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.equityVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addEquity }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }